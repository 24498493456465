export const partnerListReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_PARTNER_REQUEST':
            return {
                loading: true,
            }
        case'GET_PARTNER_SUCCESS':
            return {
                ...state,
                partners: action.payload.data,
                loading: false,
                totalCount: action.payload.totalCount,
                totalCashAppointment:action.payload.totalCashAppointment,
                totalPenaltyAppointment:action.payload.totalPenaltyAppointment,
                totalOnlineAppointment:action.payload.totalOnlineAppointment
            }
        case'GET_PARTNER_FAILED':
            return {
                ...state,
                loading: false,
            }

        case'EDIT_PARTNER_SUCCESS':
            return {
                ...state,
                partner: action.payload,
                loading: false
            }

        case'ASSIGN_WORKING_LOCATIONS':
            return {
                ...state,
                partner: action.payload,
                loading: false
            }
        case'CREATE_PARTNER_SUCCESS':
            return {
                ...state,
                partner: action.payload,
                loading: false
            }
        case'DELETE_PARTNER_SUCCESS':
            return {
                ...state,
                loading: false
            }
        case'DELETE_PARTNER_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case'UPDATE_PARTNER_SUCCESS':
            return {
                ...state,
                loading: false
            }
        case'UPDATE_PARTNER_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return {
                ...state,
                loading: false,
            }
    }
}