import './App.css';
import './dist/tailwind.css';
import React from "react";
import RouterFile from './route/router';
// import PartnerRouterFile from "./route/partnerRouter";
import 'reactjs-popup/dist/index.css';
function App() {
    return (
        <div className="App">
            <RouterFile/>
        </div>
    );
}

export default App;
