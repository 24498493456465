import * as React from "react";
import { useState } from "react";
import Popup from "reactjs-popup";
const Filter = () => {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);

  const [showFilter, setShowFilter] = React.useState(false);
  const onClickFilter = () => setShowFilter(!showFilter);
  const onClickCancelFilter = () => setShowFilter(false);

  const [showAdvacneFilter, setShowAdvacneFilter] = React.useState(false);
  const onClickAdvanceFilter = () => setShowAdvacneFilter(!showAdvacneFilter);

  const [showReference, setShowReference] = React.useState(false);
  const onClickReference = () => setShowReference(!showReference);
  const onClickCancelReference = () => setShowReference(!showReference);

  const [showCreatedat, setShowCreatedat] = React.useState(false);
  const onClickCreatedat = () => setShowCreatedat(!showCreatedat);
  const onClickCancelCreatedat = () => setShowCreatedat(!showCreatedat);

  const [showType, setShowType] = React.useState(false);
  const onClickType = () => setShowType(!showType);
  const onClickCancelType = () => setShowType(!showType);

  const [showClient, setShowClient] = React.useState(false);
  const onClickClient = () => setShowClient(!showClient);
  const onClickCancelClient = () => setShowClient(!showClient);

  const [showRegion, setShowRegion] = React.useState(false);
  const onClickRegion = () => setShowRegion(!showRegion);
  const onClickCancelRegion = () => setShowRegion(!showRegion);

  const [showStartDate, setShowStartDate] = React.useState(false);
  const onClickStartDate = () => setShowStartDate(!showStartDate);
  const onClickCancelStartDate = () => setShowStartDate(!showStartDate);

  const [showEndDate, setShowEndDate] = React.useState(false);
  const onClickEndDate = () => setShowEndDate(!showEndDate);
  const onClickCancelEndDate = () => setShowEndDate(!showEndDate);

  const [showPaymentmethod, setShowPaymentMethod] = React.useState(false);
  const onClickPaymentMethod = () => setShowPaymentMethod(!showPaymentmethod);
  const onClickCancelPaymentMethod = () =>
    setShowPaymentMethod(!showPaymentmethod);

  const [showStatus, setShowStatus] = React.useState(false);
  const onClickStatus = () => setShowStatus(!showStatus);
  const onClickCancelStatus = () => setShowStatus(!showStatus);

  const [showReportDelivery, setShowReportDelivery] = React.useState(false);
  const onClickReportDelivery = () =>
    setShowReportDelivery(!showReportDelivery);
  const onClickCancelReportDelivery = () =>
    setShowReportDelivery(!showReportDelivery);

  return (
    <>
      <div className="w-100 py-6 md:px-10 px-3 ">
        <div className=" w-full  mt-5 relative">
          <Popup
            className="filterPopup"
            open={open}
            onClose={closeModal}
            closeOnDocumentClick={false}
            trigger={
              <button
                onClick={() => setOpen((o) => !o)}
                className="p-1 pl-4 cursor mr-1 inline-block pr-4 bg-orange-500 border border-orange-500 rounded text-white"
              >
                Filter
              </button>
            }
            modal
            nested
          >
            {(close) => (
              <div className="modal">
                <button
                  className="close"
                  onClick={() => {
                    // setSelectedOptions([]);
                    close();
                  }}
                >
                  &times;
                </button>
                <div className="header"> Update Filter</div>
                <div className="content">
                <div className="FilterList">
              <ul>
                <li onClick={onClickReference}>
                  <input type="checkbox" />
                  Reference
                </li>
                <li onClick={onClickCreatedat}>
                  <input type="checkbox" />
                  Created At
                </li>
                <li onClick={onClickType}>
                  <input type="checkbox" />
                  Type
                </li>
                <li onClick={onClickClient}>
                  <input type="checkbox" />
                  Client
                </li>
                <li onClick={onClickRegion}>
                  <input type="checkbox" />
                  Region
                </li>
                <li onClick={onClickStartDate}>
                  <input type="checkbox" />
                  Start Date
                </li>
                <li onClick={onClickEndDate}>
                  <input type="checkbox" />
                  End Date
                </li>
                <li onClick={onClickPaymentMethod}>
                  <input type="checkbox" />
                  Payment Method
                </li>
                <li onClick={onClickStatus}>
                  <input type="checkbox" />
                  Status
                </li>
                <li onClick={onClickReportDelivery}>
                  <input type="checkbox" />
                  Report Delivery Status
                </li>
              </ul>
            </div>
                <div className="flex w-full justify-between	filterExpandDiv flex-wrap lg:flex-nowrap">
            <div className="FilterForm lg:w-8/12 w-full	 ">
              {showReference ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    Reference
                  </label>
                  <div className="flex mb-4  ">
                    <div className="w-full">
                      <div class="form-group mb-3 flex items-center flex-wrap md:flex-nowrap">
                        {showAdvacneFilter ? (
                          <div className="w-full md:w-1/3 md:mr-5 mr-0 md:mb-0 mb-1">
                            <select class="formControl ">
                              <option>One Time</option>
                              <option>Two Time</option>
                            </select>
                          </div>
                        ) : null}
                        <div className="w-full md:w-1/3 md:mr-5 mr-0 md:mb-0 mb-1">
                          <input type="text" class="formControl " />
                        </div>
                        <span
                          className="fa fa-minus text-red-900"
                          onClick={onClickCancelReference}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {showCreatedat ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium">
                    Created At
                  </label>
                  <div className="flex mb-4 flex-wrap md:flex-nowrap ">
                    {showAdvacneFilter ? (
                      <div className="w-full md:w-1/3 md:mr-5 mr-0 mb-1 md:mb-0">
                        <label className="block mb-2 invisible">111</label>
                        <select class="formControl ">
                          <option>One Time</option>
                          <option>Two Time</option>
                        </select>
                      </div>
                    ) : null}
                    <div className="w-full md:w-1/3">
                      <label className="block md:mb-2 mb-1 ">From</label>
                      <div class="form-group md:mb-3 mb-1 flex items-center">
                        <input type="date" class="formControl md:mr-5 mr-0" />
                      </div>
                    </div>
                    <div className="w-full md:w-1/3">
                      <label className="block mb-2 ">To</label>
                      <div class="form-group md:mb-3 mb-1 flex items-center flex-wrap md:flex-nowrap">
                        <input type="date" class="formControl md:mr-5 mr-0 mb-1 md:mb-0" />
                        <span
                          className="fa fa-minus text-red-900"
                          onClick={onClickCancelCreatedat}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {showType ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    Type
                  </label>
                  <div className="flex mb-4 flex items-center">
                    <div className="w-full md:w-1/3 mr-5 ">
                      <div class="form-group  ">
                        <select class="formControl ">
                          <option>One Time</option>
                          <option>Two Time</option>
                        </select>
                      </div>
                    </div>
                    <span
                      className="fa fa-minus text-red-900"
                      onClick={onClickCancelType}
                    ></span>
                  </div>
                </div>
              ) : null}

              {showClient ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    Client
                  </label>
                  <div className="flex mb-4 flex items-center  ">
                    <div className="w-full md:w-1/3 mr-5 ">
                      <div class="form-group ">
                        <input type="text" class="formControl " />
                      </div>
                    </div>
                    <span
                      className="fa fa-minus text-red-900"
                      onClick={onClickCancelClient}
                    ></span>
                  </div>
                </div>
              ) : null}

              {showRegion ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    Region
                  </label>
                  <div className="flex mb-4  ">
                    <div className="w-full">
                      <div class="form-group mb-3 flex items-center flex-wrap md:flex-nowrap">
                        {showAdvacneFilter ? (
                          <div className="w-full md:w-1/3 md:mr-5 mr-0 mb-1 md:mb-0">
                            <select class="formControl  ">
                              <option>One Time</option>
                              <option>Two Time</option>
                            </select>
                          </div>
                        ) : null}
                        <div className="w-full md:w-1/3 md:mr-5 mr-0 mb-1 md:mb-0">
                          <input type="text" class="formControl " />
                        </div>
                        <span
                          className="fa fa-minus text-red-900"
                          onClick={onClickCancelRegion}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="w-full mb-2">
                {showStartDate ? (
                  <div className="mb-2">
                    <label className="block mb-2 text-lg font-medium">
                      Start Date
                    </label>
                    <div className="flex mb-5 items-center flex-wrap md:flex-nowrap ">
                      {showAdvacneFilter ? (
                        <div className="w-full md:w-1/3 md:mr-5 mr-0 mb-1 md:mb-0">
                          <label className="block mb-2 invisible">111</label>
                          <select class="formControl  ">
                            <option>One Time</option>
                            <option>Two Time</option>
                          </select>
                        </div>
                      ) : null}
                      <div className="w-full md:w-1/3 md:mr-5 mr-0 mb-1 md:mb-0">
                        <label className="block mb-2 ">From</label>
                        <div class="form-group flex items-center">
                          <input type="date" class="formControl " />
                        </div>
                      </div>
                      <div className="w-full md:w-1/3 mb-1 md:mb-0  ">
                        <label className="block mb-2 ">To</label>
                        <div class="form-group flex items-center flex-wrap md:flex-nowrap">
                          <input type="date" class="formControl md:mr-5 md:mb-0 mb-1" />
                          <span
                            className="fa fa-minus text-red-900"
                            onClick={onClickCancelStartDate}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {showEndDate ? (
                  <div className="mb-2">
                    <label className="block mb-2 text-lg font-medium">
                      End Date
                    </label>
                    <div className="flex mb-5 items-center flex-wrap md:flex-nowrap ">
                      {showAdvacneFilter ? (
                        <div className="w-full md:w-1/3 md:mr-5 mr-0 mb-1 md:mb-0">
                          <label className="block mb-2 invisible">111</label>
                          <select class="formControl  ">
                            <option>One Time</option>
                            <option>Two Time</option>
                          </select>
                        </div>
                      ) : null}
                      <div className="w-full md:w-1/3 md:mr-5 mr-0 mb-1 md:mb-0">
                        <label className="block mb-2 ">From</label>
                        <div class="form-group flex items-center">
                          <input type="date" class="formControl " />
                        </div>
                      </div>
                      <div className="w-full md:w-1/3 mb-1 md:mb-0 ">
                        <label className="block mb-2 ">To</label>
                        <div class="form-group  flex items-center flex-wrap md:flex-nowrap">
                          <input type="date" class="formControl md:mr-5 md:mb-0 mb-1" />
                          <span
                            className="fa fa-minus text-red-900"
                            onClick={onClickCancelEndDate}
                          ></span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              {showPaymentmethod ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    Payment Method
                  </label>
                  <div className="flex mb-4  ">
                    <div className="w-full">
                      <div class="form-group mb-3 flex items-center flex-wrap md:flex-nowrap">
                        {showAdvacneFilter ? (
                          <div className="w-full md:w-1/3 md:mr-5 mr-0 mb-1 md:mb-0">
                            <select class="formControl  ">
                              <option>One Time</option>
                              <option>Two Time</option>
                            </select>
                          </div>
                        ) : null}
                        <div className="w-full md:w-1/3 md:mr-5 mr-0 mb-1 md:mb-0">
                          <select class="formControl ">
                            <option>Cash</option>
                            <option>Card</option>
                          </select>
                        </div>
                        <span
                          className="fa fa-minus text-red-900"
                          onClick={onClickCancelPaymentMethod}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {showStatus ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    Status
                  </label>
                  <div className="flex mb-4 items-center ">
                    <div className="w-full md:w-1/3 mr-5">
                      <div class="form-group mb-3 ">
                        <select class="formControl ">
                          <option>Waiting</option>
                          <option>Ongoing</option>
                          <option>Completed</option>
                          <option>Cancelled</option>
                        </select>
                      </div>
                    </div>
                    <span
                      className="fa fa-minus text-red-900"
                      onClick={onClickCancelStatus}
                    ></span>
                  </div>
                </div>
              ) : null}

              {showReportDelivery ? (
                <div className="w-full">
                  <label className="block mb-2 text-lg font-medium ">
                    Report Delivery Status
                  </label>
                  <div className="flex mb-4  ">
                    <div className="w-full">
                      <div class="form-group mb-3 flex items-center flex-wrap md:flex-nowrap">
                        {showAdvacneFilter ? (
                          <div className="w-full md:w-1/3 md:mr-5 mr-0 mb-1 md:mb-0">
                            <select class="formControl  ">
                              <option>One Time</option>
                              <option>Two Time</option>
                            </select>
                          </div>
                        ) : null}
                        <div className="w-full md:w-1/3 md:mr-5 mr-0 mb-1 md:mb-0">
                          <select class="formControl ">
                            <option>New</option>
                            <option>Report Due</option>
                            <option>Sent</option>
                            <option>Sent Late</option>
                          </select>
                        </div>
                        <span
                          className="fa fa-minus text-red-900"
                          onClick={onClickCancelReportDelivery}
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="advancedFilter lg:w-3/12 w-full ">
              {showReference ? (
                <div className="form-group flex pt-6 justify-end lg:flex-col flex-col md:flex-row">
                  <button className="px-8 rounded py-3 hover:bg-blue-900 bg-purple-700 text-white mb-2 md:mr-2 lg:mr-0   ">
                    Filter
                  </button>
                  <button
                    className="px-8 rounded py-3 hover:bg-blue-900 bg-purple-700 text-white mb-2 md:mr-2 lg:mr-0 "
                    onClick={onClickAdvanceFilter}
                  >
                    <span className="fa fa-cogs"></span> Advanced Filter
                  </button>
                  <button className="px-8 rounded py-3 hover:bg-red-500 bg-red-500 text-white mb-2 ">
                    Reset
                  </button>
                </div>
              ) : null}
            </div>
          </div>
                 
                
                </div>
              </div>
            )}
          </Popup>
          <button
            className="p-2 bg-purple-600 px-5 text-white ml-auto float-right mb-5"
            
          >
            Filter
          </button>
        
          
         
        </div>
        <div
          className="card clear-both mt-5 p-5 "
          onClick={onClickCancelFilter}
        >
          
        </div>
      </div>
    </>
  );
};

export default Filter;
