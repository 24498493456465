import * as React from 'react';
import {PartnerSidebar} from "./partnerSidebar";
import {PartnerHeader} from "./partnerHeader";
import {Outlet} from "react-router-dom";
import {useEffect} from "react";
import {onMessageListener} from "../firebase";

export function PartnerLayout() {
    useEffect(() => {
        onMessageListener()
            .then((payload) => {
                // setNotification({title: payload?.notification?.title, body: payload?.notification?.body});
            })
            .catch((err) => console.log('failed: ', err));
    }, [])
    return (
        <div>
            <div className="xl:w-full mx-auto min-h-screen relative flex justify-between  ">
                <PartnerSidebar/>
                <div className="contentArea w-full PartnerAdminPortal">
                    <PartnerHeader/>
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}