import {Route, Navigate, Outlet} from "react-router-dom";
import React, {lazy} from "react";
import Login from "../components/login";

import {Layout} from "../components/layout";

import {authRole, authToken, canAddPolicy, canEditPolicy, canViewPolicy, isAdmin} from "../helpers/helpers";

import MasterRoutes from "./masterRoutes";
// import CashAppointment from "../pages/partner/cashAppointment";
import PartnerPayment from "../pages/partner/cashAppointment";

const ThankYou = lazy(() => import("../pages/error/thank"))
const PaymentSuccess = lazy(() => import("../pages/error/paymentSuccess"))
const RescheduleReason = lazy(() => import("../pages/appointment/rescheduleReason"))
const CancelReason = lazy(() => import("../pages/appointment/cancelReason"))
const Dashboard = lazy(() => import("../pages/dashboard/dashboard"))
const Cleaner = lazy(() => import("../pages/cleaner/cleaner"))
const CleanerAdd = lazy(() => import("../pages/cleaner/cleanerAdd"))
const ProsView = lazy(() => import("../pages/cleaner/prosView"))
const AttendanceList = lazy(() => import("../pages/attendanceList/attendanceList"))
const AttendanceView = lazy(() => import("../pages/attendanceList/attendanceView"))
const Driver = lazy(() => import("../pages/driver/driver"))
const DriverAdd = lazy(() => import("../pages/driver/driverAdd"))
const DriverView = lazy(() => import("../pages/driver/driverView"))
const ApplyJob = lazy(() => import("../pages/applyJob/applyJob"))

const RhClub = lazy(() => import("../pages/rhClub/rhClub"))
const RhClubView = lazy(() => import("../pages/rhClub/rhClubView"))

const WalletList = lazy(() => import("../pages/customerWallet/wallet"))
const Distributor = lazy(() => import("../pages/appointment/distributor"))
const BookingList = lazy(() => import("../pages/customerWallet/booking"))


const CleanerView = lazy(() => import("../pages/cleanerreview/CleanerView"))
const CleanerReview = lazy(() => import("../pages/cleanerreview/CleanerReview"))

const Member = lazy(() => import("../pages/member/member"))
const MemberAdd = lazy(() => import("../pages/member/memberAdd"))

const Partner = lazy(() => import("../pages/partner/partner"))
const PartnerAdd = lazy(() => import("../pages/partner/partnerAdd"))
const PartnerView = lazy(() => import("../pages/partner/partnerView"))

const AppointmentView = lazy(() => import("../pages/appointment/appointmentView"))

const CleanerDriver = lazy(() => import("../pages/cleanerDriver/cleanerDriver"))
const CleanerDriverAdd = lazy(() => import("../pages/cleanerDriver/cleanerDriverAdd"))
const CleanerDriverView = lazy(() => import("../pages/cleanerDriver/cleanerDriverView"))


const Announcement = lazy(() => import("../pages/announcement/announcement"))
const AnnouncementAdd = lazy(() => import("../pages/announcement/announcementAdd"))

const Customer = lazy(() => import("../pages/customer/customer"))
const CustomerAdd = lazy(() => import("../pages/customer/customerAdd"))

const Appointment = lazy(() => import("../pages/appointment/appointment"))
const AppointmentAdd = lazy(() => import("../pages/appointment/appointmentAdd"))
const Setting = lazy(() => import("../pages/setting/Setting"))

const CalendarView = lazy(() => import("../pages/appointment/calendarView"))
const CustomerView = lazy(() => import("../pages/customer/customerView"))
const CustomerView2 = lazy(() => import("../pages/customer/customerView2"))
const Blog = lazy(() => import("../pages/blog/blog"))
const BlogAdd = lazy(() => import("../pages/blog/blogAdd"))

const Profile = lazy(() => import("../pages/profile/profile"))
const SubscriptionList = lazy(() => import("../pages/subscription/subscriptionPackage"))
const HolidaySetting = lazy(() => import("../pages/profile/holidaySetting"))

const DiscountUse = lazy(() => import("../pages/reports/discountUse"))
const WalletDistribution = lazy(() => import("../pages/reports/walletDistribution"))
const RHDistribution = lazy(() => import("../pages/reports/rhCreditDistribution"))
const Report = lazy(() => import("../pages/report/report"))
const PartnerProReport = lazy(() => import("../pages/report/partnerPros/report"))
const ServiceReport = lazy(() => import("../pages/report/service/serviceReport"))
const PerformanceReport = lazy(() => import("../pages/report/performance/report"))
const RevenueReport = lazy(() => import("../pages/report/revenue/report"))

const CustomerReport = lazy(() => import("../pages/report/customer/customerReport"))
const Notification = lazy(() => import("../pages/notification/notification"))

const Role = lazy(() => import("../pages/roles/roles"))
const RoleAdd = lazy(() => import("../pages/roles/addrole"))

const NotificationRole = lazy(() => import("../pages/notificationRoles/notificationRoles"))
const NotificationRoleAdd = lazy(() => import("../pages/notificationRoles/notificationRolesAdd"))

const CustomerRating = lazy(() => import("../pages/partnerdashboard/customerRating/customerRating"))
const CustomerRatingAdd = lazy(() => import("../pages/partnerdashboard/customerRating/customerRatingAdd"))

const PartnerCustomer = lazy(() => import("../pages/partnerdashboard/customer/partnerCustomer"))
const PartnerCustomerAdd = lazy(() => import("../pages/partnerdashboard/customer/partnerCustomerAdd"))

// const PartnerAppointment = lazy(() => import("../pages/partnerdashboard/appointment/appointment"))
// const PartnerAppointmentView = lazy(() => import("../pages/partnerdashboard/appointment/appointmentView"))

const PackageAppointments = lazy(() => import("../pages/partnerdashboard/packageAppointments/packageAppointments"))
const PackageAppointmentView = lazy(() => import("../pages/partnerdashboard/packageAppointments/packageAppointmentView"))

const RecurringAppointments = lazy(() => import("../pages/partnerdashboard/recurringAppointments/recurringAppointments"))
const RecurringAppointmentView = lazy(() => import("../pages/partnerdashboard/recurringAppointments/recurringAppointmentView"))

const CrewMembers = lazy(() => import("../pages/partnerdashboard/crewMembers/crewMembers"))
const CrewMembersView = lazy(() => import("../pages/partnerdashboard/crewMembers/crewMembersView"))

const PenaltyLogs = lazy(() => import("../pages/partnerdashboard/penaltyLogs/penaltyLogs"))
const PenaltyDashboard = lazy(() => import("../pages/partnerdashboard/penaltyDashboard/penaltyDashboard"))

const ForgotPassword = lazy(() => import("../components/ForgotPassword"))
const ResetPassword = lazy(() => import("../components/ResetPassword"))

const PartnerDriverView = lazy(() => import("../pages/partner/partnerDriverView"))
const PartnerProView = lazy(() => import("../pages/partner/partnerProView"))
const RequireAuth = lazy(() => import("../components/RequireAuth"))

const Performance = lazy(() => import("../pages/driver/performance"))
const ProsPerformance = lazy(() => import("../pages/cleaner/performance"))

const TeamTracking = lazy(() => import("../pages/appointment/teamTacking"))
const CheckoutPage = lazy(() => import("../stripeComponents/checkout"))
const Success = lazy(() => import("../stripeComponents/success"))
const Failed = lazy(() => import("../stripeComponents/failed"))
// const SuccessComponent = lazy(() => import("../stripeComponents/successComponent"))

const ProtectedRoute = ({redirectPath = '/'}) => {
    if (authRole() === 'partner') {
        return <Navigate to='/forbidden' replace/>;
    }

    if (!authToken() && (authRole() === 'partner')) {
        return <Navigate to={redirectPath} replace/>;
    }

    return <Outlet/>;
};

const RedirectRoute = ({redirectPath = '/dashboard'}) => {
    if (authToken() && (authRole() !== 'partner')) {
        return <Navigate to={redirectPath} replace/>;
    }
    return <Outlet/>;
};

const AdminRoutes = (
        <>
            <Route element={<RedirectRoute/>}>
                <Route path="/" element={<Login/>} exact/>
                <Route path="/login" element={<Login/>} exact/>
                <Route path="/forgot-password" element={<ForgotPassword/>} exact/>
                <Route path="/reset/password/:token/:email/:role" element={<ResetPassword/>} exact/>

            </Route>
            {/*protected routes of user*/
            }

            <Route element={<ProtectedRoute/>}>
                <Route path="/" element={<Layout/>}>
                    <Route path="dashboard" element={<Dashboard/>} exact/>

                    <Route path="/applyJob/list" element={<ApplyJob/>} exact/>

                    <Route path="/member/list" element={<Member/>} exact/>
                    <Route path="/member/add" element={<MemberAdd/>}/>
                    <Route path="/member/edit/:id" element={<MemberAdd/>}/>
                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canViewPolicy(3))}/>}>
                        <Route path="/cleaner/view/:id" element={<ProsView/>}/>
                        <Route path="/:pc/cleaner/list" element={<Cleaner/>} exact/>
                        <Route path="/cleaner/list" element={<Cleaner/>} exact/>
                        <Route path="/:pc/cleaner/view/:id" element={<ProsView/>}/>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canAddPolicy(3))}/>}>
                        <Route path="/cleaner/add" element={<CleanerAdd/>}/>
                        <Route path="/:pc/cleaner/add" element={<CleanerAdd/>}/>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canEditPolicy(3))}/>}>
                        <Route path="/cleaner/edit/:id" element={<CleanerAdd/>}/>
                        <Route path="/:pc/cleaner/edit/:id" element={<CleanerAdd/>}/>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canViewPolicy(4))}/>}>
                        <Route path="/driver/list" element={<Driver/>} exact/>
                        <Route path="/driver/view/:id" element={<DriverView/>}/>
                        <Route path="/:pd/driver/list" element={<Driver/>} exact/>
                        <Route path="/:pd/driver/view/:id" element={<DriverView/>}/>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canAddPolicy(4))}/>}>
                        <Route path="/driver/add" element={<DriverAdd/>}/>
                        <Route path="/:pd/driver/add" element={<DriverAdd/>}/>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canEditPolicy(4))}/>}>
                        <Route path="/driver/edit/:id" element={<DriverAdd/>}/>
                        <Route path="/:pd/driver/edit/:id" element={<DriverAdd/>}/>
                    </Route>


                    <Route path="/cleaner/driver/list" element={<CleanerDriver/>} exact/>
                    <Route path="/cleaner/driver/add" element={<CleanerDriverAdd/>}/>
                    <Route path="/cleaner/driver/edit/:id" element={<CleanerDriverAdd/>}/>
                    <Route path="/cleaner/driver/view/:id" element={<CleanerDriverView/>} exact/>

                    <Route path="/:pc/cleaner/driver/list" element={<CleanerDriver/>} exact/>
                    <Route path="/:pc/cleaner/driver/add" element={<CleanerDriverAdd/>}/>
                    <Route path="/:pc/cleaner/driver/edit/:id" element={<CleanerDriverAdd/>}/>
                    <Route path="/:pc/cleaner/driver/view/:id" element={<CleanerDriverView/>} exact/>

                    <Route path="/customer/wallet/list/:id" element={<WalletList/>} exact/>
                    <Route path="/customer/distribute/:id" element={<Distributor/>} exact/>
                    <Route path="/customer/distribute/:id/:discount" element={<Distributor/>} exact/>
                    <Route path="/customer/history/list/:id" element={<BookingList/>} exact/>
                    {/* <Route path="/pro/attendance/view/:id" element={<AttendanceView/>} exact/> */}

                    <Route path="/pro/attendance/list" element={<AttendanceList/>} exact/>
                    <Route path="/pro/attendance/view/:id" element={<AttendanceView/>} exact/>

                    <Route path="/:pd/pro/attendance/list" element={<AttendanceList/>} exact/>
                    <Route path="/:pd/pro/attendance/view/:id" element={<AttendanceView/>} exact/>

                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canViewPolicy(5))}/>}>
                        <Route path="/customer/list" element={<Customer/>} exact/>
                        <Route path="/rh/customer/list" element={<RhClub/>} exact/>
                        <Route path="/customer/rhclub/view/:id" element={<RhClubView/>} exact/>
                        <Route path="/customer/view/:id" element={<CustomerView/>} exact/>
                        <Route path="/customer/fav/:id" element={<CustomerView2/>} exact/>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canAddPolicy(5))}/>}>
                        <Route path="/customer/add" element={<CustomerAdd/>}/>
                        <Route path="/customer/add/:appointment" element={<CustomerAdd/>}/>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canEditPolicy(5))}/>}>
                        <Route path="/customer/edit/:id" element={<CustomerAdd/>}/>
                    </Route>


                    {/*announcements route*/}

                    <Route path="/announcement/list" element={<Announcement/>} exact/>
                    <Route path="/announcement/add" element={<AnnouncementAdd/>} exact/>
                    <Route path="/announcement/edit/:id" element={<AnnouncementAdd/>}/>

                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canViewPolicy(6))}/>}>
                        <Route path="/appointment/:id/list/" element={<Appointment/>} exact/>
                        <Route path="/appointment/view/:id" element={<AppointmentView/>} exact/>
                        <Route path="/appointment/calendar" element={<CalendarView/>} exact/>
                        <Route path="/appointment/calendar/:id" element={<CalendarView/>} exact/>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canAddPolicy(6))}/>}>
                        <Route path="/appointment/add" element={<AppointmentAdd/>} exact/>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canEditPolicy(6))}/>}>
                        <Route path="/appointment/edit/:id" element={<AppointmentAdd/>} exact/>
                    </Route>


                    {/*<Route path="/appointment/archived/list/" element={<Appointment/>} exact/>*/}
                    {/*<Route path="/appointment/deleted/list" element={<Appointment/>} exact/>*/}

                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canViewPolicy(2))}/>}>
                        <Route path="/partner/list" element={<Partner/>} exact/>
                        <Route path="/partner/view/:id" element={<PartnerView/>} exact/>
                        {/*<Route path="/partner/view/:id" element={<PartnerView/>} exact/>*/}
                    </Route>
                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canAddPolicy(2))}/>}>
                        <Route path="/partner/add" element={<PartnerAdd/>} exact/>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canEditPolicy(2))}/>}>
                        <Route path="/partner/edit/:id" element={<PartnerAdd/>}/>
                    </Route>

                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canViewPolicy(10))}/>}>
                        <Route path="/blog/list" element={<Blog/>} exact/>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canAddPolicy(10))}/>}>
                        <Route path="/blog/add" element={<BlogAdd/>}/>
                    </Route>
                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canEditPolicy(10))}/>}>
                        <Route path="/blog/edit/:id" element={<BlogAdd/>}/>
                    </Route>

                    <Route path="/role/list" element={<Role/>} exact/>
                    <Route path="/role/add" element={<RoleAdd/>}/>
                    <Route path="/role/edit/:id" element={<RoleAdd/>}/>

                    <Route path="/notification/roles/list" element={<NotificationRole/>} exact/>
                    <Route path="/notification/roles/add" element={<NotificationRoleAdd/>}/>
                    <Route path="/notification/roles/edit/:id" element={<NotificationRoleAdd/>}/>

                    <Route path="/team/tracking" element={<TeamTracking/>} exact/>

                    <Route path="/cleaner/review" element={<CleanerReview/>} exact/>
                    <Route path="/cleaner/review/:id" element={<CleanerView/>} exact/>
                    <Route path="/setting" element={<Setting/>} exact/>

                    <Route path="/profile" element={<Profile/>} exact/>
                    <Route path="/price/setting" element={<HolidaySetting/>} exact/>

                    <Route element={<RequireAuth allowedRoles={(isAdmin() || canViewPolicy(11))}/>}>
                        <Route path="/subscription/uses/list" element={<SubscriptionList/>} exact/>
                    </Route>


                    <Route path="/pros/report" element={<Report/>} exact/>
                    <Route path="/partner/pros/report" element={<PartnerProReport/>} exact/>
                    <Route path="/customer/report" element={<CustomerReport/>} exact/>
                    <Route path="/service/report" element={<ServiceReport/>} exact/>
                    <Route path="/performance/report" element={<PerformanceReport/>} exact/>
                    <Route path="/:pc/performance/report" element={<PerformanceReport/>} exact/>
                    <Route path="/revenue/report" element={<RevenueReport/>} exact/>

                    <Route path="/discount/report" element={<DiscountUse/>} exact/>
                    <Route path="/wallet/report" element={<WalletDistribution/>} exact/>
                    <Route path="/rhCredit/report" element={<RHDistribution/>} exact/>
                    <Route path="/notification" element={<Notification/>} exact/>


                    <Route path="/partner/customer-rating" element={<CustomerRating/>} exact/>
                    <Route path="/partner/customer-rating/add" element={<CustomerRatingAdd/>} exact/>
                    <Route path="/partner/customer/list" element={<PartnerCustomer/>} exact/>
                    <Route path="/partner/customer/add" element={<PartnerCustomerAdd/>}/>
                    {/*<Route path="/partner/appointment/list" element={<PartnerAppointment/>} exact/>*/}
                    {/*<Route path="/partner/appointment/view" element={<PartnerAppointmentView/>} exact/>*/}
                    <Route path="/partner/recurring-appointment/list" element={<RecurringAppointments/>} exact/>
                    <Route path="/partner/recurring-appointment/view" element={<RecurringAppointmentView/>} exact/>

                    <Route path="/partner/package/list" element={<PackageAppointments/>} exact/>
                    <Route path="/partner/package/view" element={<PackageAppointmentView/>} exact/>

                    <Route path="/partner/crew/list" element={<CrewMembers/>} exact/>
                    <Route path="/partner/crew/view" element={<CrewMembersView/>} exact/>
                    <Route path="penalty-logs/list" element={<PenaltyLogs/>} exact/>
                    <Route path="/partner/penalty-dashboard/list" element={<PenaltyDashboard/>} exact/>


                    <Route path="/pros/view" element={<ProsView/>} exact/>
                    <Route path="/partner/pro-view" element={<PartnerProView/>} exact/>
                    <Route path="/driver/view" element={<DriverView/>} exact/>
                    <Route path="/partner-driver/view" element={<PartnerDriverView/>} exact/>
                    <Route path="/pros/performance/:id" element={<ProsPerformance/>} exact/>
                    <Route path="/driver/performance/:id" element={<Performance/>} exact/>
                    <Route path="/cancel-reason/:id" element={<CancelReason/>} exact/>
                    <Route path="/distributor" element={<Distributor/>} exact/>
                    <Route path="/reschedule-reason/:id" element={<RescheduleReason/>} exact/>
                    {/*<Route path="/rhclub" element={<RhClub/>} exact/>*/}
                    {/*<Route path="/rhclub/view" element={<RhClubView/>} exact/>*/}

                    <Route path="/partner/payment/:id" element={<PartnerPayment/>} exact/>

                </Route>
                {MasterRoutes}

            </Route>

            <Route path="/password/success" element={<ThankYou/>} exact/>
            <Route path="/payment/success" element={<PaymentSuccess/>} exact/>
            <Route path="/payment/checkout/:id/:paymentId/:custom/:transId" element={<CheckoutPage/>} exact/>
            <Route path="/checkout/success/" element={<Success/>} exact/>
            <Route path="/checkout/failed/" element={<Failed/>} exact/>
        </>
    )
;


export default AdminRoutes;