import React from 'react';
import {Link} from 'react-router-dom';
import {ModifiedToast} from './toaster'
import {useDispatch, useSelector} from "react-redux";
import {loginUserAction} from "../actions/userActions";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {LoadingIndicator} from "./loading-indicator";

const PartnerLogin = () => {

    const dispatch = useDispatch()
    const getAllLoginState = useSelector(state => state.userLoginReducer)
    const {loading} = getAllLoginState
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL + "/admin/login/"


    const handleFormSubmit = (e) => {
        e.preventDefault();

        let values = {
            email: e.target.elements.email?.value,
            password: e.target.elements.password?.value
        }
        let frm = document.getElementById('loginForm')
        dispatch(loginUserAction(values, baseUrl, frm))

    };

    return (
        <div className="h-screen flex bg-gray-bg1 w-full">
            <HelmetProvider>
                <Helmet>
                    <title>RightHand | Login </title>

                </Helmet>
            </HelmetProvider>
            {loading ? <LoadingIndicator/> : ""}
            <ModifiedToast/>
            <div className=" flex align-center h-screen w-full">
                <div className=" w-full  ">
                    <div
                        className="md:flex align-center justify-between h-full items-center flex-wrap md:flex-nowrap 	">
                        <div className=" md:p-8 p-2 w-full 	 relative">
                            <div className=" pt-0">
                                <div
                                    className="w-full max-w-md m-auto bg-white rounded-lg border border-primaryBorder shadow-default py-8 px-8">
                                    <div className="text-center mb-2	">
                                        <img
                                            src="./images/logoSite.png"
                                            width="100"
                                            alt={"logo"}
                                            className="mx-auto p-3 bg-purple-100 rounded-full"
                                        />
                                    </div>
                                    <h1 className="text-xl font-medium text-gray-700 mb-10 text-center">
                                        Log in to your account
                                    </h1>
                                    <form
                                        onSubmit={handleFormSubmit}
                                        className="text-left"
                                        id="loginForm"
                                    >
                                        <div className="parentGroup">
                                            <label
                                                htmlFor="email"
                                                className="pb-2 block text-gray-400"
                                            >
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                className={`w-full p-3 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-4`}
                                                id="email"
                                                name="email"
                                                placeholder="Your Email"
                                            />
                                            <div className="help-block"></div>
                                        </div>
                                        <div className="parentGroup">
                                            <label
                                                htmlFor="password"
                                                className="pb-2 block text-gray-400"
                                            >
                                                Password
                                            </label>
                                            <input
                                                type="password"
                                                className={`w-full p-3 text-primary border rounded-md outline-none text-sm transition duration-150 ease-in-out mb-2`}
                                                id="password"
                                                name="password"
                                                placeholder="Your Password"
                                            />
                                            <div className="help-block"></div>
                                            <p className="text-purple-600 text-right font-medium">
                                                <Link to="/forgot-password">Forgot password?</Link>
                                            </p>
                                        </div>

                                        <div className=" mt-6 text-center">
                                            <button
                                                className={`bg-purple-500 py-3 font-medium w-full px-4 text-md text-white mb-2 rounded border border-purple focus:outline-none focus:border-purple `}
                                            >
                                                Login
                                            </button>

                                        </div>
                                       </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <ModifiedToast/>
        </div>
    );
};

export default PartnerLogin;