export const appointmentListReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_APPOINTMENT_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'GET_APPOINTMENT_SUCCESS':
            return {
                ...state,
                appointments: action.payload.data,
                loading: false,
                totalCount: action.payload.totalCount
            }
        case'GET_APPOINTMENT_SERVICE_SUCCESS':
            return {
                ...state,
                servicesR: action.payload.data,
                loading: false,
            }
        case'GET_APPOINTMENT_CANCEL_SUCCESS':
            return {
                ...state,
                loading: false,
            }
        case'GET_APPOINTMENT_FREQUENCY_SUCCESS':
            return {
                ...state,
                frequencies: action.payload.frequencies,
                priceMaster: action.payload.priceMaster,
                customerRhExist: action.payload.customerRhExist,
                services: action.payload.services,
                homeService: action.payload.homeService,
                holidayAllow: action.payload.holidayAllow,
                weekDayOff: action.payload.weekDayOff,
                holidayLists: action.payload.holidayLists,
                loading: false,
            }
        case'UPDATE_APPOINTMENT_FREQUENCY_SUCCESS':
            return {
                ...state,
                frequencies: action.payload.frequencies,
                priceMaster: action.payload.priceMaster,
                priceMasterUnFilter: action.payload.priceMasterUnFilter,
            }
        case'GET_APPOINTMENT_DATA_SUCCESS':
            return {
                ...state,
                customers: action.payload.customers,
                loading: false,
                services: action.payload.services,
                drivers: action.payload.drivers,
                cleaners: action.payload.cleaners,
                frequencies: action.payload.frequencies,
                homeService: action.payload.homeService,
                countries: action.payload.countries
            }
        case'GET_APPOINTMENT_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case'EDIT_APPOINTMENT_SUCCESS':
            return {
                ...state,
                appointment: action.payload,
                loading: false
            }
        case'MAP_APPOINTMENT_SUCCESS':
            return {
                ...state,
                loading: false
            }
        case'VIEW_APPOINTMENT_SUCCESS':
            return {
                ...state,
                appointment: action.payload.data,
                loading: false
            }
        case'CREATE_APPOINTMENT_SUCCESS':
            return {
                ...state,
                appointment: action.payload,
                loading: false
            }
        case'DELETE_APPOINTMENT_SUCCESS':
            return {
                ...state,
                loading: false
            }
        case'DELETE_APPOINTMENT_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case'UPDATE_APPOINTMENT_SUCCESS':
            return {
                ...state,
                loading: false
            }
        case'UPDATE_APPOINTMENT_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case'SWAL_CANCEL':
            return {
                ...state,
                loading: false,
            }
        default:
            return {
                ...state,
                loading: false,
            }
    }
}