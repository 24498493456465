export const serviceTypeListReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_SERVICE_TYPE_REQUEST':
            return {
                loading: true,
            }
        case'GET_SERVICE_TYPE_SUCCESS':
            return {
                ...state,
                serviceTypes: action.payload.data,
                loading: false,
                totalCount: action.payload.totalCount
            }
        case'GET_SERVICE_TYPE_FAILED':
            return {
                loading: false,
                error: action.payload
            }

        case'EDIT_SERVICE_TYPE_SUCCESS':
            return {
                serviceType: action.payload,
                loading: false
            }
        case'CREATE_SERVICE_TYPE_SUCCESS':
            return {
                serviceType: action.payload,
                loading: false
            }
        case'DELETE_SERVICE_TYPE_SUCCESS':
            return {
                loading: false
            }
        case'DELETE_SERVICE_TYPE_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        case'UPDATE_SERVICE_TYPE_SUCCESS':
            return {
                loading: false
            }
        case'UPDATE_SERVICE_TYPE_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        default:
            return {
                ...state,
                loading: false
            }
    }
}