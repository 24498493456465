export const subscriptionListReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_SUBSCRIPTION_REQUEST':
            return {
                loading: true,
            }
        case'GET_SUBSCRIPTION_SUCCESS':
            return {
                ...state,
                subscriptions: action.payload.data,
                loading: false,
                totalCount: action.payload.totalCount
            }
        case'GET_SUBSCRIPTION_FAILED':
            return {
                loading: false,
                error: action.payload
            }

        case'EDIT_SUBSCRIPTION_SUCCESS':
            return {
                subscription: action.payload,
                loading: false
            }
        case'CREATE_SUBSCRIPTION_SUCCESS':
            return {
                subscription: action.payload,
                loading: false
            }
        case'DELETE_SUBSCRIPTION_SUCCESS':
            return {
                loading: false
            }
        case'DELETE_SUBSCRIPTION_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        case'UPDATE_SUBSCRIPTION_SUCCESS':
            return {
                loading: false
            }
        case'UPDATE_SUBSCRIPTION_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        default:
            return {
                ...state,
                loading: false
            }
    }
}