export const holidayListReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_HOLIDAY_REQUEST':
            return {
                loading: true,
            }
        case'GET_HOLIDAY_SUCCESS':
            return {
                ...state,
                holidays: action.payload.data,
                loading: false,
                totalCount: action.payload.totalCount
            }
        case'EDIT_HOLIDAY_SUCCESS':
            return {
                holiday: action.payload,
                loading: false
            }
        case'CREATE_HOLIDAY_SUCCESS':
            return {
                holiday: action.payload,
                loading: false
            }
        case'GET_HOLIDAY_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        case'DELETE_HOLIDAY_SUCCESS':
            return {
                loading: false
            }
        case'DELETE_HOLIDAY_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        case'UPDATE_HOLIDAY_SUCCESS':
            return {
                loading: false
            }
        case'UPDATE_HOLIDAY_FAILED':
            return {
                loading: false,
                error: action.payload
            }

        default:
            return {
                ...state,
                loading: false
            }
    }
}