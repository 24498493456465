export const customerListReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_CUSTOMER_REQUEST':
            return {
                loading: true,
            }
        case'GET_CUSTOMER_SUCCESS':
            return {
                ...state,
                customers: action.payload.data,
                loading: false,
                totalCount: action.payload.totalCount
            }
        case'MAKE_NULL':
            return {
                ...state,
                customers: [],
            }
        case'GET_CUSTOMER_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case'EDIT_CUSTOMER_SUCCESS':
            return {
                ...state,
                customer: action.payload.customer,
                wallets: action.payload.wallets,
                totalCredits: action.payload.totalCredits,
                favExist: action.payload.favExist,
                cleaners: action.payload.cleaners,
                loading: false

            }
        case'CREATE_CUSTOMER_SUCCESS':
            return {
                ...state,
                customer: action.payload,
                loading: false
            }
        case'DELETE_CUSTOMER_SUCCESS':
            return {
                ...state,
                loading: false
            }
        case'DELETE_CUSTOMER_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case'UPDATE_CUSTOMER_SUCCESS':
            return {
                ...state,
                loading: false,
            }
        case'UPDATE_CUSTOMER_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case'UPDATE_CUSTOMER_REQUEST':
            return {
                ...state,
                loading: true,
            }
        default:
            return {
                ...state,
                loading: false,
            }
    }
}