import axios from 'axios'
import toast from "react-hot-toast";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {authHeader, commonCatchBlock} from "../helpers/helpers";
import jQuery from "jquery";

const MySwal = withReactContent(Swal)

export const appointmentListsAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_APPOINTMENT_REQUEST'})

    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'GET_APPOINTMENT_SUCCESS', payload: {data: res.data.list, totalCount: res.data.totalPages}})
        })
        .catch((err) => {
                dispatch({type: 'GET_APPOINTMENT_FAILED', payload: err})
                commonCatchBlock(err)
            }
        )
}

export const appointmentDataAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_APPOINTMENT_REQUEST'})

    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({
                type: 'GET_APPOINTMENT_DATA_SUCCESS',
                payload: {
                    customers: res.data.customers,
                    services: res.data.services,
                    drivers: res.data.drivers,
                    cleaners: res.data.cleaners,
                    frequencies: res.data.frequencies,
                    homeService: res.data.homeService,
                    countries: res.data.countries,

                }
            })

        })
        .catch((err) => {
                dispatch({type: 'GET_APPOINTMENT_FAILED', payload: err})
                commonCatchBlock(err)

            }
        )
}

export const appointmentFrequencyAction = (baseUrl, postData) => dispatch => {

    dispatch({type: 'GET_APPOINTMENT_REQUEST'})

    axios.post(baseUrl, postData, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({
                type: 'GET_APPOINTMENT_FREQUENCY_SUCCESS',
                payload: {
                    frequencies: res.data.list,
                    priceMaster: res.data.priceMaster,
                    customerRhExist: res.data.customerRhExist,
                    services: res.data.services,
                    homeService: res.data.homeService,
                    holidayAllow: res.data.holidayAllow,
                    weekDayOff: res.data.weekDayOff,
                    holidayLists: res.data.holidayLists,
                }
            })

        })
        .catch((err) => {
                dispatch({type: 'GET_APPOINTMENT_FAILED', payload: err})
                commonCatchBlock(err)

            }
        )
}

export const appointmentDatFrequencyAction = (data) => dispatch => {
    dispatch({
        type: 'UPDATE_APPOINTMENT_FREQUENCY_SUCCESS',
        payload: {
            frequencies: data?.frequencyList,
            priceMaster: data?.priceMaster,
            priceMasterUnFilter: data?.priceMasterWithoutSort
        }
    })


}

export const appointmentServiceAction = (baseUrl, data) => dispatch => {

    dispatch({type: 'GET_APPOINTMENT_REQUEST'})

    axios.post(baseUrl, {data: data}, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({
                type: 'GET_APPOINTMENT_SERVICE_SUCCESS',
                payload: {data: res.data.list}
            })
        })
        .catch((err) => {
                dispatch({type: 'GET_APPOINTMENT_FAILED', payload: err})
                commonCatchBlock(err)
            }
        )
}

export const appointmentCancellation = (baseUrl, _form, data, listUrl, appointmentType = 'current') => dispatch => {
    let frm = jQuery(_form);
    dispatch({type: 'GET_APPOINTMENT_REQUEST'})

    axios.put(baseUrl, data, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({
                type: 'GET_APPOINTMENT_CANCEL_SUCCESS',
                payload: {data: res.data}
            })
            toast.success(res.data.message, {
                duration: 2000,
                position: 'top-right',
            })
            setTimeout(() => {
                if (appointmentType === 'recurring') {
                    window.location.href = "/appointment/recurring/list"
                } else if (appointmentType === 'rh') {
                    window.location.href = "/appointment/rh/list"
                } else {
                    window.location.href = "/appointment/current/list"
                }
            }, 300)
        })
        .catch((err) => {
                dispatch({type: 'GET_APPOINTMENT_FAILED', payload: err})
                commonCatchBlock(err, frm)
            }
        )
}

export const appointmentDeleteAction = (baseUrl, listUrl, status = '') => dispatch => {

    let statusMsg = "You won't be able to revert this!"
    let buttonMsg = "Delete !"

    if (status === "soft") {
        statusMsg = ''
        buttonMsg = 'Temporary Delete !'
    }

    if (status === "arch") {
        statusMsg = ''
        buttonMsg = 'Archive !'
    }

    if (status === "unarch") {
        statusMsg = ''
        buttonMsg = 'Unarchive !'
    }

    MySwal.fire({
        title: 'Are you sure?',
        text: statusMsg,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: buttonMsg
    }).then((result) => {
        if (result.isConfirmed) {
            dispatch({type: 'GET_APPOINTMENT_REQUEST'})
            axios.delete(baseUrl, {
                headers: authHeader(baseUrl),
            })
                .then((res) => {
                    dispatch({type: 'DELETE_APPOINTMENT_SUCCESS'})
                    toast.success(res.data.message, {
                        duration: 2000,
                        position: 'top-right',
                    })
                    dispatch({type: 'GET_APPOINTMENT_REQUEST'})
                    dispatch(appointmentListsAction(listUrl))
                })
                .catch((err) => {
                        dispatch({type: 'DELETE_APPOINTMENT_FAILED'})
                        commonCatchBlock(err)

                    }
                )
        }
    })
}

export const appointmentEmptyBinAction = (baseUrl, listUrl, data,setIsShowBulk,status = '') => dispatch => {

    let statusMsg = "You won't be able to revert this!"
    let buttonMsg = "Delete !"

    if (status === "soft") {
        statusMsg = ''
        buttonMsg = 'Temporary Delete !'
    }

    if (status === "arch") {
        statusMsg = ''
        buttonMsg = 'Archive !'
    }

    MySwal.fire({
        title: 'Are you sure?',
        text: statusMsg,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: buttonMsg
    }).then((result) => {
        if (result.isConfirmed) {
            dispatch({type: 'GET_APPOINTMENT_REQUEST'})
            axios.post(baseUrl, data,{
                headers: authHeader(baseUrl),
            })
                .then((res) => {
                    dispatch({type: 'DELETE_APPOINTMENT_SUCCESS'})
                    toast.success(res.data.message, {
                        duration: 2000,
                        position: 'top-right',
                    })
                    jQuery('input:checkbox').prop('checked', false);
                    dispatch({type: 'GET_APPOINTMENT_REQUEST'})
                    dispatch(appointmentListsAction(listUrl))
                })
                .catch((err) => {
                        dispatch({type: 'DELETE_APPOINTMENT_FAILED'})
                        commonCatchBlock(err)

                    }
                )
        }
    })
}

export const appointmentCancelAction = (baseUrl, listUrl) => dispatch => {

    MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Cancel Appointment'
    }).then((result) => {
            if (result.isConfirmed) {
                dispatch({type: 'GET_APPOINTMENT_REQUEST'})
                axios.put(baseUrl, {}, {
                    headers: authHeader(baseUrl),
                })
                    .then((res) => {
                        dispatch({type: 'DELETE_APPOINTMENT_SUCCESS'})
                        toast.success(res.data.message, {
                            duration: 2000,
                            position: 'top-right',
                        })
                        dispatch({type: 'GET_APPOINTMENT_REQUEST'})
                        dispatch(appointmentListsAction(listUrl))
                    })
                    .catch((err) => {
                            dispatch({type: 'DELETE_APPOINTMENT_FAILED'})
                            commonCatchBlock(err)
                        }
                    )
            }
        }
    )
}

export const appointmentStatusAction = (baseUrl, dataset = {}, listUrl, id, viewTrue = false) => dispatch => {

    dispatch({type: 'GET_APPOINTMENT_REQUEST'})
    axios.put(baseUrl, dataset, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            if (viewTrue) {
                toast.success(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })
                dispatch({type: 'GET_APPOINTMENT_REQUEST'})
                dispatch(appointmentGetEditAction(listUrl))
            } else {
                dispatch({type: 'UPDATE_APPOINTMENT_SUCCESS'})
                // if (res.data.user.isActive) {

                toast.success(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })
                setTimeout(() => {
                    window.location.href = "/appointment/view/" + id
                    // dispatch(appointmentGetViewAction(listUrl))
                }, 1000)
                // } else {
                //     toast.error(res.data.message, {
                //         duration: 2000,
                //         position: 'top-right',
                //     })
                // }
                // dispatch({type: 'GET_APPOINTMENT_REQUEST'})
                // dispatch(appointmentGetEditAction(listUrl))
            }

        })
        .catch((err) => {

                dispatch({type: 'UPDATE_APPOINTMENT_FAILED'})
                commonCatchBlock(err)
                // if (viewTrue) {
                //     dispatch(appointmentGetEditAction(listUrl))
                // }
                // else {
                //     dispatch(appointmentListsAction(listUrl))
                // }
            }
        )

}

export const paymentUpdateAction = (baseUrl, dataset = {}, listUrl, _form) => dispatch => {

    let frm = jQuery(_form);
    dispatch({type: 'GET_APPOINTMENT_REQUEST'})
    axios.put(baseUrl, dataset, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            setTimeout(() => {
                toast.success(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })
            }, 200)
            setTimeout(() => {
                dispatch({type: 'GET_APPOINTMENT_REQUEST'})
                dispatch(appointmentListsAction(listUrl))
            }, 400)
        })
        .catch((err) => {
                dispatch({type: 'GET_APPOINTMENT_FAILED'})
                commonCatchBlock(err, frm)
                // dispatch(appointmentListsAction(listUrl))
            }
        )

}

export const appointmentRatingReview = (baseUrl, dataset = {}, listUrl, id) => dispatch => {

    dispatch({type: 'GET_APPOINTMENT_REQUEST'})
    axios.put(baseUrl, dataset, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'MAP_APPOINTMENT_SUCCESS'})
            toast.success(res.data.message, {
                duration: 2000,
                position: 'top-right',
            })
            // dispatch(appointmentGetEditAction(listUrl))
            setTimeout(() => {
                window.location.href = "/appointment/view/" + id
                // dispatch(appointmentGetViewAction(listUrl))
            }, 1000)

        })
        .catch((err) => {
                dispatch({type: 'UPDATE_APPOINTMENT_FAILED'})
                commonCatchBlock(err)
                // if (viewTrue) {
                dispatch(appointmentGetViewAction(listUrl))
                // }
                // else {
                //     dispatch(appointmentListsAction(listUrl))
                // }
            }
        )

}

export const appointmentGetEditAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_APPOINTMENT_REQUEST'})
    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'EDIT_APPOINTMENT_SUCCESS', payload: res.data.appointment})
            dispatch({
                type: 'GET_APPOINTMENT_DATA_SUCCESS',
                payload: {
                    customers: res.data.customers,
                    services: res.data.services,
                    drivers: res.data.drivers,
                    cleaners: res.data.cleaners,
                    frequencies: res.data.frequencies
                }
            })
        })
        .catch((err) => {
                dispatch({type: 'GET_APPOINTMENT_FAILED'})
                commonCatchBlock(err)
            }
        )
}

export const appointmentGetViewAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_APPOINTMENT_REQUEST'})
    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({
                type: 'VIEW_APPOINTMENT_SUCCESS', payload: {
                    data: res.data.appointment,
                    cleaners: res.data.totalPages,
                    drivers: res.data.totalPages
                }
            })
        })
        .catch((err) => {
                dispatch({type: 'GET_APPOINTMENT_FAILED'})
                commonCatchBlock(err)
            }
        )
}

export const appointmentCleanerDriverAction = (baseUrl, dataPrep, method, listUrl, id, show = false) => dispatch => {
    if (show) {
        MySwal.fire({
            title: 'Are you sure?',
            text: "Some pros not belong to driver group!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Assigned!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch({type: 'GET_APPOINTMENT_REQUEST'})
                commonAllotment(baseUrl, dataPrep, method, id, listUrl, dispatch)
            } else {
                dispatch({type: 'SWAL_CANCEL'})
            }
        })
    } else {
        dispatch({type: 'GET_APPOINTMENT_REQUEST'})
        commonAllotment(baseUrl, dataPrep, method, id, listUrl, dispatch)
    }

}


function commonAllotment(baseUrl, dataPrep, method, id, listUrl, dispatch) {
    axios({
        url: baseUrl, data: dataPrep, method: method,
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({
                type: 'MAP_APPOINTMENT_SUCCESS'
            })
            toast.success(res.data.message, {
                duration: 2000,
                position: 'top-right',
            });
            setTimeout(() => {
                window.location.href = "/appointment/view/" + id
                // dispatch(appointmentGetViewAction(listUrl))
            }, 1000)
        })
        .catch((err) => {
                dispatch({type: 'GET_APPOINTMENT_FAILED'})
                dispatch(appointmentGetViewAction(listUrl))
                commonCatchBlock(err)
            }
        )

}

export const appointmentCreateEditAction = (baseUrl, method, dataPrep, _form, getUrl, getId, customerUrl, appointmentType = 'current') => dispatch => {
    let frm = jQuery(_form);

    dispatch({type: 'GET_APPOINTMENT_REQUEST'})
    axios({
        url: baseUrl, data: dataPrep, method: method,
        headers: authHeader(baseUrl),
    })
        .then((response) => {
            dispatch({type: 'CREATE_APPOINTMENT_SUCCESS', payload: response.data.appointment})
            toast.success(response.data.message, {
                duration: 2000,
                position: 'top-right',
            });
            setTimeout(() => {
                if (appointmentType === 'recurring') {
                    window.location.href = "/appointment/recurring/list"
                } else if (appointmentType === 'rh') {
                    window.location.href = "/appointment/rh/list"
                } else {
                    window.location.href = "/appointment/current/list"
                }
            }, 1000)
        })
        .catch((err) => {
                dispatch({type: 'GET_APPOINTMENT_FAILED'})
                // if (getId) {
                //     dispatch(appointmentGetEditAction(getUrl));
                // } else {
                //     dispatch(appointmentDataAction(customerUrl))
                // }
                commonCatchBlock(err, frm)
            }
        )
}

export const doorNotificationUpdate = (baseUrl, data) => dispatch => {

    axios.put(baseUrl, data, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {

        })
        .catch((err) => {

            }
        )
}