import React from "react";
import {useLocation, Navigate, Outlet} from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = ({allowedRoles}) => {
    // const { auth } = useAuth();
    // const location = useLocation();
    // console.log(allowedRoles, 1111111111111)
    // return <Outlet/>
    return (
        allowedRoles ?
            <Outlet/> :
            <Navigate to='/forbidden' replace/>
    );
}

export default RequireAuth;