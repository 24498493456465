import {initializeApp} from 'firebase/app';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';
import {getAuth, signInAnonymously} from "firebase/auth";
import {ref, onValue, getDatabase} from 'firebase/database';


const firebaseConfig = {
    apiKey: "AIzaSyB4H6Vcc92d4HnLBTdY1rKaPCFlt-LQ1k4",
    authDomain: "right-hands.firebaseapp.com",
    projectId: "right-hands",
    storageBucket: "right-hands.appspot.com",
    messagingSenderId: "395949074977",
    appId: "1:395949074977:web:79bcfc6aca38a2d6a31160",
    measurementId: "G-P7TBY62EDM"
};


initializeApp(firebaseConfig);


const messaging = getMessaging();

const auth = getAuth();
signInAnonymously(auth)
    .then(() => {
        // Signed in..
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
    });


export const requestForToken = async () => {
    return await getToken(messaging, {vapidKey: "BMLPeqqiC1xez864rs9uw0BLjAYmXTSMk1JqLCyr9HLQKDtrIDpt0ErJHA-xwWaBJ3x1YVS0_6-WFY-cn7drbT8"})
        .then((currentToken) => {
            if (currentToken) {
                // console.log('current token for client: ', currentToken);
                // Perform any other neccessary action with the token
                return currentToken
            } else {
                // Show permission request UI

                // console.log('No registration token available. Request permission to generate one.');
                return false
            }
        })
        .catch((err) => {
            // console.log('An error occurred while retrieving token. ', err);
            return false
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            // console.log("payload", payload)
            resolve(payload);
        });
    });

export const currentOpenAppointment = async (selectedCountry = []) => {
    const db = getDatabase()
    const dbRef = ref(db, 'Appointments')
    return new Promise((resolve) => {
        onValue(dbRef, (snapshot) => {
            let records = []
            snapshot.forEach((childSnapShot) => {
                let keyName = childSnapShot.key
                let data = childSnapShot.val()
                records.push({
                    "name": keyName, location: {
                        lat: parseFloat(data.latitude),
                        lng: parseFloat(data.longitude)
                    },
                    appointmentRef: data.appointmentNo,
                })
            })
            resolve(records)
        })
    });
}
export const currentOpenDriver = async () => {
    const db = getDatabase()
    const dbRef = ref(db, 'Driver')
    return new Promise((resolve) => {
        onValue(dbRef, (snapshot) => {
            let records = []
            snapshot.forEach((childSnapShot) => {
                let keyName = childSnapShot.key
                let data = childSnapShot.val()
                records.push({
                    "name": keyName, location: {
                        lat: parseFloat(data.latitude),
                        lng: parseFloat(data.longitude)
                    },
                    driverName: data.name,
                })
            })
            resolve(records)
        })
    });

}
export const currentOpenCleaner = async (groupCleaners = [], selectedCountry = []) => {

    const db = getDatabase()
    const dbRef = ref(db, 'Cleaner')
    return new Promise((resolve) => {
        onValue(dbRef, (snapshot) => {
            let records = []

            snapshot.forEach((childSnapShot) => {
                let keyName = childSnapShot.key
                let data = childSnapShot.val()
                if (selectedCountry?.length > 0) {
                    if (selectedCountry.includes(data.countryId)) {
                        if (groupCleaners?.length > 0) {
                            if (groupCleaners.includes(keyName)) {
                                pushRecords(records, keyName, data)
                            }
                        } else {
                            pushRecords(records, keyName, data)
                        }
                    }
                } else {
                    if (groupCleaners?.length > 0) {
                        if (groupCleaners.includes(keyName)) {
                            pushRecords(records, keyName, data)
                        }
                    } else {
                        pushRecords(records, keyName, data)
                    }
                }
            })
            resolve(records)
        })
    });

}

async function pushRecords(records, keyName, data) {

    return records.push({
        "name": keyName, location: {
            lat: parseFloat(data.latitude),
            lng: parseFloat(data.longitude)
        }, distance: data.distance, appointment_id: data.appointmentId,
        proName: data.name,
        destinationLat: parseFloat(data.appointment_latitude),
        destinationLong: parseFloat(data.appointment_longitude)
    })
}

