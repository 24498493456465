import React from 'react'
import {Bar} from 'react-chartjs-2'
import {Pie} from 'react-chartjs-2';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);


const Charts = ({chartData}) => {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Appointment Status',
            },
        },
    };

    const options2 = {
        legend: {
            "position": "bottom",
            display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: false,
        offset: true
    };

    const totalAppointments = (chartData && chartData?.totalAppointments?.length > 0) ?
        chartData?.totalAppointments : []
    const completedAppointments = (chartData && chartData?.completedAppointments?.length > 0) ?
        chartData?.completedAppointments : []
    const monthNames = {
        '01': 'January', '02': 'February', '03': 'March', '04': 'April', '05': 'May', '06': 'June', '07': 'July',
        '08': 'August', '09': 'September', '10': 'October', '11': 'November', '12': 'December',
    }
    let labels = []
    labels = (chartData && chartData?.totalAppointments?.length > 0) ?
        (totalAppointments).map(function (el) {
            return monthNames[el._id]
        }) : []

    const data = {
        labels,
        datasets: [
            {
                label: 'Total ',
                data: totalAppointments.map(function (el) {
                    return el.numberOfAppointments
                }),
                backgroundColor: 'rgba(255, 0, 0, 0.7)',
            },
            {
                label: 'Completed',
                data: completedAppointments.map(function (el) {
                    return el.numberOfAppointments
                }),
                backgroundColor: 'rgba(34,139,34, 0.8)',
            },
        ],
    };

    // const [selected, setSelected] = useState(undefined);
    // const [hovered, setHovered] = useState(undefined);
    // const lineWidth = 60;


    const cancelledCount = (chartData && chartData?.appointmentCount) ?
        chartData?.appointmentCount : 0
    const completedCount = (chartData && chartData?.completedCount) ?
        chartData?.completedCount : 0
    const pendingCount = (chartData && chartData?.pendingCount) ?
        chartData?.pendingCount : 0
    const assignedCount = (chartData && chartData?.assignedCount) ?
        chartData?.assignedCount : 0
    const onGoingCount = (chartData && chartData?.onGoingCount) ?
        chartData?.onGoingCount : 0

    const data33 = {
        labels: ['Cancelled', 'Completed', 'Pending','Waiting','On Going'],
        datasets: [
            {
                label: '# of Appointment',
                data: [cancelledCount, completedCount, pendingCount, assignedCount, onGoingCount],
                backgroundColor: [
                    'rgba(255, 0, 0, 0.7)',
                    'rgba(34,139,34, 0.7)',
                    'rgba(255,69,0,0.7)',
                    'rgba(105,105,105,0.7)',
                    'rgba(0,191,255,0.7)'
                ],
                borderColor: [
                    'rgba(255, 0, 0, 1)',
                    'rgba(34,139,34, 1)',
                    'rgba(255,69,0,1)',
                    'rgba(105,105,105,1)',
                    'rgba(0,191,255,1)'
                ],
                borderWidth: 1,
            },
        ],
    };


    return (
        <>
            <div className=" flex w-full lg:mt-5  mt-4  mb-0 md:flex-nowrap	flex-wrap">
                {/*bar graph chart*/}

                <div className="md:w-1/2 md:mr-6 md:mb-0 mb-4 w-full flex  ">
                    <div className=" card dark:bg-gray-700 md:p-6 p-4  w-full ">

                        <div
                            style={{
                                width: '100%',
                                height: 'auto'
                            }}
                        >
                            <Bar options={options} data={data} type={''}/>
                        </div>
                    </div>
                </div>

                {/*pie chart*/}

                <div className="md:w-1/2  md:mb-0 w-full flex ">
                    <div className=" card dark:bg-gray-700 md:p-6 p-4 w-full pieChart ">
                        <div
                            style={{
                                width: '100%',
                                height: 'auto'
                            }}
                        >

                            <Pie data={data33} options={options2} type={''}/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Charts
