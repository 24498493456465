import axios from 'axios'
import toast from "react-hot-toast";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {authHeader, commonCatchBlock} from "../helpers/helpers";
import jQuery from "jquery";

const MySwal = withReactContent(Swal)

export const cleanerListsAction = (baseUrl, partner = false) => dispatch => {

    dispatch({type: 'GET_CLEANERS_REQUEST'})
    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'GET_CLEANERS_SUCCESS', payload: {data: res.data.list, totalCount: res.data.totalPages}})
        })
        .catch((err) => {
                dispatch({type: 'GET_CLEANERS_FAILED', payload: err})
                commonCatchBlock(err)
            }
        )
}

export const cleanerReviewListsAction = (baseUrl, partner = false) => dispatch => {

    dispatch({type: 'GET_CLEANERS_REQUEST'})
    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'GET_CLEANERS_SUCCESS', payload: {data: res.data.list, totalCount: res.data.totalPages}})
        })
        .catch((err) => {
                dispatch({type: 'GET_CLEANERS_FAILED', payload: err})
                commonCatchBlock(err)
            }
        )
}

export const cleanerDeleteAction = (baseUrl, listUrl, partner = false) => dispatch => {

    MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete!'
    }).then((result) => {
            if (result.isConfirmed) {
                dispatch({type: 'GET_CLEANERS_REQUEST'})
                axios.delete(baseUrl, {
                    headers: authHeader(baseUrl),
                })
                    .then((res) => {
                        dispatch({type: 'DELETE_CLEANERS_SUCCESS'})
                        toast.success(res.data.message, {
                            duration: 2000,
                            position: 'top-right',
                        })
                        dispatch({type: 'GET_DRIVERS_REQUEST'})
                        dispatch(cleanerListsAction(listUrl))
                    })
                    .catch((err) => {
                            dispatch({type: 'DELETE_CLEANERS_FAILED'})
                            commonCatchBlock(err)
                            dispatch(cleanerListsAction(listUrl))
                        }
                    )
            }

        }
    )
}

export const cleanerStatusAction = (baseUrl, listUrl, partner = false) => dispatch => {

    dispatch({type: 'GET_CLEANERS_REQUEST'})
    axios.put(baseUrl, {}, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'UPDATE_CLEANERS_SUCCESS'})
            if (res.data.user.isActive) {
                toast.success(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })
            } else {
                toast.error(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })
            }

            dispatch({type: 'GET_CLEANERS_REQUEST'})
            dispatch(cleanerListsAction(listUrl))
        })
        .catch((err) => {
                dispatch({type: 'UPDATE_CLEANERS_FAILED'})
                commonCatchBlock(err)
            }
        )

}

export const cleanerImageRemoveAction = (baseUrl, listUrl, partner = false) => dispatch => {

    dispatch({type: 'GET_CLEANERS_REQUEST'})
    axios.delete(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'UPDATE_CLEANERS_SUCCESS'})

            toast.success(res.data.message, {
                duration: 2000,
                position: 'top-right',
            })
            dispatch({type: 'EDIT_CLEANERS_SUCCESS', payload: res.data.cleaner})

        })
        .catch((err) => {
                dispatch({type: 'UPDATE_CLEANERS_FAILED'})
                commonCatchBlock(err)
                dispatch(cleanerGetEditAction(listUrl));
            }
        )
}

export const cleanerGetEditAction = (baseUrl, partner = false) => dispatch => {

    dispatch({type: 'GET_CLEANERS_REQUEST'})
    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({
                type: 'EDIT_CLEANERS_SUCCESS',
                payload: {data: res.data.user, attendanceResult: res.data.attendance}
            })
        })
        .catch((err) => {
                dispatch({type: 'GET_CLEANERS_FAILED'})
                commonCatchBlock(err)
            }
        )

}

export const cleanerCreateEditAction = (baseUrl, method, formData, _form, getUrl, getId, partner = false, pc = false) => dispatch => {

    dispatch({type: 'GET_CLEANERS_REQUEST'})

    let frm = jQuery(_form)
    let currentToken = localStorage.getItem('currentToken') ? JSON.parse(localStorage.getItem('currentToken')) : null
    axios({
        url: baseUrl, data: formData, method: method,
        headers: {
            "x-auth-token": currentToken,
            'Content-Type': 'multipart/form-data'
        },
    })
        .then((res) => {

            toast.success(res.data.message, {
                duration: 2000,
                position: 'top-right',
            });
            dispatch({type: 'CREATE_CLEANERS_SUCCESS', payload: res.data.cleaner})
            setTimeout(() => {
                // console.log(pc, partner)
                if (pc) {
                    window.location.href = "/partners/cleaner/list"
                } else {
                    window.location.href = (partner) ? "/partner/cleaner/list" : "/cleaner/list"
                }

            }, 300)
        })
        .catch((err) => {
                dispatch({type: 'GET_CLEANERS_FAILED'})
                commonCatchBlock(err, frm)
                if (getId) {
                    dispatch(cleanerGetEditAction(getUrl));
                }

            }
        )
}
