import {Link} from "react-router-dom";
import * as React from "react";
import {logoutUserAction} from "../actions/userActions";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {notificationSortsAction} from "../actions/notificationActions";

export function Header() {
    const dispatch = useDispatch();
    const userName = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null
    const getAllNotificationState = useSelector(
        (state) => state.notificationListReducer
    );
    const {sorts} = getAllNotificationState;

    const baseUrl = process.env.REACT_APP_LOCAL_API_URL;
    const listUrl = baseUrl + "/notification/sorts/list/";

    useEffect(() => {
        dispatch(notificationSortsAction(listUrl));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <header className="bg-white border border-b	">
            <div className="flex justify-right items-center ">
                <div className="logo w-20 lg:hidden block ">
                    <Link to="/dashboard">
                        {" "}
                        <img
                            src="/images/logoHand.png"
                            alt={"Logo"}
                            className="max-w-full mx-auto border-1 rounded-full drop-shadow w-12 "
                        />
                    </Link>
                </div>
                <div className="nav w-full">
                    <ul className="flex justify-end items-center pr-5  ">
                        <li className="lg:hidden visible">
                            <button className="togglemenmob mr-5 text-2xl text-purple-500 ">
                                <i className="fa fa-bars"/>
                            </button>

                            <div
                                className=" overflow-auto z-50 sidebar overflow-auto border-r shadow-2xl p-4 pt-16 bg-white border fixed top-0 -left-full h-screen w-60  ">
                                <Link
                                    to={"#"}
                                    className="togglemenumob right-2 top-2 p-2 absolute text-gray-800 bg-gray-200 rounded-full "
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    ></svg>
                                </Link>
                                <ul>
                                    <li>
                                        <Link
                                            to={"/dashboard"}
                                            className="p-3  rounded-lg pl-4 flex items-center text-gray-500  hover:bg-blue-400 hover:text-white text-2xl mb-3 dark:bg-blue-900"
                                        >
                                            <i className="fa fa-home w-9 "/>{" "}
                                            <span className="text-base ">Dashboard</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to={"/cleaner"}
                                            className="p-3 pl-4 flex rounded items-center text-gray-500  text-2xl hover:bg-blue-400 hover:text-white dark:text-gray-300 mb-3 "
                                        >
                                            <i className="fa fa-users w-9 "/>{" "}
                                            <span className="text-base ">Cleaner</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={"/driver"}
                                            className="p-3 pl-4 flex rounded items-center text-gray-500  text-2xl hover:bg-blue-400 hover:text-white dark:text-gray-300 mb-3 "
                                        >
                                            <i className="fa fa-calendar w-9 "/>{" "}
                                            <span className="text-base ">Driver</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={"/appointment"}
                                            className="p-3 pl-4 flex rounded items-center text-gray-500  text-2xl hover:bg-blue-400 hover:text-white dark:text-gray-300 mb-3 "
                                        >
                                            <i className="fa fa-comments-o w-9 "/>{" "}
                                            <span className="text-base ">Appointments</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to={"#"}
                                            className="p-3 pl-4 flex rounded items-center text-gray-500  text-2xl hover:bg-blue-400 hover:text-white dark:text-gray-300 mb-3 "
                                        >
                                            <i className="fa fa-user-md w-9  "/>{" "}
                                            <span className="text-base ">Services</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to={"/profile"}
                                            className="p-3 pl-4 flex rounded items-center text-gray-500  hover:bg-blue-400 hover:text-white dark:text-gray-300 mb-3 text-2xl"
                                        >
                                            <i className="fa fa-cog w-9 "/>
                                            <span className="text-base ">Settings</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={"/profile"}
                                            className="p-3 pl-4 flex rounded items-center text-gray-500  hover:bg-blue-400 hover:text-white dark:text-gray-300 mb-3 text-2xl"
                                        >
                                            <i className="fa fa-bell w-9 "/>{" "}
                                            <span className="text-base ">Settings</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <button
                                            onClick={() => dispatch(logoutUserAction())}
                                            className="border p-1 px-3 rounded text-sm bg-white"
                                        >
                                            <i className="fa fa-sign-out mr-2 "/>
                                            Logout
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <div className="dropdown relative z-30">
                                <div className=" text-black dark:text-white block datatoggle py-2 mr-4">
                                    <img src="/images/bell.png" alt={"Logo"} className="  "/>
                                </div>
                                <div
                                    style={{display: "none"}}
                                    className="slidecontent dropdown-menu absolute right-0 w-72 top-100 text-gray-400 bg-white border-t shadow-md  dark:bg-gray-500"
                                >

                                    {sorts?.length > 0 ? (
                                        sorts.map((sort, index) => {
                                            if (sort.notification_data?.appointment_id)
                                                return (
                                                    <div className=" border-b px-3 py-2 " key={index}
                                                         data-index={index}>
                                                        <h3 className="text-sm text-left">
                                                            <Link
                                                                title="view"
                                                                to={"/appointment/view/" + sort.notification_data?.appointment_id}>
                                                                {sort.notification_data?.title}
                                                            </Link>
                                                        </h3>
                                                    </div>)
                                            else
                                                return (
                                                    <div className=" border-b px-3 py-2 " key={index}
                                                         data-index={index}>
                                                        <h3 className="text-sm text-left">
                                                            <Link
                                                                title="view"
                                                                to={"/notification/"}>
                                                                {sort.notification_data?.title}
                                                            </Link>
                                                        </h3>
                                                    </div>)

                                        })) : (
                                        <div className=" border-b px-3 py-2 ">
                                            <h3 className="text-sm text-left">
                                                No Appointment !
                                            </h3>
                                        </div>
                                    )}

                                    {(sorts?.length > 0) &&
                                    (<div className="p-4 text-sm text-right">

                                        <a href={"/notification"} className=" font-bold text-purple-500">View
                                            All</a>

                                    </div>)
                                    }
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="dropdown relative z-30">
                                <div className=" text-black dark:text-white block datatoggle py-2">
                                    <img
                                        src="/images/logoHand.png"
                                        alt={"Logo"}
                                        className="shadow-lg border md:w-8 w-8 bg-white rounded-full  border-white  "
                                    />
                                </div>
                                <div
                                    style={{display: "none"}}
                                    className="slidecontent dropdown-menu absolute right-0 w-72 top-100 text-gray-400 bg-white border-t shadow-md  dark:bg-gray-500"
                                >
                                    <div className="flex p-5">
                                        <div className="profileImg mr-5">
                                            <img
                                                src="/images/logoHand.png"
                                                alt={"Logo"}
                                                className="shadow-lg border md:w-15 w-12 bg-white rounded-full  border-white  "
                                            />
                                        </div>
                                        <div className="profileContent text-left">
                                            <h3 className="text-gray-600 ">
                                                {userName ? userName.name : "N/A"}!
                                            </h3>
                                            <h5 className="text-sm">RightHands</h5>
                                        </div>
                                    </div>
                                    <div className="flex  border-t items-center  p-2 bg-gray ">
                                        <Link to={"/profile"}>
                                            <button className=" p-1 px-3 rounded text-left text-md ">
                                                <i className="fa fa-user mr-2 text-purple-500"/>
                                                Profile
                                            </button>
                                        </Link>
                                    </div>
                                    {/*<div className="flex border-t items-center bg-gray p-2 ">*/}
                                    {/*    <Link to={"/price/setting"}>*/}
                                    {/*        <button className=" p-1 px-3 rounded text-md text-left ">*/}
                                    {/*            <i className="fa fa-cog mr-2 text-purple-500"/>*/}
                                    {/*            Settings*/}
                                    {/*        </button>*/}
                                    {/*    </Link>*/}
                                    {/*</div>*/}
                                    <div className="flex  border-t items-center bg-gray p-2">
                                        <button
                                            onClick={() => dispatch(logoutUserAction())}
                                            className=" p-1 px-3 rounded text-md text-left "
                                        >
                                            <i className="fa fa-sign-out mr-2 text-purple-500"/>
                                            Logout
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    );
}
