import {BrowserRouter as Router, Routes} from "react-router-dom";

import React, {Suspense} from "react";

import AdminRoutes from "./adminRoutes";
import PartnerRoutes from "./partnerRouter";
import {LoadingIndicator} from "../components/loading-indicator";

const RouterFile = () => (
    <Router>
        <div>
            <Suspense fallback={<LoadingIndicator/>}>
                <Routes>
                    {AdminRoutes}
                    {PartnerRoutes}
                </Routes>
            </Suspense>
        </div>
    </Router>
);

export default RouterFile;