import * as React from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';

import {useEffect, useRef, useState} from 'react'
import {useLocation, useParams, useSearchParams} from "react-router-dom";
import {ModifiedToast} from "../../components/toaster";

import {partnerListsAction} from "../../actions/partnerActions";
import {useDispatch, useSelector} from "react-redux";

import {LoadingIndicator} from "../../components/loading-indicator";
import Pagination from "../../components/inc/pagination";

import jQuery from "jquery";

import CommonFilter from "../appointment/appointmentFilter";


function PartnerPayment() {
    const ref = useRef();
    const dispatch = useDispatch()
    const {id} = useParams()
    const getAllPartnersState = useSelector(state => state.partnerListReducer)
    const {
        loading, partners, totalCount, totalCashAppointment,
        totalPenaltyAppointment,
        totalOnlineAppointment
    } = getAllPartnersState
    // const [showResults, setShowResults] = React.useState(false);
    // const onClickFilter = () => setShowResults(!showResults);
    const search = useLocation().search;
    const [searchParams, setSearchParams] = useSearchParams()
    const searchTerm = searchParams.get('name') || ''
    const pageTerm = searchParams.get('page') || ''
    const [page, setPage] = useState(pageTerm ? Number(pageTerm) : 1)
    const [pages, setPages] = useState(totalCount ? totalCount : page)
    const name = new URLSearchParams(search).get('name');
    const sortTerm = searchParams.get('sortBy') || ''
    const sortOrderTerm = searchParams.get('sortOrder') || 'asc'
    const [sortBy, setSortBy] = useState(sortTerm ? sortTerm : 'first_name')
    const [sortOrder, setSortOrder] = useState(sortOrderTerm ? sortOrderTerm : 'asc')
    const query = new URLSearchParams();
    const offsetTerm = searchParams.get('offset') || ''
    const inOffset = offsetTerm ? offsetTerm : 16
    const baseUrl = process.env.REACT_APP_LOCAL_API_URL
    const listUrl = baseUrl + "/partner/payment/list/" + id
    const downloadUrl = baseUrl + `/partner/download/payment/list/${id}`
    const secondaryUrl = baseUrl + `/partner/payment/list/?page=${page}`
    // const pageUrl = (searchParams.get('name')) ?
    //     baseUrl + `/partner/payment/list/?name=${searchTerm}&page=${page}` : secondaryUrl
    // let queryUrl = ''

    //filter
    const [showFilter, setShowFilter] = useState(false);
    const onClickFilter2 = () => {
        let sp = Object.fromEntries([...searchParams])
        sp['showFilter'] = !showFilter
        setShowFilter(!showFilter);
        setSearchParams(sp)
    }
    // const onClickCancelFilter = () => setShowFilter(false);

    //advanced filter
    const [startDate, setStartDate] = useState(false);
    const [createdAt, setCreatedAt] = useState(false);
    const [appRef, setAppRef] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(false);

    useEffect(() => {
        let offset = inOffset
        setSearchParams({name, page, offset, sortBy, sortOrder})
        if (searchTerm !== 'null')
            query.set("name", searchTerm)
        query.set("page", page)
        query.set("offset", offset)
        query.set("sortBy", sortBy)
        query.set("sortOrder", sortOrder)
        let queryUrl = (query.toString()) ? listUrl + `?` + query.toString() : secondaryUrl
        dispatch(partnerListsAction(queryUrl))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    async function queryUrlMaking(download = false, sortTypeValue = false, sortingOrder = false) {
        let offset = jQuery('#offsetValue').val()
        let sortOrder = (sortingOrder) ? sortingOrder : 'asc'
        setSearchParams({
            name, page, offset, sortBy, sortOrder
        })

        if (searchTerm !== 'null')
            query.set("name", searchTerm)
        query.set("page", page)
        query.set("offset", offset)
        query.set("sortBy", sortTypeValue)
        query.set("sortOrder", sortingOrder)

        if (download)
            return (query.toString()) ? downloadUrl + `?` + query.toString() : downloadUrl
        return (query.toString()) ? listUrl + `?` + query.toString() : secondaryUrl
    }


    useEffect(() => {
        if (partners?.length > 0) {
            setPages(totalCount)
        } else {
            if (partners !== undefined)
                setPages(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partners, page, totalCount])

    // async function handleRecordDelete(partnerId) {
    //     let url = baseUrl + "/partner/delete/" + partnerId
    //     let queryUrl = await queryUrlMaking()
    //     dispatch(partnerDeleteAction(url, queryUrl))
    // }

    // async function handleActiveStatus(partnerId) {
    //     let url = baseUrl + "/partner/update/status/" + partnerId
    //     let queryUrl = await queryUrlMaking()
    //     dispatch(partnerStatusAction(url, queryUrl))
    // }

    // async function handlePartnerSearch(e) {
    //     let name = jQuery('#inputSearch').val()
    //     let offset = jQuery('#offsetValue').val()
    //     if (name) {
    //         setSearchParams({name, page, offset, sortBy, sortOrder})
    //         query.set("name", name)
    //
    //     } else {
    //         query.set("name", '')
    //         setSearchParams({name, page, offset, sortBy, sortOrder})
    //     }
    //     setPage(1)
    //     query.set("page", 1)
    //     query.set("offset", offset)
    //     query.set("sortBy", sortBy)
    //     query.set("sortOrder", sortOrder)
    //     setInOffset(offset)
    //     let queryUrl = (query.toString()) ? listUrl + `?` + query.toString() : secondaryUrl
    //     await dispatch(partnerListsAction(queryUrl))
    //
    // }

    const handleSortOrder = async (sortValue, sortType) => {
        setSortBy(sortType)
        let sortingOrder = (sortValue === 'desc') ? 'asc' : 'desc'
        setSortOrder(sortingOrder)
        let queryUrl = await queryUrlMaking(false, sortType, sortingOrder)
        dispatch(partnerListsAction(queryUrl))

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const optimisedSearch = useCallback(debounce(handlePartnerSearch), [])
    //
    // async function exportData() {
    //     let baseUrl = await queryUrlMaking(true)
    //     dispatch(reportReportDownloadAction(baseUrl, 'Partners'))
    // }

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Right Hands | Partner Payment List</title>
                </Helmet>
            </HelmetProvider>
            {loading ? <LoadingIndicator/> : ""}
            <ModifiedToast/>

            <div className="w-100 py-6 md:px-10 px-3">
                <h2 className="text-2xl mb-3 font-normal  ">Partner Payment List</h2>

                <div className="flex justify-between items-center mb-6 flex-wrap lg:flex-nowrap">
                    <div className="xl:w-1/3 lg:w-1/2 md:w-1/2 md:mb-0 mb-2 w-full">
                        {/* <input
                            type="text"
                            id="inputSearch"
                            defaultValue={(searchTerm !== 'null') ? searchTerm : ''}
                            onChange={optimisedSearch}
                            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Search by partner name"
                        /> */}
                    </div>
                    <div className="flex md:w-auto justify-end w-full">
                        <button
                            className="p-1 px-4 rounded  float-right bg-white  sm:mb-0 mb-1 "
                            onClick={onClickFilter2}
                        >
                            Filter <span className="fa fa-angle-down text-gray-500 pl-3"></span>
                        </button>

                        <select className="p-1 px-2 rounded ml-2 float-right bg-white sm:mb-0 mb-1 "
                                id="offsetValue"
                                defaultValue={inOffset}

                            // onChange={() => handleAppointmentSearch()}
                        >
                            <option value={16}>16</option>
                            <option value={32}>32</option>
                            <option value={64}>64</option>
                            <option value={128}>128</option>
                            <option value={256}>256</option>
                        </select>
                    </div>
                </div>
                <div className='flex flex-wrap  justify-end '>
                    <div className=" w-full  mt-5 relative">
                        {showFilter ? (
                            <CommonFilter
                                listName={'partnerPayment'}
                                startDate={startDate}
                                setStartDate={setStartDate}
                                createdAt={createdAt}
                                setCreatedAt={setCreatedAt}
                                paymentMethod={paymentMethod}
                                setPaymentMethod={setPaymentMethod}
                                appRef={appRef}
                                setAppRef={setAppRef}
                                offset={inOffset}
                                sortBy={sortBy}
                                sortOrder={sortOrder}
                                id={id}
                                page={page}
                                setSearchParams={setSearchParams}
                                listUrl={listUrl}
                                secondaryUrl={secondaryUrl}
                                downloadUrl={downloadUrl}
                                ref={ref}
                            />
                        ) : null}
                    </div>
                    {/*{showResults ? (*/}
                    {/*    <div className="card p-5 mb-5 clear-both lg:mr-4 w-full	">*/}
                    {/*        <form>*/}
                    {/*            <div className="flex flex-wrap">*/}

                    {/*                <div className="xl:w-1/3 md:w-1/3 w-full md:pr-5">*/}
                    {/*                    <div className="form-group mb-6 ">*/}
                    {/*                        <label className="formBlock	mb-2 py-2">From </label>*/}

                    {/*                        <input type="date" className="formControl"*/}
                    {/*                               id="start_date"*/}
                    {/*                               name="start_date"*/}

                    {/*                        />*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className="xl:w-1/3 md:w-1/3 w-full md:pr-5">*/}
                    {/*                    <div className="form-group mb-6 ">*/}
                    {/*                        <label className="formBlock	mb-2 py-2">To</label>*/}

                    {/*                        <input type="date" className="formControl"*/}
                    {/*                               id="end_date"*/}
                    {/*                               name="end_date"*/}

                    {/*                        />*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}


                    {/*                <div className=" w-full flex justify-end">*/}
                    {/*                    <button*/}
                    {/*                        // onClick={(e) => handleAppointmentFilter(e)}*/}
                    {/*                        className="p-2 px-6 bg-purple-500 text-white clear-both float-right rounded">*/}
                    {/*                        Apply Filter*/}
                    {/*                    </button>*/}
                    {/*                    <button*/}
                    {/*                        // onClick={(e) => handleResetFilter(e)}*/}
                    {/*                        className="p-2 px-2 text-purple-500 text-white clear-both ml-2 float-right rounded">*/}
                    {/*                        Clear All*/}
                    {/*                    </button>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </form>*/}
                    {/*    </div>*/}
                    {/*) : null}*/}

                    <div className='w-full md:w-1/3 lg:w-1/4 pull-right flex-end '>
                        <div className='card p-4 text-center mb-4'>
                            <h3 className='mb-1 text-lg'>Cash: <span
                                className='mb-2 font-semibold w-72'>QAR {totalCashAppointment ? totalCashAppointment : 0}</span>
                            </h3>
                            <h3 className='mb-1 text-lg'>Online: <span
                                className='mb-2 font-semibold'>QAR {totalPenaltyAppointment ? totalPenaltyAppointment : 0}</span>
                            </h3>
                            <h3 className='mb-1 text-lg'>Penalties: <span
                                className='mb-2 font-semibold'>QAR {totalOnlineAppointment ? totalOnlineAppointment : 0}</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="card clear-both">
                    <div className="lg:overflow-visible overflow-x-auto w-full whitespace-nowrap">
                        <table className="table-auto tr-even:bg-grey-light striped w-full">
                            <thead className="thead-dark border-b">
                            <tr className="text-gray-600">
                                <th className="px-4 py-3">
                                    <div className="flex items-center">
                                        <button className="font-normal"
                                                onClick={(e) => handleSortOrder(sortOrder, 'first_name')}
                                        > Appointment Id
                                            {(sortBy === 'first_name') ?
                                                (sortOrder === 'desc') ?
                                                    (<span className="fa fa-arrow-up"/>)
                                                    : (<span className="fa fa-arrow-down"/>)
                                                : ''}
                                        </button>
                                    </div>
                                </th>
                                <th className="px-4 py-3">
                                    <div className="flex items-center">
                                        <button className="font-normal"
                                                onClick={(e) => handleSortOrder(sortOrder, 'email')}
                                        >Payment Mode

                                            {(sortBy === 'email') ?
                                                (sortOrder === 'desc') ?
                                                    (<span className="fa fa-arrow-up"/>)
                                                    : (<span className="fa fa-arrow-down"/>)
                                                : ''}
                                        </button>
                                    </div>
                                </th>
                                <th className="px-4 py-3">
                                    <div className="flex items-center">
                                        <button className="font-normal">Amount</button>
                                    </div>
                                </th>

                                <th className="px-4 py-3">
                                    <div className="flex items-center font-normal">
                                        Penalties
                                    </div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {partners?.length > 0 ? (
                                partners.map((partner, index) => {
                                    return (
                                        <tr
                                            className="border-b text-gray-500"
                                            data-index={index}
                                            key={index}
                                        >
                                            <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">
                                                {partner.appointment_no}
                                            </td>
                                            <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">
                                                {partner.payment_mode}
                                            </td>
                                            <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">
                                                {partner.partner_commission}
                                            </td>
                                            <td className="p-4 border-r dark:text-gray-300 dark:border-gray-500">
                                                {partner?.penaltyLogs?.penalty_amount ? partner?.penaltyLogs?.penalty_amount : 0}
                                            </td>

                                        </tr>
                                    );
                                })
                            ) : (
                                <tr className="  border-t-0 border-b dark:border-gray-500 ">
                                    <td className=" p-4 border-r dark:text-gray-300 dark:border-gray-500 text-center"
                                        colSpan="12">
                                        {(partners !== undefined) ? ' No Payment History Found' : ''}
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <Pagination
                            page={page}
                            pages={pages}
                            changePage={setPage}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PartnerPayment