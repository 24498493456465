import {Route, Navigate, Outlet} from "react-router-dom";
import {PartnerDashboard} from "../pages/partnerdashboard/dashboard";

import React, {lazy} from "react";
import {authRole, destroyTokenDetails, authToken} from "../helpers/helpers";

import {PartnerLayout} from "../components/partnerLayout";
import CalendarView from "../pages/partnerdashboard/appointment/calendarView";
// import Archive from "../pages/partnerdashboard/appointment/archive";
// import {Layout} from "../components/layout";

const PartnerAppointmentView = lazy(() => import("../pages/partnerdashboard/appointment/appointmentView"))
const PartnerAppointment = lazy(() => import("../pages/partnerdashboard/appointment/appointment"))

const PartnerAppointmentCurrency = lazy(() => import("../pages/partnerdashboard/appointment/appointmentCurrency"))

const Cleaner = lazy(() => import("../pages/cleaner/cleaner"))
const CleanerAdd = lazy(() => import("../pages/cleaner/cleanerAdd"))
const CleanerView = lazy(() => import("../pages/cleanerreview/CleanerView"))
const CleanerReview = lazy(() => import("../pages/cleanerreview/CleanerReview"))
const Driver = lazy(() => import("../pages/driver/driver"))
const DriverAdd = lazy(() => import("../pages/driver/driverAdd"))
const ProsView = lazy(() => import("../pages/cleaner/prosView"))
const DriverView = lazy(() => import("../pages/driver/driverView"))
const CleanerDriver = lazy(() => import("../pages/cleanerDriver/cleanerDriver"))
const CleanerDriverAdd = lazy(() => import("../pages/cleanerDriver/cleanerDriverAdd"))

const AttendanceList = lazy(() => import("../pages/attendanceList/attendanceList"))
const AttendanceView = lazy(() => import("../pages/attendanceList/attendanceView"))

const PartnerLogin = lazy(() => import("../components/partnerLogin"))
const PartnerForgotPassword = lazy(() => import("../components/partnerForgotPassword"))
const PartnerResetPassword = lazy(() => import("../components/partnerResetPassword"))

const PartnerProfile = lazy(() => import("../pages/profile/partnerProfile"))
const ForBiddenPage = lazy(() => import("../pages/error/403"))
const PenaltyLogs = lazy(() => import("../pages/partnerdashboard/penaltyLogs/penaltyLogs"))

const PageNotFound = lazy(() => import("../pages/error/404"))
const ThankYou = lazy(() => import("../pages/error/thank"))
const PenaltyDashboard = lazy(() => import("../pages/partnerdashboard/penaltyDashboard/penaltyDashboard"))
const Notification = lazy(() => import("../pages/notification/notification"))

const ProtectedPartnerRoute = ({redirectPath = '/partner/login'}) => {

    if (!authRole()) {
        destroyTokenDetails()
        return <Navigate to={redirectPath} replace/>;
    }
    if (!authToken() && (authRole() !== 'partner')) {
        destroyTokenDetails()
        return <Navigate to={redirectPath} replace/>;
    }

    return <Outlet/>;
};

const RedirectPartnerRoute = ({redirectPath = '/partner/dashboard'}) => {
    if (authRole() === 'partner') {
        console.log("partner hai ye")
    }
    if (authToken()) {
        return <Navigate to={redirectPath} replace/>;
    }

    return <Outlet/>;
};

const PartnerRoutes = (
    <>
        <Route element={<ProtectedPartnerRoute/>}>
            <Route path="/" element={<PartnerLayout/>}>
                <Route path="partner/dashboard" element={<PartnerDashboard/>} exact/>
                <Route path="/partner/profile" element={<PartnerProfile/>} exact/>

                <Route path="/partner/notification" element={<Notification/>} exact/>

                <Route path="/partner/cleaner/list" element={<Cleaner/>} exact/>
                <Route path="/partner/cleaner/add" element={<CleanerAdd/>}/>
                <Route path="/partner/cleaner/edit/:id" element={<CleanerAdd/>}/>

                <Route path="/partner/cleaner/view/:id" element={<ProsView/>}/>
                <Route path="/partner/driver/view/:id" element={<DriverView/>}/>

                <Route path="/partner/driver/list" element={<Driver/>} exact/>
                <Route path="/partner/driver/add" element={<DriverAdd/>}/>
                <Route path="/partner/driver/edit/:id" element={<DriverAdd/>}/>

                <Route path="/partner/penalty-logs/list" element={<PenaltyLogs/>} exact/>

                <Route path="/partner/cleaner/driver/list" element={<CleanerDriver/>} exact/>
                <Route path="/partner/cleaner/driver/add" element={<CleanerDriverAdd/>}/>
                <Route path="/partner/cleaner/driver/edit/:id" element={<CleanerDriverAdd/>}/>

                <Route path="/partner/cleaner/review" element={<CleanerReview/>} exact/>
                <Route path="/partner/cleaner/review/:id" element={<CleanerView/>} exact/>

                <Route path="/partner/pro/attendance/list" element={<AttendanceList/>} exact/>
                <Route path="/partner/pro/attendance/view/:id" element={<AttendanceView/>} exact/>
                <Route path="/partner/penalty-dashboard/" element={<PenaltyDashboard/>} exact/>
                <Route path="/partner/appointment/calendar" element={<CalendarView/>} exact/>
                <Route path="/partner/appointment/:id" element={<PartnerAppointment/>} exact/>
                <Route path="/partner/appointment" element={<PartnerAppointment/>} exact/>
                <Route path="/partner/appointment/view/:id" element={<PartnerAppointmentView/>} exact/>
                <Route path="/partner/appointment/currency" element={<PartnerAppointmentCurrency/>} exact/>
                {/* <Route path="/partner/appointment/list" element={<PartnerAppointment/>} exact/> */}


                {/*<Route path="/partner/role/list" element={<Role/>} exact/>*/}
                {/*<Route path="/partner/role/add" element={<RoleAdd/>}/>*/}
                {/*<Route path="/partner/role/edit/:id" element={<RoleAdd/>}/>*/}

            </Route>
        </Route>

        <Route element={<RedirectPartnerRoute/>}>
            <Route path="/partner/" element={<PartnerLogin/>} exact/>
            <Route path="/partner/login" element={<PartnerLogin/>} exact/>
            <Route path="/partner/forgot-password" element={<PartnerForgotPassword/>} exact/>
            <Route path="/partner/reset/password/:token/:email/:role" element={<PartnerResetPassword/>} exact/>

        </Route>

        <Route path="/*" element={<PageNotFound/>} exact/>
        <Route path="/forbidden" element={<ForBiddenPage/>} exact/>

        <Route path="/password/success" element={<ThankYou/>} exact/>

    </>
);


export default PartnerRoutes;