import React from 'react';
import {Link} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useEffect} from "react";
import {homeDashboardAction} from "../../actions/userActions";
import {useDispatch, useSelector} from "react-redux";
import {LoadingIndicator} from "../../components/loading-indicator";
import Charts from "./Charts";
import MyCalendar from "./MyCalendar";
// import DashboardPartials from "./dashboardPartials";
import {isAdmin} from "../../helpers/helpers";
import {requestForToken} from '../../firebase';
import {ModifiedToast} from "../../components/toaster";

export function PartnerDashboard() {
    let baseUrl = process.env.REACT_APP_LOCAL_API_URL
    const listUrl = baseUrl + "/partner/dashboard/"
    const dispatch = useDispatch()
    const getAllHomeState = useSelector(state => state.userLoginReducer)
    const {
        loading, appointmentCount, dashboardData, chartData
    } = getAllHomeState

    useEffect(() => {

        requestForToken()
            .then((res) => {
                let data = {token: (res) ? res : null}
                dispatch(homeDashboardAction(listUrl, data, true))
            })
            .catch((err) => console.log('failed: ', err));
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listUrl])

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Right Hands | Partner Dashboard</title>
                </Helmet>
            </HelmetProvider>
            {loading ? <LoadingIndicator/> : ""}
            <ModifiedToast/>
            <div>
                <div className="w-100  py-6 md:px-10 px-3  ">
                    <div className="flex lg:flex-nowrap flex-wrap relative  params-inheritance-demo">
                        <div
                            className=' w-full lg:w-1/2 dashboardCard PartnerDCard PartnerDashboard flex flex-wrap lg:mr-6 mr-0 mb-6 lg:mb-0'>
                            <div className='w-full lg:w-full xl:w-1/2 md:w-1/2 xl:pr-6 pr-0 pb-6 lg:pr-0 '>
                                <Link to={"/partner/appointment"}
                                      className="text-left relative block shadow-md bg-purple-600 rounded-lg min-h-175  ">
                                    <div className="flex justify-between w-full"
                                    >
                                        <div className="div el1">
                                            <h3 className="text-md text-white leading-none p-4 ">
                                                Appointments
                                            </h3>
                                        </div>
                                        <div
                                            className="absolute right-0 text-purple-500 text-7xl text-center mr-5 mt-12 ">
                                            <span className="fa fa-calendar "/>
                                        </div>
                                    </div>
                                    <h2 className="text-xl font-bold  leading-none mb-2 text-white 	p-4 pt-0 ">
                                        {appointmentCount ? appointmentCount : 0}
                                    </h2>
                                    <button
                                        className='bg-purple-700 absolute bottom-0 w-full p-1 text-white rounded-b-lg'>More
                                        Info <span
                                            className="fa fa-angle-right "/></button>
                                </Link>
                            </div>
                            <div className='w-full lg:w-full xl:w-1/2 md:w-1/2 pb-6 lg:pr-0 '>
                                <Link to={"/partner/appointment"} state={{appointmentStatus: 3}}
                                      className="text-left relative block shadow-md bg-green-600 rounded-lg  min-h-175 ">
                                    <div className="flex justify-between w-full"
                                    >
                                        <div className="div el1">
                                            <h3 className="text-md text-white leading-none p-4 ">
                                                Utilization
                                            </h3>
                                        </div>
                                        <div
                                            className="absolute right-0 text-green-500 text-7xl text-center mr-5 mt-12 ">
                                            <span className="fa fa-bar-chart "/>
                                        </div>
                                    </div>
                                    <h2 className="text-xl font-bold  leading-none mb-2 text-white 	p-4 pt-0 ">
                                        {(chartData?.utilization) ? chartData?.utilization + ' %' : 0}
                                    </h2>
                                    <button className='bg-green-700 w-full p-1 text-white rounded-b-lg'>More Info <span
                                        className="fa fa-angle-right "/></button>
                                </Link>
                            </div>
                            {/* <div className='w-full lg:w-1/2 md:w-1/2 lg:pr-6 pb-4 lg:pr-6 pb-4 md:pb-0'>
                                <Link to={"/appointment/list"}
                                      className="text-left relative block shadow-md bg-yellow-600 rounded-lg  ">
                                    <div className="flex justify-between w-full"
                                    >
                                        <div className="div el1">
                                            <h3 className="text-md text-white leading-none p-4 ">
                                                Customer Rating
                                            </h3>
                                        </div>
                                        <div
                                            className="absolute right-0 text-yellow-500 text-7xl text-center mr-5 mt-9 ">
                                            <span className="fa fa-star "/>
                                        </div>
                                    </div>
                                    <h2 className="text-xl font-bold  leading-none mb-2 text-white 	p-4 pt-0 ">
                                        4.5
                                    </h2>
                                    <button className='bg-yellow-700 w-full p-1 text-white rounded-b-lg'>More Info <span
                                        className="fa fa-angle-right "/></button>
                                </Link>
                            </div> */}
                            <div className='w-full lg:w-full xl:w-1/2 md:w-1/2 xl:pr-6 pb-6   '>
                                <Link to={"/partner/appointment/currency"}
                                      className="text-left relative block shadow-md bg-red-600 rounded-lg min-h-175  ">
                                    <div className="flex justify-between w-full"
                                    >
                                        <div className="div el1">
                                            <h3 className="text-md text-white leading-none p-4 ">
                                                Revenue This Month
                                            </h3>
                                        </div>
                                        <div
                                            className="absolute right-0 text-red-500 text-7xl text-center mr-5 mt-12 ">
                                            <span className="fa fa-money "/>
                                        </div>
                                    </div>
                                    <h2 className="text-xl font-bold  leading-none mb-2 text-white 	p-4 pt-0 ">
                                        {dashboardData?.totalRevenue?.total}
                                    </h2>
                                    <button className='bg-red-700 w-full p-1 text-white rounded-b-lg'>More Info <span
                                        className="fa fa-angle-right "/></button>
                                </Link>
                            </div>

                        </div>
                        <div className=' w-full lg:w-1/2 dashboardCard '>
                            <MyCalendar/>

                        </div>

                    </div>
                    {isAdmin() && (
                        <>
                            <Charts
                                chartData={chartData ? chartData : ''}
                            />
                            {/* <DashboardPartials
                                dashboardData={dashboardData ? dashboardData : ''}
                            /> */}
                        </>)
                    }
                </div>
            </div>
        </div>
    );
}