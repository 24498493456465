export const cleanerDriverListReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_CLEANER_DRIVER_REQUEST':
            return {
                loading: true,
            }
        case'GET_CLEANER_DRIVER_SUCCESS':
            return {
                ...state,
                cleanerDrivers: action.payload.data,
                loading: false,
                totalCount: action.payload.totalCount,
                cleaners: action.payload.cleaners
            }
        case'GET_CLEANER_DRIVER_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        case'EDIT_CLEANER_DRIVER_SUCCESS':
            return {
                loading: false,
                cleanerDriver: action.payload,
            }
        case'EDIT_MAP_SUCCESS':
            return {
                cleanerDriver: action.payload,
                loading: false
            }
        case'GET_MAP_REQUEST':
            return {
                loading: true,
            }
        case'CREATE_CLEANER_DRIVER_SUCCESS':
            return {
                cleanerDriver: action.payload,
                loading: false
            }
        case'DELETE_CLEANER_DRIVER_SUCCESS':
            return {
                loading: false
            }
        case'DELETE_CLEANER_DRIVER_FAILED':
            return {
                loading: false,
                error: action.payload
            }

        case'UPDATE_CLEANER_DRIVER_SUCCESS':
            return {
                loading: false
            }
        case'UPDATE_CLEANER_DRIVER_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        default:
            return {
                ...state,
                loading: false
            }
    }
}