export const bannerListReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_BANNER_REQUEST':
            return {
                loading: true,
            }
        case'GET_BANNER_SUCCESS':
            return {
                banners: action.payload,
                loading: false
            }
        case'EDIT_BANNER_SUCCESS':
            return {
                banner: action.payload,
                loading: false
            }
        case'CREATE_BANNER_SUCCESS':
            return {
                banner: action.payload,
                loading: false
            }
        case'GET_BANNER_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        case'DELETE_BANNER_SUCCESS':
            return {
                loading: false
            }
        case'DELETE_BANNER_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        case'UPDATE_BANNER_SUCCESS':
            return {
                loading: false
            }
        case'UPDATE_BANNER_FAILED':
            return {
                loading: false,
                error: action.payload
            }

        default:
            return {
                ...state,
                loading: false
            }


    }
}