export const subServiceListReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_SUB_SERVICE_REQUEST':
            return {
                loading: true,
            }
        case'GET_SUB_SERVICE_SUCCESS':
            return {
                ...state,
                subServices: action.payload.data,
                loading: false,
                totalCount: action.payload.totalCount
            }
        case'EDIT_SUB_SERVICE_SUCCESS':
            return {
                ...state,
                subService: action.payload,
                loading: false
            }
        case'ASSIGN_PRICE':
            return {
                loading: false
            }
        case'CREATE_SUB_SERVICE_SUCCESS':
            return {
                ...state,
                subService: action.payload,
                loading: false
            }
        case'GET_SUB_SERVICE_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case'DELETE_SUB_SERVICE_SUCCESS':
            return {
                ...state,
                loading: false
            }
        case'DELETE_SUB_SERVICE_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case'UPDATE_SUB_SERVICE_SUCCESS':
            return {
                ...state,
                loading: false
            }
        case'UPDATE_SUB_SERVICE_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        default:
            return {
                ...state,
                loading: false
            }


    }
}