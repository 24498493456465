import axios from 'axios'
import toast from "react-hot-toast";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {authHeader, commonCatchBlock} from "../helpers/helpers";
import jQuery from "jquery";


const MySwal = withReactContent(Swal)

export const driverListsAction = (baseUrl, partner = false) => dispatch => {

    dispatch({type: 'GET_DRIVERS_REQUEST'})

    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {

            dispatch({
                type: 'GET_DRIVERS_SUCCESS',
                payload: {data: res.data.list, totalCount: res.data.totalPages, cleaners: res.data.cleaners}
            })
        })
        .catch((err) => {
                dispatch({type: 'GET_DRIVERS_FAILED', payload: err})
                commonCatchBlock(err)
            }
        )
}

export const driverDeleteAction = (baseUrl, listUrl, partner = false) => dispatch => {

    MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete!'
    }).then((result) => {
            if (result.isConfirmed) {
                dispatch({type: 'GET_DRIVERS_REQUEST'})
                axios.delete(baseUrl, {
                    headers: authHeader(baseUrl),
                })
                    .then((res) => {
                        dispatch({type: 'DELETE_DRIVERS_SUCCESS'})
                        toast.success(res.data.message, {
                            duration: 2000,
                            position: 'top-right',
                        })
                        dispatch({type: 'GET_DRIVERS_REQUEST'})
                        dispatch(driverListsAction(listUrl))
                    })
                    .catch((err) => {
                            dispatch({type: 'DELETE_DRIVERS_FAILED'})
                            commonCatchBlock(err)
                        }
                    )
            }

        }
    )
}

export const driverStatusAction = (baseUrl, listUrl, partner = false) => dispatch => {

    dispatch({type: 'GET_DRIVERS_REQUEST'})
    axios.put(baseUrl, {}, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'UPDATE_DRIVERS_SUCCESS'})
            if (res.data.user.isActive) {
                toast.success(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })
            } else {
                toast.error(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })
            }

            dispatch({type: 'GET_DRIVERS_REQUEST'})
            dispatch(driverListsAction(listUrl))
        })
        .catch((err) => {
                dispatch({type: 'UPDATE_DRIVERS_FAILED'})
                commonCatchBlock(err)
            }
        )

}

export const driverImageRemoveAction = (baseUrl, listUrl, partner = false) => dispatch => {

    dispatch({type: 'GET_DRIVERS_REQUEST'})
    axios.delete(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'UPDATE_DRIVERS_SUCCESS'})

            toast.success(res.data.message, {
                duration: 2000,
                position: 'top-right',
            })
            dispatch({type: 'EDIT_DRIVERS_SUCCESS', payload: res.data.driver})

        })
        .catch((err) => {
                dispatch({type: 'UPDATE_DRIVERS_FAILED'})
                commonCatchBlock(err)
                dispatch(driverGetEditAction(listUrl));
            }
        )
}

export const driverGetEditAction = (baseUrl, partner = false) => dispatch => {

    dispatch({type: 'GET_DRIVERS_REQUEST'})

    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'EDIT_DRIVERS_SUCCESS', payload: res.data.user})
        })
        .catch((err) => {
                dispatch({type: 'GET_DRIVERS_FAILED'})
                commonCatchBlock(err)
            }
        )

}

export const driverCreateEditAction = (baseUrl, method, formData, _form, getUrl, getId, partner = false, pd = false) => dispatch => {

    dispatch({type: 'GET_DRIVERS_REQUEST'})
    let frm = jQuery(_form)

    let currentToken = localStorage.getItem('currentToken') ? JSON.parse(localStorage.getItem('currentToken')) : null
    axios({
        url: baseUrl, data: formData, method: method,
        headers: {
            "x-auth-token": currentToken,
            'Content-Type': 'multipart/form-data'
        },
    })
        .then((response) => {

            toast.success(response.data.message, {
                duration: 2000,
                position: 'top-right',
            });
            setTimeout(() => {
                if (pd) {
                    window.location.href = "/partners/driver/list"
                } else {
                    window.location.href = (partner) ? "/partners/driver/list" : "/driver/list"
                }


                dispatch({type: 'CREATE_DRIVERS_SUCCESS', payload: response.data.driver})
            }, 1000)
        })
        .catch((err) => {
                dispatch({type: 'GET_DRIVERS_FAILED'})
                commonCatchBlock(err, frm)
                if (getId) {
                    dispatch(driverGetEditAction(getUrl));
                }
            }
        )

}

export const mapDriverCleanerAction = (baseUrl, method, formData, listUrl, partner = false) => dispatch => {

    dispatch({type: 'GET_MAP_REQUEST'})
    axios({
        url: baseUrl, data: {cleaners_id: formData}, method: method,
        headers: authHeader(baseUrl),
    })
        .then((response) => {
            dispatch({type: 'EDIT_MAP_SUCCESS'})
            toast.success(response.data.message, {
                duration: 2000,
                position: 'top-right',
            });
            dispatch({type: 'GET_DRIVERS_REQUEST'})
            setTimeout(() => {
                dispatch(driverListsAction(listUrl))
            }, 1000)
        })
        .catch((err) => {
                dispatch({type: 'GET_DRIVERS_FAILED'})
                commonCatchBlock(err)
                dispatch({type: 'GET_DRIVERS_REQUEST'})
                setTimeout(() => {
                    dispatch(driverListsAction(listUrl))
                }, 1000)
            }
        )

}