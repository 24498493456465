export const applyJobListReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_APPLY_JOB_REQUEST':
            return {
                loading: true,
            }
        case'GET_APPLY_JOB_SUCCESS':
            return {
                ...state,
                applyJobs: action.payload.data,
                loading: false,
                totalCount: action.payload.totalCount
            }
        case'GET_APPLY_JOB_FAILED':
            return {
                ...state,
                loading: false,
            }

        case'EDIT_APPLY_JOB_SUCCESS':
            return {
                ...state,
                applyJob: action.payload,
                loading: false
            }
        case'CREATE_APPLY_JOB_SUCCESS':
            return {
                ...state,
                applyJob: action.payload,
                loading: false
            }
        case'DELETE_APPLY_JOB_SUCCESS':
            return {
                ...state,
                loading: false
            }
        case'DELETE_APPLY_JOB_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case'UPDATE_APPLY_JOB_SUCCESS':
            return {
                ...state,
                loading: false
            }
        case'UPDATE_APPLY_JOB_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return {
                ...state,
                loading: false,
            }
    }
}