import * as React from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {ModifiedToast} from "../../../components/toaster";
import moment from 'moment'
import {LoadingIndicator} from "../../../components/loading-indicator";
import {Calendar,  momentLocalizer} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
// import format from "date-fns/format";
// import getDay from "date-fns/getDay";
// import parse from "date-fns/parse";
// import startOfWeek from "date-fns/startOfWeek";
import {useEffect, useState} from "react";
import {appointmentListsAction} from "../../../actions/appointmentActions";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate} from "react-router-dom";

// const locales = {
//     "en-US": require("date-fns/locale/en-US"),
// };
// const localizer = dateFnsLocalizer({
//     format,
//     parse,
//     startOfWeek,
//     getDay,
//     locales,
// });

const localizer = momentLocalizer(moment);

const minTime = new Date();
minTime.setHours(8, 0, 0);
const maxTime = new Date();
maxTime.setHours(22, 0, 0);

const appStatus = {
    0: 'Pending', 1: 'Waiting', 2: 'Completed', 3: 'Cancelled', 4: 'On Going'
}


 function CalendarView() {
    const navigate = useNavigate()
    // const [newEvent, setNewEvent] = useState({title: "", start: "", end: ""});
    const dispatch = useDispatch()
    const baseUrl = process.env.REACT_APP_LOCAL_API_URL
    const listUrl = baseUrl + "/appointment/admin/calendar"
    const getAllAppointmentsState = useSelector(state => state.appointmentListReducer)
    const {loading, appointments} = getAllAppointmentsState
    let dynamicEvents = []
    useEffect(() => {
        dispatch(appointmentListsAction(listUrl))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [allEvents, setAllEvents] = useState([]);
    useEffect(() => {
        if (appointments?.length > 0) {
            for (let j = 0; j < appointments?.length; j++) {
                let startTime
                if (appointments[j]?.appointment_date_start) {
                    let appTime = (appointments[j]?.appointment_date_start).replace("T", " ")
                    let timeSplit = appTime.split('.')

                    startTime = moment(timeSplit[0]).format("YYYY ,MM ,DD, HH ,mm, 0")

                } else {
                    startTime = moment(appointments[j]?.appointment_date_start).format("YYYY ,MM ,DD, HH ,mm, 0")
                }

                let da1 = startTime.split(',').map(function (d) {
                    return parseInt(d);
                });

                startTime = new Date(da1[0], da1[1] - 1, da1[2], da1[3], da1[4], da1[5])

                if (startTime === 'Invalid Date')
                    startTime = new Date(moment(appointments[j]?.appointment_date_start).format("YYYY ,MM ,DD"))
                let endTime
                if (appointments[j]?.appointment_date_end) {
                    let appTime = (appointments[j]?.appointment_date_end).replace("T", " ")
                    let timeSplit = appTime.split('.')
                    endTime = moment(timeSplit[0]).format("YYYY ,MM ,DD, HH ,mm, 0")
                } else
                    endTime = moment(appointments[j]?.appointment_date_end).format("YYYY ,MM ,DD, HH ,mm, 0")
                let da2 = endTime.split(',').map(function (d) {
                    return parseInt(d);
                });
                endTime = new Date(da2[0], da2[1] - 1, da2[2], da2[3], da2[4], da2[5])
                if (endTime === 'Invalid Date')
                    endTime = new Date(moment(appointments[j]?.appointment_date_end).format("YYYY ,MM ,DD"))

                for (let k = 0; k < appointments[j]?.CleanerDetails?.length; k++) {
                    dynamicEvents.push({
                        title: appointments[j]?.CleanerDetails[k]?.fullName + ' (' + appStatus[appointments[j].appointment_status ? appointments[j].appointment_status : 0] + ')',
                        start: startTime,
                        end: endTime,
                        color: appointments[j]?.CleanerDetails[k]?.theme_color,
                        appointment_id: appointments[j]._id,
                    })
                }
            }
        }
        setAllEvents(dynamicEvents)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointments])


    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>RightHand | Calendar view</title>
                </Helmet>
            </HelmetProvider>
            {loading ? <LoadingIndicator/> : ""}
            <ModifiedToast/>
            <div className="w-100 py-6 md:px-10 px-3 ">
                               <div className="flex justify-between items-center mb-6 flex-wrap lg:flex-nowrap">
                    <div className="xl:w-1/3 lg:w-1/2 md:w-1/2 lg:mb-0 mb-2 w-full">
                        <h2 className="text-2xl mb-3 font-normal  ">
                            Calendar
                        </h2>
                    </div>
                    <div className="flex md:w-auto justify-end w-full">

                    </div>
                </div>
                <div className="card cardCalendar">
                <Calendar localizer={localizer} events={allEvents} startAccessor="start" endAccessor="end"
                          style={{height: 650, margin: "50px"}}
                          min={minTime}
                          max={maxTime}
                          eventPropGetter={(event, start, end, isSelected) => ({
                              event,
                              start,
                              end,
                              isSelected,
                              style: {backgroundColor: event.color}
                          })}
                          onSelectEvent={(e) => (navigate("/partner/appointment/view/" + e.appointment_id))}
                />
            </div>  </div>
        </div>
    );
};

export default CalendarView