export const faqQuestionListReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_FAQ_QUESTION_REQUEST':
            return {
                loading: true,
            }
        case'GET_FAQ_QUESTION_SUCCESS':
            return {
                ...state,
                faqQuestions: action.payload.data,
                loading: false,
                totalCount: action.payload.totalCount
            }
        case'EDIT_FAQ_QUESTION_SUCCESS':
            return {
                faqQuestion: action.payload,
                loading: false,

            }
        case'CREATE_FAQ_QUESTION_SUCCESS':
            return {
                faqQuestion: action.payload,
                loading: false
            }
        case'GET_FAQ_QUESTION_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        case'DELETE_FAQ_QUESTION_SUCCESS':
            return {
                loading: false
            }
        case'DELETE_FAQ_QUESTION_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        case'UPDATE_FAQ_QUESTION_SUCCESS':
            return {
                loading: false
            }
        case'UPDATE_FAQ_QUESTION_FAILED':
            return {
                loading: false,
                error: action.payload
            }

        default:
            return {
                ...state,
                loading: false
            }


    }
}