import {Route} from "react-router-dom";
import React, {lazy} from "react";
import {Layout} from "../components/layout";
// import {Frequency} from "../pages/frequency/frequency";
// import {FrequencyAdd} from "../pages/frequency/frequencyAdd";
// import {Service} from "../pages/service/service";
// import {ServiceAdd} from "../pages/service/serviceAdd";
// import {Discount} from "../pages/discount/discount";
// import {DiscountAdd} from "../pages/discount/discountAdd";
// import {SubServiceAdd} from "../pages/subService/subServiceAdd";
// import {SubService} from "../pages/subService/subService";
// import {PackageServiceAdd} from "../pages/servicePackage/servicePackageAdd";
// import {PackageService} from "../pages/servicePackage/servicePackage";
// import {BannerAdd} from "../pages/banner/bannerAdd";
// import {Banner} from "../pages/banner/banner";
// import {TemplateAdd} from "../pages/template/templateAdd";
// import {Template} from "../pages/template/template";
// import {ServiceType} from "../pages/serviceType/serviceType";
// import {ServiceTypeAdd} from "../pages/serviceType/serviceTypeAdd";
// import {Broadcast} from "../pages/broadcast/broadcast";
// import {StaticContent} from "../pages/static/staticPage";
// import {BroadcastAdd} from "../pages/broadcast/broadcastAdd";
// import {StaticContentAdd} from "../pages/static/staticPageAdd";
// import {FaqCategory} from "../pages/faq/faqCategory";
// import {FaqCategoryAdd} from "../pages/faq/faqCategoryAdd";
// import {FaqQuestion} from "../pages/faq/faqQuestions";
// import {FaqQuestionAdd} from "../pages/faq/faqQuestionsAdd";
// import {Subscription} from "../pages/subscription/subscription";
// import {SubscriptionAdd} from "../pages/subscription/subscriptionAdd";
// import {HelpCategory} from "../pages/help/helpCategory";
// import {HelpCategoryAdd} from "../pages/help/helpCategoryAdd";
// import {HelpQuestion} from "../pages/help/helpQuestions";
// import {HelpQuestionAdd} from "../pages/help/helpQuestionsAdd";
// import {RushSlot} from "../pages/rushSlot/rushSlot";
// import {RushSlotAdd} from "../pages/rushSlot/rushSlotAdd";

// import {Holiday} from "../pages/holiday/holiday";
// import {HolidayAdd} from "../pages/holiday/holidayAdd";
// import {Country} from "../pages/country/country";
// import {CountryAdd} from "../pages/country/countryAdd";
// import {NeedHelp} from "../pages/needHelp/needHelp";
// import {NeedHelpAdd} from "../pages/needHelp/needHelpAdd";
// import {JobService} from "../pages/jobService/jobService";
// import {JobServiceAdd} from "../pages/jobService/jobServiceAdd";
import Filter from "../components/Filter";
import RequireAuth from "../components/RequireAuth";
import {canAddPolicy, canEditPolicy, canViewPolicy, isAdmin} from "../helpers/helpers";
// import AttendanceList from "../pages/partnerdashboard/attendanceList/attendanceList";

const Service = lazy(() => import("../pages/service/service"))
const ServiceAdd = lazy(() => import("../pages/service/serviceAdd"))
const ServiceView = lazy(() => import("../pages/service/serviceView"))

const Template = lazy(() => import("../pages/template/templateAdd"))
const TemplateAdd = lazy(() => import("../pages/template/template"))

const ServiceType = lazy(() => import("../pages/serviceType/serviceType"))
const ServiceTypeAdd = lazy(() => import("../pages/serviceType/serviceTypeAdd"))

const Broadcast = lazy(() => import("../pages/broadcast/broadcast"))
const BroadcastAdd = lazy(() => import("../pages/broadcast/broadcastAdd"))

const StaticContent = lazy(() => import("../pages/static/staticPage"))
const StaticContentAdd = lazy(() => import("../pages/static/staticPageAdd"))

const FaqCategory = lazy(() => import("../pages/faq/faqCategory"))
const FaqCategoryAdd = lazy(() => import("../pages/faq/faqCategoryAdd"))

const FaqQuestion = lazy(() => import("../pages/faq/faqQuestions"))
const FaqQuestionAdd = lazy(() => import("../pages/faq/faqQuestionsAdd"))

const Subscription = lazy(() => import("../pages/faq/faqQuestions"))
const SubscriptionAdd = lazy(() => import("../pages/faq/faqQuestionsAdd"))

const HelpCategory = lazy(() => import("../pages/help/helpCategory"))
const HelpCategoryAdd = lazy(() => import("../pages/help/helpCategoryAdd"))

const HelpQuestion = lazy(() => import("../pages/help/helpQuestions"))
const HelpQuestionAdd = lazy(() => import("../pages/help/helpQuestionsAdd"))

const RushSlot = lazy(() => import("../pages/rushSlot/rushSlot"))
const RushSlotAdd = lazy(() => import("../pages/rushSlot/rushSlotAdd"))

const Holiday = lazy(() => import("../pages/holiday/holiday"))
const HolidayAdd = lazy(() => import("../pages/holiday/holidayAdd"))

const Country = lazy(() => import("../pages/country/country"))
const CountryAdd = lazy(() => import("../pages/country/countryAdd"))

const NeedHelp = lazy(() => import("../pages/needHelp/needHelp"))
const NeedHelpAdd = lazy(() => import("../pages/needHelp/needHelpAdd"))

const Banner = lazy(() => import("../pages/banner/banner"))
const BannerAdd = lazy(() => import("../pages/banner/bannerAdd"))

const JobServiceAdd = lazy(() => import("../pages/jobService/jobServiceAdd"))

const Discount = lazy(() => import("../pages/discount/discount"))
const DiscountAdd = lazy(() => import("../pages/discount/discountAdd"))

const Frequency = lazy(() => import("../pages/frequency/frequency"))
const FrequencyAdd = lazy(() => import("../pages/frequency/frequencyAdd"))

const SubServiceAdd = lazy(() => import("../pages/subService/subServiceAdd"))
const SubService = lazy(() => import("../pages/subService/subService"))
const SubServiceView = lazy(() => import("../pages/subService/subServiceView"))

const PackageServiceAdd = lazy(() => import("../pages/servicePackage/servicePackageAdd"))
const PackageService = lazy(() => import("../pages/servicePackage/servicePackage"))
const PackageServiceView = lazy(() => import("../pages/servicePackage/servicePackageView"))

const PriceMaster = lazy(() => import("../pages/priceMaster/priceMaster"))
const PriceMasterAdd = lazy(() => import("../pages/priceMaster/priceMasterAdd"))

const JobService = lazy(() => import("../pages/jobService/jobService"))


const MasterRoutes = (

    <Route path="/" element={<Layout/>}>

        {/*all list routes*/}
        <Route element={<RequireAuth allowedRoles={(isAdmin() || canViewPolicy(7))}/>}>
            <Route path="/frequency/list" element={<Frequency/>} exact/>
            <Route path="/price/master/list" element={<PriceMaster/>} exact/>
            <Route path="/service/list" element={<Service/>} exact/>
            <Route path="/serviceType/list" element={<ServiceType/>} exact/>

            <Route path="/sub/service/list" element={<SubService/>} exact/>
            <Route path="/package/service/list" element={<PackageService/>} exact/>
            <Route path="/banner/list" element={<Banner/>} exact/>
            <Route path="/template/list" element={<Template/>} exact/>

            <Route path="/content/list" element={<StaticContent/>} exact/>
            <Route path="/faq/category/list" element={<FaqCategory/>} exact/>
            <Route path="/faq/question/list" element={<FaqQuestion/>} exact/>

            <Route path="/help/category/list" element={<HelpCategory/>} exact/>
            <Route path="/rush/slot/list" element={<RushSlot/>} exact/>
            <Route path="/help/question/list" element={<HelpQuestion/>} exact/>
            <Route path="/needHelp/list" element={<NeedHelp/>} exact/>
            <Route path="/holiday/list" element={<Holiday/>} exact/>
            <Route path="/country/list" element={<Country/>} exact/>
            <Route path="/jobService/list" element={<JobService/>} exact/>
            <Route path="/subscription/list" element={<Subscription/>} exact/>
            {/*view routes*/}

            <Route path="/service/view/:id" element={<ServiceView/>}/>
            <Route path="/sub/service/view/:id" element={<SubServiceView/>} exact/>
            <Route path="/package/service/view/:id" element={<PackageServiceView/>} exact/>

        </Route>


        <Route element={<RequireAuth allowedRoles={(isAdmin() || canViewPolicy(12))}/>}>
            <Route path="/broadcast/list" element={<Broadcast/>} exact/>
        </Route>
        <Route element={<RequireAuth allowedRoles={(isAdmin() || canAddPolicy(12))}/>}>
            <Route path="/broadcast/add" element={<BroadcastAdd/>}/>
        </Route>
        <Route element={<RequireAuth allowedRoles={(isAdmin() || canEditPolicy(12))}/>}>
            <Route path="/broadcast/edit/:id" element={<BroadcastAdd/>}/>
        </Route>

        <Route element={<RequireAuth allowedRoles={(isAdmin() || canViewPolicy(8))}/>}>
            <Route path="/discount/list" element={<Discount/>} exact/>
        </Route>
        <Route element={<RequireAuth allowedRoles={(isAdmin() || canAddPolicy(8))}/>}>
            <Route path="/discount/add" element={<DiscountAdd/>}/>
        </Route>
        <Route element={<RequireAuth allowedRoles={(isAdmin() || canEditPolicy(8))}/>}>
            <Route path="/discount/edit/:id" element={<DiscountAdd/>}/>
        </Route>


        {/*all master add routes*/}

        <Route element={<RequireAuth allowedRoles={(isAdmin() || canAddPolicy(7))}/>}>
            <Route path="/country/add" element={<CountryAdd/>}/>
            <Route path="/jobService/add" element={<JobServiceAdd/>}/>
            <Route path="/frequency/add" element={<FrequencyAdd/>}/>
            <Route path="/price/master/add" element={<PriceMasterAdd/>}/>
            <Route path="/service/add" element={<ServiceAdd/>}/>
            <Route path="/serviceType/add" element={<ServiceTypeAdd/>}/>
            <Route path="/subscription/add" element={<SubscriptionAdd/>}/>
            <Route path="/sub/service/add" element={<SubServiceAdd/>} exact/>
            <Route path="/package/service/add" element={<PackageServiceAdd/>} exact/>
            <Route path="/banner/add" element={<BannerAdd/>} exact/>
            <Route path="/template/add" element={<TemplateAdd/>} exact/>

            <Route path="/content/add" element={<StaticContentAdd/>}/>
            <Route path="/faq/category/add" element={<FaqCategoryAdd/>}/>
            <Route path="/faq/question/add" element={<FaqQuestionAdd/>}/>

            <Route path="/help/category/add" element={<HelpCategoryAdd/>}/>
            <Route path="/rush/slot/add" element={<RushSlotAdd/>}/>
            <Route path="/help/question/add" element={<HelpQuestionAdd/>}/>
            <Route path="/needHelp/add" element={<NeedHelpAdd/>}/>
            <Route path="/holiday/add" element={<HolidayAdd/>}/>
        </Route>

        {/*all master edit routes*/}
        <Route element={<RequireAuth allowedRoles={(isAdmin() || canEditPolicy(7))}/>}>
            <Route path="/jobService/edit/:id" element={<JobServiceAdd/>}/>
            <Route path="/country/edit/:id" element={<CountryAdd/>}/>
            <Route path="/frequency/edit/:id" element={<FrequencyAdd/>}/>
            <Route path="/price/master/edit/:id" element={<PriceMasterAdd/>}/>
            <Route path="/service/edit/:id" element={<ServiceAdd/>}/>
            <Route path="/serviceType/edit/:id" element={<ServiceTypeAdd/>}/>

            <Route path="/sub/service/edit/:id" element={<SubServiceAdd/>} exact/>
            <Route path="/package/service/edit/:id" element={<PackageServiceAdd/>} exact/>
            <Route path="/banner/edit/:id" element={<BannerAdd/>} exact/>
            <Route path="/template/edit/:id" element={<TemplateAdd/>} exact/>

            <Route path="/content/edit/:id" element={<StaticContentAdd/>}/>
            <Route path="/faq/category/edit/:id" element={<FaqCategoryAdd/>}/>
            <Route path="/faq/question/edit/:id" element={<FaqQuestionAdd/>}/>

            <Route path="/help/category/edit/:id" element={<HelpCategoryAdd/>}/>
            <Route path="/rush/slot/edit/:id" element={<RushSlotAdd/>}/>
            <Route path="/help/question/edit/:id" element={<HelpQuestionAdd/>}/>
            <Route path="/needHelp/edit/:id" element={<NeedHelpAdd/>}/>
            <Route path="/holiday/edit/:id" element={<HolidayAdd/>}/>

            <Route path="/subscription/edit/:id" element={<SubscriptionAdd/>}/>
        </Route>
        <Route path="/filter" element={<Filter/>}/>
        {/*<Route path="/attendance" element={<AttendanceList/>}/>*/}

    </Route>

);


export default MasterRoutes;