import axios from 'axios'
import toast from "react-hot-toast";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {authHeader, commonCatchBlock} from "../helpers/helpers";
import jQuery from "jquery";


const MySwal = withReactContent(Swal)

export const customerListsAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_CUSTOMER_REQUEST'})

    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'GET_CUSTOMER_SUCCESS', payload: {data: res.data.list, totalCount: res.data.totalPages}})
        })
        .catch((err) => {
                dispatch({type: 'GET_CUSTOMER_FAILED', payload: err})
                commonCatchBlock(err)
            }
        )
}

export const customerDeleteAction = (baseUrl, listUrl, view = false, wallet = false) => dispatch => {

    MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete!'
    }).then((result) => {
            if (result.isConfirmed) {
                dispatch({type: 'GET_CUSTOMER_REQUEST'})
                axios.delete(baseUrl, {
                    headers: authHeader(baseUrl),
                })
                    .then((res) => {
                        dispatch({type: 'DELETE_CUSTOMER_SUCCESS'})
                        toast.success(res.data.message, {
                            duration: 2000,
                            position: 'top-right',
                        })

                        if (wallet) {
                            dispatch(customerGetEditAction(listUrl))
                        } else {
                            if (view) {
                                window.location.href = "/rh/customer/list"
                            } else
                                dispatch(customerListsAction(listUrl))
                        }
                    })
                    .catch((err) => {
                            dispatch({type: 'DELETE_CUSTOMER_FAILED'})
                            dispatch(customerListsAction(listUrl))
                            commonCatchBlock(err)
                        }
                    )
            }

        }
    )
}

export const customerAddressDeleteAction = (baseUrl, dataset, listUrl) => dispatch => {

    MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete!'
    }).then((result) => {
            if (result.isConfirmed) {
                dispatch({type: 'GET_CUSTOMER_REQUEST'})
                axios.put(baseUrl, dataset, {
                    headers: authHeader(baseUrl),
                })
                    .then((res) => {
                        dispatch({type: 'DELETE_CUSTOMER_SUCCESS'})
                        toast.success(res.data.message, {
                            duration: 2000,
                            position: 'top-right',
                        })
                        dispatch({type: 'GET_CUSTOMER_REQUEST'})
                        dispatch(customerGetEditAction(listUrl))
                    })
                    .catch((err) => {
                            dispatch({type: 'DELETE_CUSTOMER_FAILED'})
                            dispatch(customerGetEditAction(listUrl))
                            commonCatchBlock(err)
                        }
                    )
            }

        }
    )
}

export const customerStatusAction = (baseUrl, listUrl) => dispatch => {

    dispatch({type: 'GET_CUSTOMER_REQUEST'})
    axios.put(baseUrl, {}, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'UPDATE_CUSTOMER_SUCCESS'})
            if (res.data.user.isActive) {
                toast.success(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })
            } else {
                toast.error(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })
            }

            dispatch({type: 'GET_CUSTOMER_REQUEST'})
            dispatch(customerListsAction(listUrl))
        })
        .catch((err) => {
                dispatch({type: 'UPDATE_CUSTOMER_FAILED'})
                commonCatchBlock(err)
            }
        )

}

export const customerGetEditAction = (baseUrl) => dispatch => {
    dispatch({type: 'GET_CUSTOMER_REQUEST'})

    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({
                type: 'EDIT_CUSTOMER_SUCCESS', payload: {
                    customer: res.data.customer, wallets: res.data.wallets,
                    totalCredits: res.data.totalCredits,
                    favExist: res.data.favExist,
                    cleaners: res.data.cleanerResult
                }
            })
        })
        .catch((err) => {
                dispatch({type: 'GET_CUSTOMER_FAILED'})
                commonCatchBlock(err)
            }
        )

}

export const customerImageRemoveAction = (baseUrl, listUrl) => dispatch => {

    dispatch({type: 'GET_CUSTOMER_REQUEST'})
    axios.delete(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'UPDATE_CUSTOMER_SUCCESS'})

            toast.success(res.data.message, {
                duration: 2000,
                position: 'top-right',
            })
            dispatch({type: 'EDIT_CUSTOMER_SUCCESS', payload: res.data.customer})

        })
        .catch((err) => {
                dispatch({type: 'UPDATE_CUSTOMER_FAILED'})
                commonCatchBlock(err)
                dispatch(customerGetEditAction(listUrl));
            }
        )
}


export const customerCreateEditAction = (baseUrl, method, formData, _form, getUrl, getId, viaApp = false) => dispatch => {
    let frm = jQuery(_form);

    dispatch({type: 'GET_CUSTOMER_REQUEST'})

    let currentToken = localStorage.getItem('currentToken') ? JSON.parse(localStorage.getItem('currentToken')) : null
    axios({
        url: baseUrl, method: method, data: formData,
        headers: {
            "x-auth-token": currentToken,
            'Content-Type': 'multipart/form-data'
        },
    })
        .then((response) => {

            toast.success(response.data.message, {
                duration: 2000,
                position: 'top-right',
            });
            setTimeout(() => {
                if (viaApp)
                    window.location.href = "/appointment/add"
                else
                    window.location.href = "/customer/list"
                dispatch({type: 'CREATE_CUSTOMER_SUCCESS', payload: response.data.customer})
            }, 1000)
        })
        .catch((err) => {
                dispatch({type: 'GET_CUSTOMER_FAILED'})

                if (getId) {
                    dispatch(customerGetEditAction(getUrl));
                }
                commonCatchBlock(err, frm)
            }
        )

}

export const customerAddressCreateEdit = (baseUrl, method, formData, _form, getUrl, getId) => dispatch => {
    let frm = jQuery(_form);

    dispatch({type: 'GET_CUSTOMER_REQUEST'})

    let currentToken = localStorage.getItem('currentToken') ? JSON.parse(localStorage.getItem('currentToken')) : null
    axios({
        url: baseUrl, data: formData, method: method,
        headers: {
            "x-auth-token": currentToken,
            'Content-Type': 'multipart/form-data'
        },
    })
        .then((response) => {
            dispatch({type: 'UPDATE_CUSTOMER_SUCCESS'})
            toast.success(response.data.message, {
                duration: 2000,
                position: 'top-right',
            });
            dispatch(customerGetEditAction(getUrl))
            setTimeout(() => {
                window.location.href = "/customer/view/" + getId
            }, 1000)
        })
        .catch((err) => {
                dispatch({type: 'GET_CUSTOMER_FAILED'})

                if (getId) {
                    dispatch(customerGetEditAction(getUrl));
                }
                commonCatchBlock(err, frm)


            }
        )

}

export const customerWalletAmountAdd = (baseUrl, method, formData, getUrl, getId) => dispatch => {
    dispatch({type: 'UPDATE_CUSTOMER_REQUEST'})
    axios({
        url: baseUrl, data: formData, method: method,
        headers: authHeader(baseUrl),
    })
        .then((response) => {
            dispatch({type: 'UPDATE_CUSTOMER_SUCCESS'})
            toast.success(response.data.message, {
                duration: 2000,
                position: 'top-right',
            });
            dispatch(customerGetEditAction(getUrl))
            setTimeout(() => {
                window.location.href = "/customer/wallet/list/" + getId
            }, 1500)
        })
        .catch((err) => {
                dispatch({type: 'GET_CUSTOMER_FAILED'})
                dispatch(customerGetEditAction(getUrl));
                commonCatchBlock(err)
            }
        )

}

export const customerWalletDistribution = (baseUrl, method, formData, toRedirect, getUrl, getId) => dispatch => {
    dispatch({type: 'UPDATE_CUSTOMER_REQUEST'})
    let frm = jQuery(formData);
    axios({
        url: baseUrl, data: frm.serialize(), method: method,
        headers: authHeader(baseUrl),
    })
        .then((response) => {
            dispatch({type: 'UPDATE_CUSTOMER_SUCCESS'})
            toast.success(response.data.message, {
                duration: 2000,
                position: 'top-right',
            });
            setTimeout(() => {
                window.location.href = toRedirect
            }, 100)
        })
        .catch((err) => {
                dispatch({type: 'GET_CUSTOMER_FAILED'})
                // dispatch(customerListsAction(getUrl));
                commonCatchBlock(err)
            }
        )

}

export const customerFavProUpdate = (baseUrl, method, formData, getUrl, getId) => dispatch => {
    // let frm = jQuery(_form);

    dispatch({type: 'GET_CUSTOMER_REQUEST'})

    let currentToken = localStorage.getItem('currentToken') ? JSON.parse(localStorage.getItem('currentToken')) : null
    axios({
        url: baseUrl, data: formData, method: method,
        headers: {
            "x-auth-token": currentToken,
        },
    })
        .then((response) => {
            dispatch({type: 'UPDATE_CUSTOMER_SUCCESS'})
            toast.success(response.data.message, {
                duration: 2000,
                position: 'top-right',
            });
            dispatch(customerGetEditAction(getUrl))
            setTimeout(() => {
                window.location.href = "/customer/fav/" + getId
            }, 1000)
        })
        .catch((err) => {
                dispatch({type: 'GET_CUSTOMER_FAILED'})

                if (getId) {
                    dispatch(customerGetEditAction(getUrl));
                }
                commonCatchBlock(err)


            }
        )

}

export const makeCustomerNull = () => dispatch => {
    dispatch({type: 'MAKE_NULL'})
}