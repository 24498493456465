export const serviceListReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_SERVICE_REQUEST':
            return {
                loading: true,
            }
        case'GET_SERVICE_SUCCESS':
            return {
                ...state,
                services: action.payload.data,
                loading: false,
                totalCount: action.payload.totalCount
            }
        case'EDIT_SERVICE_SUCCESS':
            return {
                ...state,
                service: action.payload,
                loading: false
            }

        case'ASSIGN_PRICE':
            return {
                ...state,
                loading: false
            }
        case'CREATE_SERVICE_SUCCESS':
            return {
                ...state,
                service: action.payload,
                loading: false
            }
        case'GET_SERVICE_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case'DELETE_SERVICE_SUCCESS':
            return {
                ...state,
                loading: false
            }
        case'DELETE_SERVICE_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case'UPDATE_SERVICE_SUCCESS':
            return {
                ...state,
                loading: false
            }
        case'UPDATE_SERVICE_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        default:
            return {
                ...state,
                loading: false
            }


    }
}