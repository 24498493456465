export const needHelpListReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_NEED_HELP_REQUEST':
            return {
                loading: true,
            }
        case'GET_NEED_HELP_SUCCESS':
            return {
                ...state,
                needHelps: action.payload.data,
                loading: false,
                totalCount: action.payload.totalCount
            }
        case'EDIT_NEED_HELP_SUCCESS':
            return {
                needHelp: action.payload,
                loading: false,

            }
        case'CREATE_NEED_HELP_SUCCESS':
            return {
                needHelp: action.payload,
                loading: false
            }
        case'GET_NEED_HELP_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        case'DELETE_NEED_HELP_SUCCESS':
            return {
                loading: false
            }
        case'DELETE_NEED_HELP_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        case'UPDATE_NEED_HELP_SUCCESS':
            return {
                loading: false
            }
        case'UPDATE_NEED_HELP_FAILED':
            return {
                loading: false,
                error: action.payload
            }

        default:
            return {
                ...state,
                loading: false
            }


    }
}