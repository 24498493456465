import axios from 'axios'
import toast from "react-hot-toast";
import jQuery from "jquery";
import {authHeader, commonCatchBlock} from "../helpers/helpers";


export const loginUserAction = (values, baseUrl, _form, partner = false) => dispatch => {

    dispatch({type: 'USER_LOGIN_REQUEST'})
    let frm = jQuery(_form);
    axios.post(baseUrl, values,)
        .then((res) => {
            dispatch({type: 'USER_LOGIN_SUCCESS'})
            localStorage.setItem('currentUser', JSON.stringify(res.data.user))
            localStorage.setItem('currentToken', JSON.stringify(res.data.token))

            toast.success('Login Successfully.', {
                duration: 2000,
                position: 'top-right',
            })
            setTimeout(() => {
                window.location.href = (!partner) ? "/dashboard" : "/partner/dashboard"
            }, 2000)
        })
        .catch((err) => {
                // console.log(err)
                dispatch({type: 'USER_LOGIN_FAILED', payload: err})
                // if (err.response.status === 401) {
                //     return toast.error(err.response.data.message, {
                //         duration: 2000,
                //         position: 'top-right',
                //     });
                // }
                commonCatchBlock(err, frm, partner)
            }
        )
}

export const forgotPasswordActions = (values, baseUrl, _form, partner = false) => dispatch => {

    dispatch({type: 'FORGOT_PASSWORD'})
    let frm = jQuery(_form);
    axios.post(baseUrl, values,)
        .then((res) => {
            dispatch({type: 'FORGOT_PASSWORD_SUCCESS'})
            toast.success('Mail Sent Successfully.', {
                duration: 2000,
                position: 'top-right',
            })
            setTimeout(() => {
                window.location.href = (partner) ? "/partner/login" : "/login"
            }, 2000)
        })
        .catch((err) => {
                dispatch({type: 'USER_LOGIN_FAILED', payload: err})
                commonCatchBlock(err, frm)
            }
        )
}

export const resetPasswordActions = (values, baseUrl, _form, token, partner = false) => dispatch => {

    dispatch({type: 'RESET_PASSWORD'})
    let frm = jQuery(_form);
    axios.post(baseUrl, values, {
        headers: {
            "x-auth-token": token
        }
    })
        .then((res) => {
            dispatch({type: 'RESET_PASSWORD_SUCCESS'})
            toast.success(res.data.message, {
                duration: 2000,
                position: 'top-right',
            })
            setTimeout(() => {
                if (res.data.role === "user" || res.data.role === "member")
                    window.location.href = "/login"
                else if (res.data.role === "partner")
                    window.location.href = "/partner/login"
                else
                    window.location.href = "/password/success"
            }, 2000)
        })
        .catch((err) => {
                dispatch({type: 'USER_LOGIN_FAILED', payload: err})
                commonCatchBlock(err, frm)
            }
        )
}

export const logoutUserAction = (partner = false) => dispatch => {
    localStorage.removeItem('currentUser')
    localStorage.removeItem('currentToken')
    dispatch({type: 'USER_LOGOUT'})
    toast.success("Logout Successfully", {
        duration: 2000,
        position: 'top-right',
    })
    setTimeout(() => {
        window.location.href = (partner) ? "/partner/login" : "/"
    }, 2000)
}

export const homeDashboardAction = (baseUrl, data={}, partner = false) => dispatch => {
    dispatch({type: 'USER_HOME_REQUEST'})

    axios.post(baseUrl, data, {
        headers: authHeader(baseUrl),

    })
        .then((res) => {

            dispatch({
                type: 'USER_HOME_SUCCESS', payload: {
                    appointmentCount: res.data.appointmentCount,
                    customerCount: res.data.customerCount,
                    driverCount: res.data.driverCount,
                    cleanerCount: res.data.cleanerCount,
                    userName: res.data.userName,
                    dashboardData: res.data.dashboardData,
                    chartData: res.data.chartData,
                    partnerCount: res.data.partnerCount,
                    partnerCleanerCount: res.data.partnerCleanerCount,
                    driverPartnerCount: res.data.driverPartnerCount,
                    partnerCurrency: res.data.partnerCurrency,
                    utilization: res.data.utilization,
                }
            })
            dispatch({type: 'USER_LOGIN_SUCCESS'})
            localStorage.setItem('currentUser', JSON.stringify(res.data.user))
        })
        .catch((err) => {
                dispatch({type: 'USER_HOME_FAILED'})
                commonCatchBlock(err, partner)
            }
        )
}

export const profileUpdate = (baseUrl, method, _form, partner = false) => dispatch => {

    dispatch({type: 'PROFILE_UPDATE'})
    let frm = jQuery(_form);
    axios({
        url: baseUrl, data: frm.serialize(), method: method,
        headers: authHeader(baseUrl)
    })
        .then((res) => {
            dispatch({type: 'PROFILE_UPDATE_SUCCESS'})
            localStorage.setItem('currentUser', JSON.stringify(res.data.user))
            toast.success(res.data.message, {
                duration: 2000,
                position: 'top-right',
            })
            setTimeout(() => {
                window.location.href = (partner) ? "/partner/dashboard" : "/dashboard"
            }, 2000)
        })
        .catch((err) => {
                dispatch({type: 'USER_LOGIN_FAILED', payload: err})
                commonCatchBlock(err, frm)
            }
        )
}