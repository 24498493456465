import React, {useState} from "react";
import {Link} from "react-router-dom";
import {logoutUserAction} from "../actions/userActions";
import {useDispatch} from "react-redux";
import {isAdmin} from "../helpers/helpers";

export function PartnerSidebar() {
    const dispatch = useDispatch();

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    const [dropclick, setdropClick] = useState(false);
    const handleDrop = () => setdropClick(!dropclick);
    const quickBookUrl = process.env.REACT_APP_LOCAL_API_URL + '/quickBookToken'

    return (
        <div
            className=" text-white sidebarnav bg-gradient-to-t from-purple-600 to-purple-900 z-10  left-0  lg:visible invisible py-5">
            <div className="logo w-50 mx-auto mb-4">
                <Link to={"/partner/dashboard"}>
                    <img
                        src="/images/logo-white.png"
                        alt={"Logo"}
                        className="max-w-full border-1 mx-auto rounded-full drop-shadow "
                        width="50"
                    />
                </Link>
            </div>
            <div
                className=" text-white my-3 togllefull text-center"
                onClick={handleClick}
            >
                <i className="fa fa-bars text-2xl lg:block hidden"/>
                <i className="fa fa-close text-3xl lg:hidden block"/>
            </div>

            <ul className={click ? "sidebarulOpen" : "sidebarul"}>
                <li title="Dashboard">
                    <Link
                        to={"/partner/dashboard"}
                        className="p-3  rounded-lg pl-4 flex items-center  hover:text-white text-3xl dark:bg-blue-900"
                    >
                        <i className="fa fa-tachometer   w-8 "/>
                        <span
                            className="text-base ml-2"
                            title="Dashboard"
                            data-tooltip-target="tooltip-default"
                        >
                Dashboard
              </span>
                    </Link>
                </li>
                {/*{isAdmin() && (*/}
                {/*<li onClick={handleDrop}>*/}
                {/*    <div className={dropclick ? "dropdownOpen" : "dropdown"}>*/}
                {/*        <div*/}
                {/*            title="Roles"*/}
                {/*            className="p-3 pl-4 flex rounded  datatoggle  relative items-center text-white  text-2xl  hover:text-white dark:text-gray-300  "*/}
                {/*        >*/}
                {/*            <i>*/}
                {/*                <img src="/images/clean.png" alt={"role"}/>*/}
                {/*            </i>*/}
                {/*            <span className="text-base ">Roles</span>*/}

                {/*            <i className="fa fa-angle-right"/>*/}
                {/*        </div>*/}
                {/*        /!*<ul className="slidecontent " style={{display: "none"}}>*!/*/}
                {/*        /!*    <li>*!/*/}
                {/*        /!*        <Link to={"/partner/role/add"}>*!/*/}
                {/*        /!*            Add Role*!/*/}
                {/*        /!*        </Link>*!/*/}
                {/*        /!*    </li>*!/*/}
                {/*        /!*    <li>*!/*/}
                {/*        /!*        <a href={"/partner/role/list"}>*!/*/}
                {/*        /!*            Role List*!/*/}
                {/*        /!*        </a>*!/*/}
                {/*        /!*    </li>*!/*/}
                {/*        /!*</ul>*!/*/}
                {/*    </div>*/}
                {/*</li>*/}
                {/*)*/}
                {/*}*/}
                {/*{(isAdmin() || false) &&*/}
                {/*(*/}
                <li onClick={handleDrop}>
                    <div className={dropclick ? "dropdownOpen" : "dropdown"}>
                        <div
                            title="Pros"
                            className="p-3 pl-4 flex rounded  datatoggle  relative items-center text-white  text-2xl  hover:text-white dark:text-gray-300  "
                        >
                            <i>
                                <img src="/images/clean.png" alt={"clean"}/>
                            </i>
                            <span className="text-base ">Pros</span>

                            <i className="fa fa-angle-right"/>
                        </div>
                        <ul className="slidecontent " style={{display: "none"}}>
                            <li>
                                <a href={"/partner/cleaner/list"}>
                                    Pros List
                                </a>
                            </li>
                            {/*<li>*/}
                            {/*    <a href={"/partner/cleaner/review"}>*/}
                            {/*        Pros Review List*/}
                            {/*    </a>*/}
                            {/*</li>*/}
                        </ul>
                    </div>
                </li>

                {/*)*/}
                {/*}*/}
                {/* {(isAdmin() || false) &&*/}
                {/*(*/}
                <li onClick={handleDrop}>
                    <div className={dropclick ? "dropdownOpen" : "dropdown"}>
                        <div
                            title="Drivers"
                            className="p-3 pl-4 flex rounded  datatoggle  relative items-center text-white  text-2xl  hover:text-white dark:text-gray-300  "
                        >
                            <i className="fa fa-car    "/>
                            <span className="text-base ">Drivers </span>
                            <i className="fa fa-angle-right"/>
                        </div>
                        <ul className="slidecontent " style={{display: "none"}}>
                            {/*<li>*/}
                            {/*    <Link to={"/partner/driver/add"}>*/}
                            {/*        Add Driver*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            <li>
                                <a href={"/partner/driver/list"}>
                                    Driver List
                                </a>
                            </li>
                            <li>
                                <a href={"/partner/cleaner/driver/list"}>
                                    Partner's Group
                                </a>
                            </li>
                            <li>
                                <Link to={"/partner/pro/attendance/list"}
                                      className="p-3 pl-4 flex rounded  datatoggle  relative items-center text-white  text-2xl  hover:text-white dark:text-gray-300  ">
                                    {/*<i className="fa fa-book  "/>*/}
                                    {/*<span className="text-base text-left ">*/}
                                    Pro's Attendance List
                                    {/*</span>*/}
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>
                {/*)*/}
                {/*}*/}


                <li onClick={handleDrop}>
                    <div className={dropclick ? "dropdownOpen" : "dropdown"}>
                        <div
                            title="Pros"
                            className="p-3 pl-4 flex rounded  datatoggle  relative items-center text-white  text-2xl  hover:text-white dark:text-gray-300  "
                        >
                            <i className="fa fa-bar-chart "/>
                            <span className="text-base ">Appointments</span>

                            <i className="fa fa-angle-right"/>
                        </div>
                        <ul className="slidecontent " style={{display: "none"}}>
                            <li>
                                <a href={"/partner/appointment"}>
                                    Appointment List
                                </a>
                            </li>
                            <li>
                                <a href={"/partner/appointment/archived"}>
                                    Archived List
                                </a>
                            </li>
                            <li>
                                <Link to={"/partner/appointment/calendar"}>
                                    Calendar View
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>

                {/*<li onClick={handleDrop}>*/}
                {/*    <div className={dropclick ? "dropdownOpen" : "dropdown"}>*/}
                {/*        <div*/}
                {/*            title="Cleaners"*/}
                {/*            className="p-3 pl-4 flex rounded  datatoggle  relative items-center text-white  text-2xl  hover:text-white dark:text-gray-300  "*/}
                {/*        >*/}
                {/*            <i className="fa fa-clock "/>*/}
                {/*            <span className="text-base ">Recurring Appointments</span>*/}


                {/*        </div>*/}

                {/*    </div>*/}
                {/*</li>*/}
                {/*<li onClick={handleDrop}>*/}
                {/*    <div className={dropclick ? "dropdownOpen" : "dropdown"}>*/}
                {/*        <div*/}
                {/*            title="Drivers"*/}
                {/*            className="p-3 pl-4 flex rounded  datatoggle  relative items-center text-white  text-2xl  hover:text-white dark:text-gray-300  "*/}
                {/*        >*/}
                {/*            <i className="fa fa-archive    "/>*/}
                {/*            <span className="text-base ">Package <br></br>Appointments </span>*/}

                {/*        </div>*/}

                {/*    </div>*/}
                {/*</li>*/}
                {/*<li onClick={handleDrop}>*/}
                {/*    <div className={dropclick ? "dropdownOpen" : "dropdown"}>*/}
                {/*        <div*/}
                {/*            title="Customers"*/}
                {/*            className="p-3 pl-4 flex rounded  datatoggle  relative items-center text-white  text-2xl  hover:text-white dark:text-gray-300  "*/}
                {/*        >*/}
                {/*            <i className="fa fa-users    "/>*/}
                {/*            <span className="text-base ">Crew Members </span>*/}


                {/*        </div>*/}

                {/*    </div>*/}
                {/*</li>*/}
                {/*<li>*/}

                {/*    <Link to={"/partner/attendance"}*/}
                {/*          className="p-3 pl-4 flex rounded  datatoggle  relative items-center text-white  text-2xl  hover:text-white dark:text-gray-300  ">*/}
                {/*        <i className="fa fa-book  "/>*/}
                {/*        <span className="text-base text-left ">Professional Attendance List </span>*/}
                {/*    </Link>*/}


                {/*</li>*/}
                {/* <li>
                    <div
                        className=" flex rounded relative items-center text-white  text-2xl  hover:text-white dark:text-gray-300  ">
                        <a
                            href={"/broadcast/list"}
                            title="Broadcast"
                            className="rounded-lg flex items-center p-3 pl-4 w-full hover:text-white text-3xl dark:bg-blue-900"
                        >
                            <i className="fa fa-star"/>
                            <span className="text-base ml-2 ">Customer Ratings</span>
                        </a>
                    </div>
                </li> */}
                {/*<li>*/}
                {/*    <div*/}
                {/*        className=" flex rounded relative items-center text-white  text-2xl  hover:text-white dark:text-gray-300  ">*/}
                {/*        <a*/}
                {/*            href={"/partner/penalty-logs/list"}*/}
                {/*            title="Penalty Logs"*/}
                {/*            className="rounded-lg flex items-center p-3 pl-4 w-full hover:text-white text-3xl dark:bg-blue-900"*/}
                {/*        >*/}
                {/*            <i className="fa fa-money"/>*/}
                {/*            <span className="text-base ml-2 ">Penalty Logs</span>*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    <div*/}
                {/*        className=" flex rounded relative items-center text-white  text-2xl  hover:text-white dark:text-gray-300  ">*/}
                {/*        <a*/}
                {/*            href={"/partner/penalty-dashboard"}*/}
                {/*            title="penalty-dashboard"*/}
                {/*            className="rounded-lg flex items-center p-3 pl-4 w-full hover:text-white text-3xl dark:bg-blue-900"*/}
                {/*        >*/}
                {/*            <i className="fa fa-money"/>*/}
                {/*            <span className="text-base ml-2 ">Penalty Dashboard</span>*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*</li>*/}
                <li onClick={() => dispatch(logoutUserAction(true))}>
                    <Link
                        title="Logout"
                        to={"#"}
                        className="p-3 pl-4 flex rounded items-center text-white  hover:text-white dark:text-gray-300 text-2xl"
                    >
                        <i className="fa fa-sign-in   "/>{" "}
                        <span className="text-base ">Logout</span>
                    </Link>
                </li>
            </ul>
            <div className=" text-white my-3 togllemin text-center" title="">
                <i className="fa fa-arrow-left text-2xl"/>
            </div>
        </div>
    );
}
