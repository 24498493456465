export const driverListReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_DRIVERS_REQUEST':
            return {
                loading: true,
            }
        case'GET_DRIVERS_SUCCESS':
            return {
                ...state,
                drivers: action.payload.data,
                loading: false,
                totalCount: action.payload.totalCount,
                cleaners: action.payload.cleaners
            }
        case'GET_DRIVERS_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        case'EDIT_DRIVERS_SUCCESS':
            return {
                loading: false,
                driver: action.payload,
            }
        case'EDIT_MAP_SUCCESS':
            return {
                driver: action.payload,
                loading: false
            }
        case'GET_MAP_REQUEST':
            return {
                loading: true,
            }
        case'CREATE_DRIVERS_SUCCESS':
            return {
                driver: action.payload,
                loading: false
            }
        case'DELETE_DRIVERS_SUCCESS':
            return {
                loading: false
            }
        case'DELETE_DRIVERS_FAILED':
            return {
                loading: false,
                error: action.payload
            }

        case'UPDATE_DRIVERS_SUCCESS':
            return {
                loading: false
            }
        case'UPDATE_DRIVERS_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        default:
            return {
                ...state,
                loading: false
            }
    }
}