import React, {useState} from "react";
import {Link} from "react-router-dom";
import {logoutUserAction} from "../actions/userActions";
import {useDispatch} from "react-redux";
import {canAddPolicy, canViewPolicy, isAdmin, onlyAdmin} from "../helpers/helpers";

export function Sidebar() {
    const dispatch = useDispatch();

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    const [dropclick, setdropClick] = useState(false);
    const handleDrop = () => setdropClick(!dropclick);
    const quickBookUrl = process.env.REACT_APP_LOCAL_API_URL + '/quickBookToken'

    return (
        <div
            className="  sidebarnav bg-gradient-to-t bg-purple-900 z-10  left-0  lg:visible invisible py-5">
            <div className="logo w-50 mx-auto mb-4">
                <Link to={"/dashboard"}>
                    <img
                        src="/images/logo-white.png"
                        alt={"Logo"}
                        className="max-w-full border-1 mx-auto rounded-full drop-shadow "
                        width="50"
                    />
                </Link>
            </div>
            <div
                className="  py-3 togllefull text-center"
                onClick={handleClick}
            >
                <i className="fa fa-bars text-2xl lg:block hidden"/>
                <i className="fa fa-close text-3xl lg:hidden block"/>
            </div>
            <ul className={click ? "sidebarulOpen" : "sidebarul"}>
                <li title="Dashboard">
                    <Link
                        to={"/dashboard"}
                        className="p-3   pl-4 flex items-center  hover: text-3xl dark:bg-blue-900"
                    >
                        <i className="fa fa-tachometer   w-8 "/>
                        <span
                            className="text-base ml-2"
                            title="Dashboard"
                            data-tooltip-target="tooltip-default"
                        >
                Dashboard
              </span>
                    </Link>
                </li>
                {isAdmin() && (
                    <>
                        <li onClick={handleDrop}>
                            <div className={dropclick ? "dropdownOpen" : "dropdown"}>
                                <div
                                    title="Roles"
                                    className="p-3 pl-4 flex rounded  datatoggle  relative items-center   text-2xl  hover: dark:text-gray-300  "
                                >
                                    <i>
                                        <img src="/images/roles.png" alt={"role"}/>
                                    </i>
                                    <span className="text-base ">Roles</span>

                                    <i className="fa fa-angle-right"/>
                                </div>
                                <ul className="slidecontent " style={{display: "none"}}>
                                    <li>
                                        <Link to={"/role/add"}>
                                            Add Role
                                        </Link>
                                    </li>
                                    <li>
                                        <a href={"/role/list"}>
                                            Role List
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li onClick={handleDrop}>
                            <div className={dropclick ? "dropdownOpen" : "dropdown"}>
                                <div
                                    title="Roles"
                                    className="p-3 pl-4 flex rounded  datatoggle  relative items-center   text-2xl  hover: dark:text-gray-300  "
                                >
                                    <i className="fa fa-bell text-xl">

                                    </i>
                                    <span className="text-base ">Notification Roles</span>

                                    <i className="fa fa-angle-right"/>
                                </div>
                                <ul className="slidecontent " style={{display: "none"}}>
                                    <li>
                                        <Link to={"/notification/roles/add"}>
                                            Add Notification Role
                                        </Link>
                                    </li>
                                    <li>
                                        <a href={"/notification/roles/list"}>
                                            Notification Role List
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li onClick={handleDrop}>
                            <div className={dropclick ? "dropdownOpen" : "dropdown"}>
                                <div
                                    title="Member"
                                    className="p-3 pl-4 flex rounded  datatoggle  relative items-center   text-2xl  hover: dark:text-gray-300  "
                                >
                                    <i>
                                        <img src="/images/member.png" alt={"role"}/>
                                    </i>
                                    <span className="text-base ">Members</span>

                                    <i className="fa fa-angle-right"/>
                                </div>
                                <ul className="slidecontent " style={{display: "none"}}>
                                    <li>
                                        <Link to={"/member/add"}>
                                            Add Member
                                        </Link>
                                    </li>
                                    <li>
                                        <a href={"/member/list"}>
                                            Member List
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </>
                )}

                {(isAdmin() || canViewPolicy(2)) && (
                    <li onClick={handleDrop}>
                        <div className={dropclick ? "dropdownOpen" : "dropdown"}>
                            <div
                                title="Partner's"
                                className="p-3 pl-4 flex rounded  datatoggle  relative items-center   text-2xl  hover: dark:text-gray-300  "
                            >
                                <i>
                                    <img src="/images/partner.png" alt={"partner's"}/>
                                </i>
                                <span className="text-base ">Partners</span>

                                <i className="fa fa-angle-right"/>
                            </div>
                            <ul className="slidecontent " style={{display: "none"}}>
                                {(isAdmin() || canAddPolicy(2)) &&
                                <li>
                                    <Link to={"/partner/add"}>
                                        Add Partner
                                    </Link>
                                </li>
                                }
                                {(isAdmin() || canViewPolicy(2)) &&
                                <li>
                                    <a href={"/partner/list"}>
                                        Partner's List
                                    </a>
                                </li>
                                }

                            </ul>
                        </div>
                    </li>)
                }

                {(isAdmin() || canViewPolicy(3)) &&
                (<li onClick={handleDrop}>
                    <div className={dropclick ? "dropdownOpen" : "dropdown"}>
                        <div
                            title="Pros"
                            className="p-3 pl-4 flex rounded  datatoggle  relative items-center   text-2xl  hover: dark:text-gray-300  "
                        >
                            <i>
                                <img src="/images/clean.png" alt={"pros"}/>
                            </i>
                            <span className="text-base ">Pros</span>

                            <i className="fa fa-angle-right"/>
                        </div>
                        <ul className="slidecontent " style={{display: "none"}}>
                            {(isAdmin() || canAddPolicy(3)) &&
                            <li>
                                <Link to={"/cleaner/add"}>
                                    Add Pros
                                </Link>
                            </li>
                            }
                            <li>
                                <a href={"/cleaner/list"}>
                                    Pros List
                                </a>
                            </li>
                            {(isAdmin() || canViewPolicy(2)) &&
                            <li>
                                <a href={"/partners/cleaner/list"}>
                                    Partner Pros List
                                </a>
                            </li>
                            }
                        </ul>
                    </div>
                </li>)
                }
                {(isAdmin() || canViewPolicy(4)) &&
                (<li onClick={handleDrop}>
                    <div className={dropclick ? "dropdownOpen" : "dropdown"}>
                        <div
                            title="Drivers"
                            className="p-3 pl-4 flex rounded  datatoggle  relative items-center   text-2xl  hover: dark:text-gray-300  "
                        >
                            <i className="fa fa-car "/>
                            <span className="text-base ">Drivers </span>
                            <i className="fa fa-angle-right"/>
                        </div>
                        <ul className="slidecontent " style={{display: "none"}}>
                            {(isAdmin() || canAddPolicy(4)) &&
                            <li>
                                <Link to={"/driver/add"}>
                                    Add Driver
                                </Link>
                            </li>
                            }
                            <li>
                                <a href={"/driver/list"}>
                                    Driver List
                                </a>
                            </li>
                            <li>
                                <a href={"/cleaner/driver/list"}>
                                    Right Hand's Group
                                </a>
                            </li>
                            {(isAdmin() || canViewPolicy(2)) &&
                            <li>
                                <a href={"/partners/cleaner/driver/list"}>
                                    Partner's Group List
                                </a>
                            </li>
                            }
                            {(isAdmin() || canViewPolicy(2)) &&
                            <li>
                                <a href={"/partners/driver/list"}>
                                    Partner's Driver List
                                </a>
                            </li>
                            }

                        </ul>
                    </div>
                </li>)
                }
                {(isAdmin() || canViewPolicy(5)) &&
                (<li onClick={handleDrop}>
                    <div className={dropclick ? "dropdownOpen" : "dropdown"}>
                        <div
                            title="Customers"
                            className="p-3 pl-4 flex rounded  datatoggle  relative items-center   text-2xl  hover: dark:text-gray-300  "
                        >
                            <i className="fa fa-users    "/>
                            <span className="text-base ">Customers </span>

                            <i className="fa fa-angle-right"/>
                        </div>
                        <ul className="slidecontent " style={{display: "none"}}>
                            {(isAdmin() || canAddPolicy(5)) &&
                            <li>
                                <Link to={"/customer/add"}>
                                    Add Customer
                                </Link>
                            </li>
                            }
                            <li>
                                <a href={"/customer/list"}>
                                    Customer List
                                </a>
                            </li>
                            {(isAdmin() || canViewPolicy(2)) &&
                            <li>
                                <a href={"/rh/customer/list"}>
                                    RH Club Customers
                                </a>
                            </li>
                            }

                        </ul>
                    </div>
                </li>)
                }
                {(isAdmin() || canViewPolicy(6)) &&
                (<li>
                    <div
                        title="Appointments"
                        className="p-3 pl-4 flex rounded  datatoggle  relative items-center   text-2xl  hover: dark:text-gray-300  "
                    >

                        <i className="fa fa-calendar  "/>
                        <span className="text-base ">Appointments </span>
                        <i className="fa fa-angle-right"/>
                    </div>
                    <ul className="slidecontent " style={{display: "none"}}>
                        {isAdmin() &&
                        <li>
                            <Link to={"/appointment/calendar"}>
                                Calendar View
                            </Link>
                        </li>
                        }
                        <li>
                            <a href={"/appointment/current/list"}>
                                Package Appointment List
                            </a>
                        </li>
                        <li>
                            <a href={"/appointment/recurring/list"}>
                                Frequencies Appointment List
                            </a>
                        </li>
                        <li>
                            <a href={"/appointment/rh/list"}>
                                RH Club Appointment List
                            </a>
                        </li>
                        <li>
                            <a href={"/appointment/archived/list"}>
                                Archived List
                            </a>
                        </li>
                        {isAdmin() &&
                        <li>
                            <a href={"/appointment/deleted/list"}>
                                Bin List
                            </a>
                        </li>
                        }
                        {(isAdmin() || canAddPolicy(6)) &&
                        <li>
                            <Link to={"/appointment/add"}>
                                Add Appointment
                            </Link>
                        </li>
                        }
                        {isAdmin() &&
                        <li>
                            <a href={"/team/tracking"}>
                                Team Tracking
                            </a>
                        </li>
                        }
                    </ul>
                </li>)
                }
                {(isAdmin() || canViewPolicy(7)) && (
                    <>
                        <li>
                            <div
                                title="Masters"
                                className="p-3 pl-4 flex rounded  datatoggle  relative items-center   text-2xl  hover: dark:text-gray-300  "
                            >
                                <i className="fa fa-cog "/>
                                <span className="text-base ">Master Data </span>
                                <i className="fa fa-angle-right"/>
                            </div>
                            <ul className="slidecontent " style={{display: "none"}}>
                                <li>
                                    <a href={"/country/list"}>
                                        Country Master
                                    </a>
                                </li>

                                <li>
                                    <a href={"/frequency/list"}>
                                        Frequency
                                    </a>
                                </li>
                                <li>
                                    <a href={"/serviceType/list"}>
                                        Service Types
                                    </a>
                                </li>
                                <li>
                                    <a href={"/service/list"}>
                                        Service
                                    </a>
                                </li>
                                <li>
                                    <a href={"/sub/service/list"}>
                                        Service Categories
                                    </a>
                                </li>
                                <li>
                                    <a href={"/package/service/list"}>
                                        Service Packages
                                    </a>
                                </li>
                                <li>
                                    <a href={"/banner/list"}>
                                        Banner
                                    </a>
                                </li>
                                <li>
                                    <a href={"/content/list"}>
                                        Policy
                                    </a>
                                </li>
                                {/*<li>*/}
                                {/*    <a href={"/subscription/list"}>*/}
                                {/*        Subscriptions*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                                <li>
                                    <a href={"/rush/slot/list"}>
                                        Rush Slots</a>
                                </li>
                                <li>
                                    <a href={"/price/master/list"}>
                                        Price Master
                                    </a>
                                </li>
                                <li>
                                    <a href={"/holiday/list"}>
                                        Holiday
                                    </a>
                                </li>
                                {/*<li>*/}
                                {/*    <Link to={"/template/list"}>*/}
                                {/*        Template's*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                            </ul>
                        </li>


                        {/*<li>*/}
                        {/*    <div*/}
                        {/*        className=" flex rounded  datatoggle  relative items-center   text-2xl  hover: dark:text-gray-300  ">*/}
                        {/*        <Link*/}
                        {/*            title="Right Hand Credit"*/}
                        {/*            to={"/credit"}*/}
                        {/*            className="rounded-lg p-3 pl-4 w-full flex items-center  hover: text-3xl dark:bg-blue-900"*/}
                        {/*        >*/}
                        {/*            <i*/}
                        {/*                className="fa fa-credit-card    "*/}
                        {/*                style={{fontSize: "24px"}}*/}
                        {/*            />*/}
                        {/*            <span className="text-base ml-2 ">Right Hand Credit</span>*/}
                        {/*        </Link>*/}
                        {/*    </div>*/}
                        {/*</li>*/}

                        {/*<li>*/}
                        {/*    <Link to={"/cleanerreview"} title="Cleaner Reviews">*/}
                        {/*        <div*/}
                        {/*            className="p-3 pl-4 flex rounded  datatoggle  relative items-center   text-2xl  hover: dark:text-gray-300  ">*/}
                        {/*            <i className="fa fa-star    "/>*/}
                        {/*            <span className="text-base ">Cleaner Reviews </span>*/}
                        {/*        </div>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                        {/*<li>*/}
                        {/*    <Link to={"/faq"} title="FAQ">*/}
                        {/*        <div*/}
                        {/*            className="p-3 pl-4 flex rounded  datatoggle  relative items-center   text-2xl  hover: dark:text-gray-300  ">*/}
                        {/*            <i className="fa fa-question-circle    "/>*/}
                        {/*            <span className="text-base ">FAQ </span>*/}
                        {/*        </div>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}

                        {/*<li>*/}
                        {/*    <div*/}
                        {/*        title="FAQ"*/}
                        {/*        className="p-3 pl-4 flex rounded  datatoggle  relative items-center   text-2xl  hover: dark:text-gray-300  "*/}
                        {/*    >*/}

                        {/*        <i className="fa fa-question-circle    "/>*/}
                        {/*        <span className="text-base ">FAQ </span>*/}
                        {/*        <i className="fa fa-angle-right"/>*/}
                        {/*    </div>*/}
                        {/*    <ul className="slidecontent " style={{display: "none"}}>*/}
                        {/*        <li>*/}
                        {/*            <a href={"/faq/category/list"}>*/}
                        {/*                FAQ category*/}
                        {/*            </a>*/}
                        {/*        </li>*/}
                        {/*        <li>*/}
                        {/*            <a href={"/faq/question/list"}>*/}
                        {/*                FAQ Q/A*/}
                        {/*            </a>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</li>*/}

                        {/*{<li>*/}
                        {/*    <div*/}
                        {/*        title="Help"*/}
                        {/*        className="p-3 pl-4 flex rounded  datatoggle  relative items-center   text-2xl  hover: dark:text-gray-300  "*/}
                        {/*    >*/}

                        {/*        <i className="fa-solid fa-circle-info"/>*/}
                        {/*        <span className="text-base ">Help </span>*/}
                        {/*        <i className="fa fa-angle-right"/>*/}
                        {/*    </div>*/}
                        {/*    <ul className="slidecontent " style={{display: "none"}}>*/}
                        {/*        <li>*/}
                        {/*            <a href={"/help/category/list"}>*/}
                        {/*                Need Help Question*/}
                        {/*            </a>*/}
                        {/*        </li>*/}
                        {/*        /!*<li>*!/*/}
                        {/*        /!*    <a href={"/help/question/list"}>*!/*/}
                        {/*        /!*        Help Q/A*!/*/}
                        {/*        /!*    </a>*!/*/}
                        {/*        /!*</li>*!/*/}
                        {/*    </ul>*/}
                        {/*</li>}*/}
                    </>)}
                {(isAdmin() || canViewPolicy(16)) && (
                    <li>
                        <div
                            title="HR"
                            className="p-3 pl-4 flex rounded  datatoggle  relative items-center   text-2xl  hover: dark:text-gray-300  "
                        >
                            <i>
                                <img src="/images/hr.png" alt={"HR"}/>
                            </i>
                            <span className="text-base ">HR section </span>
                            <i className="fa fa-angle-right"/>
                        </div>
                        <ul className="slidecontent " style={{display: "none"}}>
                            <li>
                                <a
                                    href={"/penalty-logs/list"}
                                    title="Penalty Logs"
                                    className="rounded-lg flex items-center p-3 pl-4 w-full hover: text-3xl dark:bg-blue-900"
                                >
                                    Penalty Logs
                                </a>
                            </li>
                            <li>
                                <a href={"/partner/penalty-dashboard/list"}>
                                    Penalty Dashboard
                                </a>
                            </li>
                            <li>
                                <Link to={"/pro/attendance/list"}
                                      className="p-3 pl-4 flex rounded  datatoggle  relative items-center   text-2xl  hover: dark:text-gray-300  ">
                                    Pro's Attendance
                                </Link>
                            </li>

                            <li>
                                <a href={"/cleaner/review"}>
                                    Pros Review List
                                </a>
                            </li>

                            <li>
                                <a href={"/partners/pro/attendance/list"}>
                                    Partner Pro's Attendance
                                </a>
                            </li>
                            <li>
                                <a href={"/performance/report"}>
                                    Performance Report
                                </a>
                            </li>
                            {/*{(isAdmin() || canViewPolicy(15)) &&*/}
                            {/*(*/}
                            <li>
                                <Link
                                    title="Applied Jobs"
                                    to={"/applyJob/list"}
                                    className="p-3 pl-4 flex rounded items-center   hover: dark:text-gray-300 text-2xl"
                                >

                                    Applied Jobs
                                </Link>
                            </li>
                            {/*)}*/}
                            <li>
                                <a href={"/jobService/list"}>
                                    Job Services
                                </a>
                            </li>
                        </ul>
                    </li>
                )}
                {(isAdmin() || canViewPolicy(9)) && (<li>
                    <div
                        className=" flex rounded  relative items-center   text-2xl  hover: dark:text-gray-300  ">
                        <a
                            href={"/discount/list"}
                            title="Discount code"
                            className="rounded-lg flex items-center p-3 pl-4 w-full hover: text-3xl dark:bg-blue-900"
                        >
                            <i className="fa fa-percent    "/>
                            <span className="text-base ml-2 ">Discount Code </span>
                        </a>
                    </div>
                </li>)}
                {(isAdmin() || canViewPolicy(10)) && (<li>
                    <div
                        className=" flex rounded  relative items-center   text-2xl  hover: dark:text-gray-300  ">
                        <a
                            href={"/blog/list"}
                            title="Blog"
                            className="rounded-lg flex items-center p-3 pl-4 w-full hover: text-3xl dark:bg-blue-900"
                        >
                            <i className="fa fa-blog"/>
                            <span className="text-base ml-2 ">Blog </span>
                        </a>
                    </div>
                </li>)}
                {/*{(isAdmin() || canViewPolicy(11)) && (<li>*/}
                {/*    <div*/}
                {/*        className=" flex rounded  relative items-center   text-2xl  hover: dark:text-gray-300  ">*/}
                {/*        <a*/}
                {/*            href={"/subscription/uses/list"}*/}
                {/*            title="Subscription Uses"*/}
                {/*            className="rounded-lg flex items-center p-3 pl-4 w-full hover: text-3xl dark:bg-blue-900"*/}
                {/*        >*/}
                {/*            <i className="fa fa-credit-card"/>*/}
                {/*            <span className="text-base ml-2 ">Subscription Uses </span>*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*</li>)}*/}
                {(isAdmin() || canViewPolicy(12)) && (<li>
                    <div
                        className=" flex rounded relative items-center   text-2xl  hover: dark:text-gray-300  ">
                        <a
                            href={"/broadcast/list"}
                            title="Broadcast"
                            className="rounded-lg flex items-center p-3 pl-4 w-full hover: text-3xl dark:bg-blue-900"
                        >
                            <i className="fa fa-broadcast-tower"/>
                            <span className="text-base ml-2 ">Broadcast</span>
                        </a>
                    </div>
                </li>)}
                {(isAdmin() || canViewPolicy(13)) && (<li onClick={handleDrop}>
                    <div className={dropclick ? "dropdownOpen" : "dropdown"}>
                        <div
                            title="Customers"
                            className="p-3 pl-4 flex rounded  datatoggle  relative items-center   text-2xl  hover: dark:text-gray-300  "
                        >
                            <i className="fa-solid fa-book   "/>
                            <span className="text-base ">Reports </span>

                            <i className="fa fa-angle-right"/>
                        </div>
                        <ul className="slidecontent " style={{display: "none"}}>
                            <li>
                                <Link to={"/discount/report"}>
                                    Discount uses
                                </Link>
                            </li>
                            <li>
                                <Link to={"/wallet/report"}>
                                    Wallet Amount Distribution
                                </Link>
                            </li>
                            <li>
                                <Link to={"/rhCredit/report"}>
                                    Discount Distribution
                                </Link>
                            </li>
                            <li>
                                <Link to={"/pros/report"}>
                                    Pros Report
                                </Link>
                            </li>
                            <li>
                                <Link to={"/partner/pros/report"}>
                                    Partner Pros Report
                                </Link>
                            </li>
                            <li>
                                <Link to={"/customer/report"}>
                                    Customer Report
                                </Link>
                            </li>
                            {/*<li>*/}
                            {/*    <Link to={"/service/report"}>*/}
                            {/*        Service Report*/}
                            {/*    </Link>*/}
                            {/*</li>*/}

                            <li>
                                <a href={"/partners/performance/report"}>
                                    Partner Performance Report
                                </a>
                            </li>
                            <li>
                                <Link to={"/revenue/report"}>
                                    Revenue Report
                                </Link>
                            </li>
                        </ul>
                    </div>
                </li>)}
                {(isAdmin() || canViewPolicy(14)) && (<li>
                    <div
                        className=" flex rounded relative items-center   text-2xl  hover: dark:text-gray-300  ">
                        <a
                            href={quickBookUrl}
                            title="Connect QuickBook"
                            className="rounded-lg flex items-center p-3 pl-4 w-full hover: text-3xl dark:bg-blue-900"
                        >
                            <i className="fa-brands fa-quora"/>
                            <span className="text-base ml-2 ">QuickBook</span>
                        </a>
                    </div>
                </li>)}
                {/*<li>*/}
                {/*    <Link*/}
                {/*        title="Terms and conditions "*/}
                {/*        to={"/terms"}*/}
                {/*        className="p-3 pl-4 flex rounded items-center   hover: dark:text-gray-300  text-2xl"*/}
                {/*    >*/}
                {/*        <i className="fa fa-file-text   "/>*/}
                {/*        <span className="text-base ">Terms and Conditions </span>*/}
                {/*    </Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    <Link*/}
                {/*        title="Privacy policy"*/}
                {/*        to={"#"}*/}
                {/*        className="p-3 pl-4 flex rounded items-center   hover: dark:text-gray-300 text-2xl"*/}
                {/*    >*/}
                {/*        <i className="fa fa-file  "/>*/}
                {/*        <span className="text-base ">Privacy Policy </span>*/}
                {/*    </Link>*/}
                {/*</li>*/}


                {(isAdmin() || canViewPolicy(16)) &&
                (<li>
                    <Link
                        title="Announcements"
                        to={"/announcement/list"}
                        className="p-3 pl-4 flex rounded items-center   hover: dark:text-gray-300 text-2xl"
                    >
                        <i className="fa fa-bullhorn">

                        </i>
                        <span className="text-base ">Announcements </span>
                    </Link>
                </li>)}

                <li onClick={() => dispatch(logoutUserAction())}>
                    <Link
                        title="Logout"
                        to={"#"}
                        className="p-3 pl-4 flex rounded items-center   hover: dark:text-gray-300 text-2xl"
                    >
                        <i className="fa fa-sign-in   "/>{" "}
                        <span className="text-base ">Logout</span>
                    </Link>
                </li>
            </ul>
            <div className="  my-3 togllemin text-center" title="">
                <i className="fa fa-arrow-left text-2xl"/>
            </div>
        </div>
    );
}
