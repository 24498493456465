import axios from 'axios'
import toast from "react-hot-toast";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {authHeader, commonCatchBlock} from "../helpers/helpers";
// import jQuery from "jquery";

const MySwal = withReactContent(Swal)

export const notificationListsAction = (baseUrl, partner = false) => dispatch => {

    dispatch({type: 'GET_NOTIFICATION_REQUEST'})

    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({
                type: 'GET_NOTIFICATION_SUCCESS',
                payload: {data: res.data.list, totalCount: res.data.totalPages}
            })
        })
        .catch((err) => {
                dispatch({type: 'GET_NOTIFICATION_FAILED', payload: err})
                commonCatchBlock(err, partner)
            }
        )
}

export const notificationSortsAction = (baseUrl, partner = false) => dispatch => {

    dispatch({type: 'GET_NOTIFICATION_REQUEST'})

    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({
                type: 'GET_NOTIFICATION_SORTS',
                payload: res.data.list
            })
        })
        .catch((err) => {
                dispatch({type: 'GET_NOTIFICATION_FAILED', payload: err})
                commonCatchBlock(err, partner)
            }
        )
}

export const notificationDeleteAction = (baseUrl, listUrl, partner = false) => dispatch => {

    MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete!'
    }).then((result) => {
            if (result.isConfirmed) {
                dispatch({type: 'GET_NOTIFICATION_REQUEST'})
                axios.delete(baseUrl, {
                    headers: authHeader(baseUrl),
                })
                    .then((res) => {
                        dispatch({type: 'DELETE_NOTIFICATION_SUCCESS'})
                        toast.success(res.data.message, {
                            duration: 2000,
                            position: 'top-right',
                        })
                        dispatch({type: 'GET_NOTIFICATION_REQUEST'})
                        dispatch(notificationListsAction(listUrl))
                    })
                    .catch((err) => {
                            dispatch({type: 'DELETE_NOTIFICATION_FAILED'})
                            commonCatchBlock(err, partner)
                            dispatch(notificationListsAction(listUrl))
                        }
                    )
            }
        }
    )
}
