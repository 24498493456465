import {combineReducers} from 'redux'
import {createStore, applyMiddleware, compose} from "redux";
import {thunk} from 'redux-thunk'
import {userLoginReducer} from './reducers/userReducers'
import {cleanerListReducer} from "./reducers/cleanerReducer";
import {driverListReducer} from "./reducers/driverReducer";
import {customerListReducer} from "./reducers/customerReducer";
import {frequencyListReducer} from "./reducers/frequencyReducer";
import {serviceListReducer} from "./reducers/serviceReducers";
import {discountListReducer} from "./reducers/discountReducers";
import {subServiceListReducer} from "./reducers/subServiceReducers";
import {packageServiceListReducer} from "./reducers/packageServiceReducers";
import {bannerListReducer} from "./reducers/bannerReducers";
import {templateListReducer} from "./reducers/templateReducers";
import {serviceTypeListReducer} from "./reducers/serviceTypeReducer";
import {appointmentListReducer} from "./reducers/appointmentReducer";
import {broadcastListReducer} from "./reducers/broadcastReducers";
import {staticPageListReducer} from "./reducers/staticReducers";
import {faqCatListReducer} from "./reducers/faqCategoryReducer";
import {faqQuestionListReducer} from "./reducers/faqQuestionAnswerReducers";
import {subscriptionListReducer} from "./reducers/subscriptionPackageReducer";
import {blogListReducer} from "./reducers/blogReducers";
import {helpCatListReducer} from "./reducers/helpCategoryReducer";
import {helpQuestionListReducer} from "./reducers/helpQuestionAnswerReducers";
import {rushSlotListReducer} from "./reducers/rushSlotReducer";
import {holidayListReducer} from "./reducers/holidayReducers";
import {reportListReducer} from "./reducers/reportReducers";
import {notificationListReducer} from "./reducers/notificationReducers";
import {roleListReducer} from "./reducers/roleReducers";
import {partnerListReducer} from "./reducers/partnerReducer";
import {countryListReducer} from "./reducers/countryReducers";
import {needHelpListReducer} from "./reducers/needHelpReducers";
import {jobServiceListReducer} from "./reducers/jobServiceReducers";
import {applyJobListReducer} from "./reducers/applyJobReducers";
import {memberListReducer} from "./reducers/memberReducer";
import {priceMasterListReducer} from "./reducers/priceMasterReducer";
import {cleanerDriverListReducer} from "./reducers/cleanerDriverReducer";
import {announcementListReducer} from "./reducers/announcementReducers";
import {notificationRoleListReducer} from "./reducers/notificationRoleReducers";

const finalReducer = combineReducers({
    userLoginReducer: userLoginReducer,
    cleanerListReducer: cleanerListReducer,
    driverListReducer: driverListReducer,
    customerListReducer: customerListReducer,
    frequencyListReducer: frequencyListReducer,
    serviceListReducer: serviceListReducer,
    subServiceListReducer: subServiceListReducer,
    packageServiceListReducer: packageServiceListReducer,
    discountListReducer: discountListReducer,
    bannerListReducer: bannerListReducer,
    templateListReducer: templateListReducer,
    serviceTypeListReducer: serviceTypeListReducer,
    appointmentListReducer: appointmentListReducer,
    broadcastListReducer: broadcastListReducer,
    staticPageListReducer: staticPageListReducer,
    faqCatListReducer: faqCatListReducer,
    faqQuestionListReducer: faqQuestionListReducer,
    subscriptionListReducer: subscriptionListReducer,
    blogListReducer: blogListReducer,
    helpCatListReducer: helpCatListReducer,
    helpQuestionListReducer: helpQuestionListReducer,
    rushSlotListReducer: rushSlotListReducer,
    holidayListReducer: holidayListReducer,
    reportListReducer: reportListReducer,
    notificationListReducer: notificationListReducer,
    roleListReducer: roleListReducer,
    partnerListReducer: partnerListReducer,
    countryListReducer: countryListReducer,
    needHelpListReducer: needHelpListReducer,
    jobServiceListReducer: jobServiceListReducer,
    applyJobListReducer: applyJobListReducer,
    memberListReducer: memberListReducer,
    priceMasterListReducer: priceMasterListReducer,
    cleanerDriverListReducer: cleanerDriverListReducer,
    announcementListReducer: announcementListReducer,
    notificationRoleListReducer: notificationRoleListReducer
})
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const currentUser = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null
const currentToken = localStorage.getItem('currentToken') ? JSON.parse(localStorage.getItem('currentToken')) : null

const initialState = {
    userLoginReducer: {currentUser: currentUser, currentToken: currentToken}
}

const store = createStore(finalReducer, initialState, composeEnhancers(
    applyMiddleware(thunk)
))

export default store