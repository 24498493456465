export const staticPageListReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_STATIC_PAGE_REQUEST':
            return {
                loading: true,
            }
        case'GET_STATIC_PAGE_SUCCESS':
            return {
                ...state,
                staticPages: action.payload.data,
                loading: false,
                totalCount: action.payload.totalCount
            }
        case'EDIT_STATIC_PAGE_SUCCESS':
            return {
                staticPage: action.payload,
                loading: false
            }
        case'CREATE_STATIC_PAGE_SUCCESS':
            return {
                staticPage: action.payload,
                loading: false
            }
        case'GET_STATIC_PAGE_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        case'DELETE_STATIC_PAGE_SUCCESS':
            return {
                loading: false
            }
        case'DELETE_STATIC_PAGE_FAILED':
            return {
                loading: false,
                error: action.payload
            }
        case'UPDATE_STATIC_PAGE_SUCCESS':
            return {
                loading: false
            }
        case'UPDATE_STATIC_PAGE_FAILED':
            return {
                loading: false,
                error: action.payload
            }

        default:
            return {
                ...state,
                loading: false
            }


    }
}