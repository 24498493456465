import * as React from 'react';

export function LoadingIndicator() {
    return (
        <div>
            <div className="loadingIndicatorClass">
                <div className="la-ball-clip-rotate-multiple la-2x">
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}