export const userLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case'USER_LOGIN_REQUEST':
            return {
                ...state,
                loading: true,
            }

        case'FORGOT_PASSWORD':
            return {
                ...state,
                loading: true,
            }
        case'FORGOT_PASSWORD_SUCCESS':
            return {
                ...state,
                loading: false,
            }
        case'RESET_PASSWORD':
            return {
                ...state,
                loading: true,
            }
        case'RESET_PASSWORD_SUCCESS':
            return {
                loading: false,
            }
        case'PROFILE_UPDATE':
            return {
                ...state,
                loading: true,
            }
        case'PROFILE_UPDATE_SUCCESS':
            return {
                ...state,
                loading: false,
            }
        case'USER_LOGIN_SUCCESS':
            return {
                ...state,
                loading: false,
                success: true
            }
        case'USER_LOGIN_FAILED':
            return {
                ...state,
                loading: false,
                error: true
            }
        case'USER_LOGOUT':
            return {
                ...state,
            }
        case'USER_HOME_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'USER_HOME_SUCCESS':
            return {
                ...state,
                appointmentCount: action.payload.appointmentCount,
                loading: false,
                customerCount: action.payload.customerCount,
                driverCount: action.payload.driverCount,
                cleanerCount: action.payload.cleanerCount,
                userName: action.payload.userName,
                dashboardData: action.payload.dashboardData,
                chartData: action.payload.chartData,
                partnerCount: action.payload.partnerCount,
                partnerCleanerCount: action.payload.partnerCleanerCount,
                driverPartnerCount: action.payload.driverPartnerCount,
                partnerCurrency: action.payload.partnerCurrency,
                utilization: action.payload.utilization,
            }
        case'USER_CHECK_ACTION':
            return {
                ...state,
                loading: true,
                userName: action.payload,
            }
        case'USER_HOME_FAILED':
            return {
                ...state,
                loading: false,
            }

        default:
            return {
                ...state
            }
    }
}