import toast from "react-hot-toast";
import jQuery from 'jquery'

const momentTimezone = require('moment-timezone');

export function authHeader(url) {

    let currentToken = localStorage.getItem('currentToken') ? JSON.parse(localStorage.getItem('currentToken')) : null
    if (currentToken) {
        return {
            "x-auth-token": currentToken,
            "x-time-zone": getBrowserTimezone(),
            "x-hmac-token": generateHash((url))
        }
    } else {
        return {
            "x-time-zone": getBrowserTimezone(),
            "x-hmac-token": generateHash((url))
        }
    }

}

export function isAdmin() {
    if (authRole() === 'partner')
        return true
    let currentUser = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null
    return currentUser && (currentUser.isAdmin === true);
}

export function onlyAdmin() {
    let currentUser = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null
    return currentUser && (currentUser.isAdmin === true);
}

export function canViewPolicy(rankType) {
    let currentUser = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null
    let rolesArray = (currentUser?.roles?.roles) ? currentUser?.roles?.roles : []
    let canView = false

    for (let i = 0; i < rolesArray.length; i++) {
        if ((rolesArray[i]['privilege_rank'] === rankType) && rolesArray[i]['canView']) {
            canView = true
        }
    }

    return canView;
}

export function canAddPolicy(rankType) {
    let currentUser = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null
    let rolesArray = (currentUser?.roles?.roles) ? currentUser?.roles?.roles : []
    let canAdd = false

    for (let i = 0; i < rolesArray.length; i++) {
        if ((rolesArray[i]['privilege_rank'] === rankType) && rolesArray[i]['canAdd']) {
            canAdd = true
        }
    }
    return canAdd;
}

export function canEditPolicy(rankType) {
    let currentUser = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null
    let rolesArray = (currentUser?.roles?.roles) ? currentUser?.roles?.roles : []
    let canEdit = false

    for (let i = 0; i < rolesArray.length; i++) {
        if ((rolesArray[i]['privilege_rank'] === rankType) && rolesArray[i]['canEdit']) {
            canEdit = true
        }
    }

    return canEdit;
}

export function canDeletePolicy(rankType) {
    let currentUser = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')) : null
    let rolesArray = (currentUser?.roles?.roles) ? currentUser?.roles?.roles : []
    let canDelete = false

    for (let i = 0; i < rolesArray.length; i++) {
        if ((rolesArray[i]['privilege_rank'] === rankType) && rolesArray[i]['canDelete']) {
            canDelete = true
        }
    }

    return canDelete;
}

export function getBrowserTimezone() {
    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // return timezone
    // return 'Asia/Qatar'
    return momentTimezone.tz.guess();
}

export function authToken() {

    return localStorage.getItem('currentToken') ? JSON.parse(localStorage.getItem('currentToken')) : null
}

export function authRole() {

    return localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser'))?.role : ''
}

export function destroyTokenDetails() {
    localStorage.removeItem('currentUser')
    localStorage.removeItem('currentToken')
}

export function roleCan(roles, privilege_id, column) {
    let isValid = false
    if (roles?.length > 0)
        for (let i = 0; i < roles?.length; i++) {
            if (roles[i]?.privilege_id === privilege_id) {
                if (roles[i]?.[column] === true) {
                    isValid = true
                    break;
                }
            }
        }
    return isValid
}

export function commonCatchBlock(err, frm = false, partner = false) {
    if (!err.response) {
        return toast.error('Something went wrong', {
            duration: 2000,
            position: 'top-right',
        })
    }

    if (err.response.status === 401) {
        destroyTokenDetails()
        window.location.href = (partner) ? "/partner/login" : "/"
        return false
    }

    if (err.response.status === 409) {
        return toast.error('Something went wrong', {
            duration: 2000,
            position: 'top-right',
        })
    }

    if (err.response.status === 400) {
        let msg = err?.response?.data?.message || 'Something went wrong'
        return toast.error(msg, {
            duration: 2000,
            position: 'top-right',
        });
    }

    if (err.response.status === 406) {
        return toast.error(err.response.data.message, {
            duration: 2000,
            position: 'top-right',
        });
    }

    if (frm) {
        let errors = err.response.data;
        frm.find('.form-group').removeClass('error');
        frm.find('.help-block').html('');
        let checkFirstEle = 0;
        jQuery.each(errors, function (i, _msg) {
            let el = frm.find("[name=" + i + "]");
            if (checkFirstEle === 0) {
                el.focus();
                checkFirstEle++;
            }
            el.parents('.form-group').find('.help-block').addClass('text-red-500')
            el.parents('.form-group').find('.help-block').html(_msg);
        });
        return toast.error(err.response.data.message, {
            duration: 2000,
            position: 'top-right',
        })
    } else {
        if (err.response?.data?.message)
            toast.error(err.response.data.message, {
                duration: 2000,
                position: 'top-right',
            })
    }
    if (err.response.status === 500) {
        return toast.error(err.response.data.message, {
            duration: 2000,
            position: 'top-right',
        });
    }
}

export const debounce = (func) => {
    let timer;
    return function (...args) {
        const context = this;
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => {
            timer = null
            func.apply(context, args);
        }, 1000)
    }
}


export const asiaTimeZone = [
    {"value": "Asia/Aden", "name": "Aden"},
    {"value": "Asia/Almaty", "name": "Almaty"},
    {"value": "Asia/Amman", "name": "Amman"},
    {"value": "Asia/Anadyr", "name": "Anadyr"},
    {"value": "Asia/Aqtau", "name": "Aqtau"},
    {"value": "Asia/Aqtobe", "name": "Aqtobe"},
    {"value": "Asia/Ashgabat", "name": "Ashgabat"},
    {"value": "Asia/Ashkhabad", "name": "Ashkhabad"},
    {"value": "Asia/Baghdad", "name": "Baghdad"},
    {"value": "Asia/Bahrain", "name": "Bahrain"},
    {"value": "Asia/Baku", "name": "Baku"},
    {"value": "Asia/Bangkok", "name": "Bangkok"},
    {"value": "Asia/Beirut", "name": "Beirut"},
    {"value": "Asia/Bishkek", "name": "Bishkek"},
    {"value": "Asia/Brunei", "name": "Brunei"},
    {"value": "Asia/Calcutta", "name": "Calcutta"},
    {"value": "Asia/Choibalsan", "name": "Choibalsan"},
    {"value": "Asia/Chongqing", "name": "Chongqing"},
    {"value": "Asia/Chungking", "name": "Chungking"},
    {"value": "Asia/Colombo", "name": "Colombo"},
    {"value": "Asia/Dacca", "name": "Dacca"},
    {"value": "Asia/Damascus", "name": "Damascus"},
    {"value": "Asia/Dhaka", "name": "Dhaka"},
    {"value": "Asia/Dili", "name": "Dili"},
    {"value": "Asia/Dubai", "name": "Dubai"},
    {"value": "Asia/Dushanbe", "name": "Dushanbe"},
    {"value": "Asia/Gaza", "name": "Gaza"},
    {"value": "Asia/Harbin", "name": "Harbin"},
    {"value": "Asia/Ho_Chi_Minh", "name": "Ho Chi Minh"},
    {"value": "Asia/Hong_Kong", "name": "Hong Kong"},
    {"value": "Asia/Hovd", "name": "Hovd"},
    {"value": "Asia/Irkutsk", "name": "Irkutsk"},
    {"value": "Asia/Istanbul", "name": "Istanbul"},
    {"value": "Asia/Jakarta", "name": "Jakarta"},
    {"value": "Asia/Jayapura", "name": "Jayapura"},
    {"value": "Asia/Jerusalem", "name": "Jerusalem"},
    {"value": "Asia/Kabul", "name": "Kabul"},
    {"value": "Asia/Kamchatka", "name": "Kamchatka"},
    {"value": "Asia/Karachi", "name": "Karachi"},
    {"value": "Asia/Kashgar", "name": "Kashgar"},
    {"value": "Asia/Kathmandu", "name": "Kathmandu"},
    {"value": "Asia/Katmandu", "name": "Katmandu"},
    {"value": "Asia/Kolkata", "name": "Kolkata"},
    {"value": "Asia/Krasnoyarsk", "name": "Krasnoyarsk"},
    {"value": "Asia/Kuala_Lumpur", "name": "Kuala Lumpur"},
    {"value": "Asia/Kuching", "name": "Kuching"},
    {"value": "Asia/Kuwait", "name": "Kuwait"},
    {"value": "Asia/Macao", "name": "Macao"},
    {"value": "Asia/Macau", "name": "Macau"},
    {"value": "Asia/Magadan", "name": "Magadan"},
    {"value": "Asia/Makassar", "name": "Makassar"},
    {"value": "Asia/Manila", "name": "Manila"},
    {"value": "Asia/Muscat", "name": "Muscat"},
    {"value": "Asia/Nicosia", "name": "Nicosia"},
    {"value": "Asia/Novokuznetsk", "name": "Novokuznetsk"},
    {"value": "Asia/Novosibirsk", "name": "Novosibirsk"},
    {"value": "Asia/Omsk", "name": "Omsk"},
    {"value": "Asia/Oral", "name": "Oral"},
    {"value": "Asia/Phnom_Penh", "name": "Phnom Penh"},
    {"value": "Asia/Pontianak", "name": "Pontianak"},
    {"value": "Asia/Pyongyang", "name": "Pyongyang"},
    {"value": "Asia/Qatar", "name": "Qatar"},
    {"value": "Asia/Qyzylorda", "name": "Qyzylorda"},
    {"value": "Asia/Rangoon", "name": "Rangoon"},
    {"value": "Asia/Riyadh", "name": "Riyadh"},
    {"value": "Asia/Saigon", "name": "Saigon"},
    {"value": "Asia/Sakhalin", "name": "Sakhalin"},
    {"value": "Asia/Samarkand", "name": "Samarkand"},
    {"value": "Asia/Seoul", "name": "Seoul"},
    {"value": "Asia/Shanghai", "name": "Shanghai"},
    {"value": "Asia/Singapore", "name": "Singapore"},
    {"value": "Asia/Taipei", "name": "Taipei"},
    {"value": "Asia/Tashkent", "name": "Tashkent"},
    {"value": "Asia/Tbilisi", "name": "Tbilisi"},
    {"value": "Asia/Tehran", "name": "Tehran"},
    {"value": "Asia/Tel_Aviv", "name": "Tel Aviv"},
    {"value": "Asia/Thimbu", "name": "Thimbu"},
    {"value": "Asia/Thimphu", "name": "Thimphu"},
    {"value": "Asia/Tokyo", "name": "Tokyo"},
    {"value": "Asia/Ujung_Pandang", "name": "Ujung Pandang"},
    {"value": "Asia/Ulaanbaatar", "name": "Ulaanbaatar"},
    {"value": "Asia/Ulan_Bator", "name": "Ulan Bator"},
    {"value": "Asia/Urumqi", "name": "Urumqi"},
    {"value": "Asia/Vientiane", "name": "Vientiane"},
    {"value": "Asia/Vladivostok", "name": "Vladivostok"},
    {"value": "Asia/Yakutsk", "name": "Yakutsk"},
    {"value": "Asia/Yekaterinburg", "name": "Yekaterinburg"},
    {"value": "Asia/Yerevan", "name": "Yerevan"}
]

export const CurrencyList = [
    {"value": "QAR", "name": "Qatar (QAR)"},
    {"value": "$", "name": "America ($)"},
    {"value": "Rs", "name": "India (Rs)"}
]

export const numberOfDays = (dateTo = null) => {
    if (dateTo) {
        let h = new Date(dateTo)
        return new Date(h.getFullYear(), h.getMonth() + 1, 0).getDate();
    }
    let h = new Date()
    return new Date(h.getFullYear(), h.getMonth() + 1, 0).getDate();

};

export const getSheetDate = (time) => {
    return new Date(time).getDate();

};

export const getTodayDate = () => {
    return new Date().getDate();

};

export const getTodayMonth = () => {
    let month = new Date().getMonth();
    if (month === (10 || 11 || 12))
        return (month + 1)
    else
        return ('0' + (month + 1))

};

export const getTodayYear = () => {
    return new Date().getFullYear();

};

export const isPresent = (attList = false, date = 0, month = false, year = false) => {
    let matchDate
    let dateArray = [1, 2, 3, 4, 5, 6, 7, 8, 9]
    if (attList) {
        if (dateArray.includes(date)) {
            matchDate = `${year}-${month}-0${date}`
        } else {
            matchDate = `${year}-${month}-${date}`
        }
        for (let yy = 0; yy < attList?.length; yy++) {
            // console.log(yy)
            const splitArray = (attList[yy]?.createdAt).split("T");
            if (splitArray[0] === matchDate)
                return true
            //     // console.log(attList[yy])
        }
    }
    return false
}

export const generateHash = (url = 'url') => {
    const crypto = require('crypto-js');
    const apiSecret = process.env.REACT_APP_HMAC_SECRET;
    return crypto.HmacSHA256(url, apiSecret)
}