import * as React from 'react';
import {Sidebar} from "./sideBar";
import {Header} from "./header";
import {Outlet} from "react-router-dom";
import {useEffect} from "react";
import {onMessageListener} from "../firebase";
import {notificationSortsAction} from "../actions/notificationActions";
import {useDispatch} from "react-redux";

export function Layout() {
    const dispatch = useDispatch();
    useEffect(() => {
        onMessageListener()
            .then((payload) => {
                const baseUrl = process.env.REACT_APP_LOCAL_API_URL;
                const listUrl = baseUrl + "/notification/sorts/list/";
                dispatch(notificationSortsAction(listUrl));
                // setNotification({title: payload?.notification?.title, body: payload?.notification?.body});
            })
            .catch((err) =>
                console.log('failed: ', err)
            );
    }, [])
    return (
        <div>
            <div className="xl:w-full mx-auto min-h-screen relative flex justify-between  ">
                <Sidebar/>
                <div className="contentArea w-full PartnerAdminPortal">
                    <Header/>
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}