import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import {StrictMode} from "react";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import store from "./store";
import {registerServiceWorker} from './serviceWorker'
// import {createRoot} from 'react-dom/client';


// const domNode = document.getElementById('root');
// const root = createRoot(domNode);
// // root.render(<App />);
// // const root = createRoot(document.getElementById('root'));
// root.render(
//     <Provider store={store}>
//         <StrictMode>
//             <App/>
//         </StrictMode>
//     </Provider>
// );


ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//service worker registration
registerServiceWorker();
