export const discountListReducer = (state = {}, action) => {
    switch (action.type) {
        case'GET_DISCOUNT_REQUEST':
            return {
                loading: true,
            }
        case'GET_DISCOUNT_SUCCESS':
            return {
                ...state,
                discounts: action.payload.data,
                loading: false,
                totalCount: action.payload.totalCount
            }
        case'EDIT_DISCOUNT_SUCCESS':
            return {
                ...state,
                discount: action.payload,
                loading: false
            }
        case'CREATE_DISCOUNT_SUCCESS':
            return {
                ...state,
                discount: action.payload,
                loading: false
            }
        case'GET_DISCOUNT_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case'DELETE_DISCOUNT_SUCCESS':
            return {
                ...state,
                loading: false
            }
        case'DELETE_DISCOUNT_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case'UPDATE_DISCOUNT_REQUEST':
            return {
                ...state,
                loading: true,
            }
        case'UPDATE_DISCOUNT_SUCCESS':
            return {
                ...state,
                loading: false
            }
        case'UPDATE_DISCOUNT_FAILED':
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        default:
            return {
                ...state,
                loading: false
            }


    }
}