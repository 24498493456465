import axios from 'axios'
import toast from "react-hot-toast";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {authHeader, commonCatchBlock} from "../helpers/helpers";
import jQuery from "jquery";


const MySwal = withReactContent(Swal)

export const partnerListsAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_PARTNER_REQUEST'})

    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({
                type: 'GET_PARTNER_SUCCESS', payload: {
                    data: res.data.list,
                    totalCount: res.data.totalPages,
                    totalCashAppointment: res.data.totalCashAppointment,
                    totalPenaltyAppointment: res.data.totalPenaltyAppointment,
                    totalOnlineAppointment: res.data.totalOnlineAppointment

                }
            })
        })
        .catch((err) => {
                dispatch({type: 'GET_PARTNER_FAILED', payload: err})
                commonCatchBlock(err)
            }
        )
}

export const partnerDeleteAction = (baseUrl, listUrl) => dispatch => {

    MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete!'
    }).then((result) => {
            if (result.isConfirmed) {
                dispatch({type: 'GET_PARTNER_REQUEST'})
                axios.delete(baseUrl, {
                    headers: authHeader(baseUrl),
                })
                    .then((res) => {
                        dispatch({type: 'DELETE_PARTNER_SUCCESS'})
                        toast.success(res.data.message, {
                            duration: 2000,
                            position: 'top-right',
                        })
                        dispatch({type: 'GET_PARTNER_REQUEST'})
                        dispatch(partnerListsAction(listUrl))
                    })
                    .catch((err) => {
                            dispatch({type: 'DELETE_PARTNER_FAILED'})
                            dispatch(partnerListsAction(listUrl))
                            commonCatchBlock(err)
                        }
                    )
            }

        }
    )
}

export const partnerStatusAction = (baseUrl, listUrl) => dispatch => {

    dispatch({type: 'GET_PARTNER_REQUEST'})
    axios.put(baseUrl, {}, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'UPDATE_PARTNER_SUCCESS'})
            if (res.data.user.isActive) {
                toast.success(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })
            } else {
                toast.error(res.data.message, {
                    duration: 2000,
                    position: 'top-right',
                })
            }

            dispatch({type: 'GET_PARTNER_REQUEST'})
            dispatch(partnerListsAction(listUrl))
        })
        .catch((err) => {
                dispatch({type: 'UPDATE_PARTNER_FAILED'})
                commonCatchBlock(err)
            }
        )

}

export const partnerGetEditAction = (baseUrl) => dispatch => {

    dispatch({type: 'GET_PARTNER_REQUEST'})
    axios.get(baseUrl, {
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({type: 'EDIT_PARTNER_SUCCESS', payload: res.data.partner})
        })
        .catch((err) => {
                dispatch({type: 'GET_PARTNER_FAILED'})
                commonCatchBlock(err)
            }
        )

}

export const partnerCreateEditAction = (baseUrl, method, formData, _form, getUrl, getId, viaApp = false) => dispatch => {
    let frm = jQuery(_form);

    dispatch({type: 'GET_PARTNER_REQUEST'})

    let currentToken = localStorage.getItem('currentToken') ? JSON.parse(localStorage.getItem('currentToken')) : null
    axios({
        url: baseUrl, data: formData, method: method,
        headers: {
            "x-auth-token": currentToken,
            'Content-Type': 'multipart/form-data'
        },
    })
        .then((response) => {

            toast.success(response.data.message, {
                duration: 2000,
                position: 'top-right',
            });
            setTimeout(() => {
                window.location.href = "/partner/list"
                dispatch({type: 'CREATE_PARTNER_SUCCESS', payload: response.data.partner})
            }, 1000)
        })
        .catch((err) => {
                dispatch({type: 'GET_PARTNER_FAILED'})
                if (getId) {
                    dispatch(partnerGetEditAction(getUrl));
                }
                commonCatchBlock(err, frm)


            }
        )

}


export const saveWorkingLocations = (baseUrl, dataPrep, method, listUrl, id) => dispatch => {

    dispatch({type: 'GET_PARTNER_REQUEST'})

    axios({
        url: baseUrl, data: dataPrep, method: method,
        headers: authHeader(baseUrl),
    })
        .then((res) => {
            dispatch({
                type: 'ASSIGN_WORKING_LOCATIONS'
            })
            toast.success(res.data.message, {
                duration: 2000,
                position: 'top-right',
            });
            setTimeout(() => {
                window.location.href = "/partner/view/" + id
                // dispatch(appointmentGetViewAction(listUrl))
            }, 1000)
        })
        .catch((err) => {
                dispatch({type: 'GET_PARTNER_FAILED'})
                dispatch(partnerGetEditAction(listUrl))
                commonCatchBlock(err)
            }
        )

}